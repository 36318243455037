const parallaxWithMapStyle = {
  parallax: {
    height: "40vh",
    overflow: "hidden",
    position: "relative",
    /* backgroundPosition: "75% 50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "700px 400px", */
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    background: "url(assets/img/topo-repeating.svg)",
    backgroundColor: "#afadae !important"
  },
  filter: {
    "&:before": {
      //background: "rgba(0, 0, 0, 0.045)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  small: {
    height: "500px"
  },
  medium: {
    height: "90vh"
  },
  mobile: {
    height: "100vh"
  }
};

export default parallaxWithMapStyle;
