import React from "react";
import Prism from "prismjs";

const response = `{
    "waypoints": [
        {
            "nodes": [
                21876024,
                21876023
            ],
            "location": [
                33.464357,
                35.006425
            ],
            "name": "Şehit Ulus Ülfet Cd.",
            "distance": 54727.588436,
            "hint": "0AwTgNIME4BlAAAAAAAAABoBAAAAAAAAxX-MQgAAAADL5EJDAAAAAGUAAAAAAAAAGgEAAAAAAAAcGAAAJaD-AdknFgI8ef0BLbAOAgIADwtrbARr"
        },
        {
            "nodes": [
                21876023,
                21876022
            ],
            "location": [
                33.464309,
                35.007057
            ],
            "name": "Şehit Ulus Ülfet Cd.",
            "distance": 54796.58384,
            "hint": "0AwTgNIME4CHAAAAAAAAAJMAAABlAAAAgYO6QgAAAAAURstCxX-MQocAAAAAAAAAkwAAAGUAAAAcGAAA9Z_-AVEqFgI8ef0BLbAOAgEAzwprbARr"
        },
        {
            "nodes": [
                21819042,
                21819041
            ],
            "location": [
                33.466144,
                35.007241
            ],
            "name": "Mustafa ve Yusuf Bayram Sk.",
            "distance": 54838.249779,
            "hint": "kAwTgJQME4B1AAAAAAAAAD4BAAAAAAAARD-iQgAAAACD3ltDAAAAAHUAAAAAAAAAPgEAAAAAAAAcGAAAIKf-AQkrFgI8ef0BLbAOAgIALwprbARr"
        }
    ],
    "code": "Ok"
}`;

class Nearest extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    return (
      <span>
        <h3>
          <small>En Yakın Lokasyonları Alma</small>
        </h3>
        <p>
          Koordinata, cadde ağı üstündeki en yakın "n" lokasyonu döndürür.
          Kullanıcı aşağıdaki adrese GET sorgusu atarak servisin bu özelliğine
          ulaşır.
        </p>
        <br />
        <pre>
          <code className="language-">
            {
              "http://servis.pirireis.com.tr/osrm/nearest/v1/driving/{coordinates}.json?number={number}"
            }
          </code>
        </pre>
        <p>
          <b>coordinates</b> sadece tek bir (enlem, boylam) olabilir.
        </p>
        <p>
          <b>number</b> 1 ya da 1'den büyük bir sayı olabilir. Kullanıcıya bu
          sayı kadar yakın lokasyonlar döner. Varsayılan değeri 1'dir
        </p>
        <p>API'dan dönen response örneği aşağıdadır.</p>
        <pre>
          <code className="language-javascript">{response}</code>
        </pre>
      </span>
    );
  }
}

export default Nearest;
