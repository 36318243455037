import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Agreement from "components/Modal/Agreement.jsx";
import { registerUser } from "../../actions";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      name: "",
      surname: "",
      mail: "",
      password: "",
      error: {
        username: "",
        name: "",
        surname: "",
        mail: "",
        password: ""
      }
    };
  }

  handleChange = (name, errorMsg) => event => {
    let error = Object.assign({}, this.state.error);

    if (!event.target.value) {
      this.setState({
        error: Object.assign(error, { [name]: errorMsg })
      });
    } else {
      this.setState({
        error: Object.assign(error, { [name]: "", notFound: "" })
      });
    }

    this.setState({
      [name]: event.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    let error = {
      username: "",
      name: "",
      surname: "",
      mail: "",
      password: "",
      notFound: ""
    };

    if (!this.state.username) {
      this.setState(() => {
        return {
          error: Object.assign(error, { username: "Kullanıcı adı giriniz" })
        };
      });
    } else if (this.state.username.length < 3) {
      this.setState(() => {
        return {
          error: Object.assign(error, {
            username: "Kullanıcı adı minimum 3 karakter içermeli"
          })
        };
      });
    }

    if (!this.state.password) {
      this.setState(() => {
        return {
          error: Object.assign(error, { password: "Şifre giriniz" })
        };
      });
    }

    if (!this.state.name) {
      this.setState(() => {
        return {
          error: Object.assign(error, { name: "Ad giriniz" })
        };
      });
    }

    if (!this.state.surname) {
      this.setState(() => {
        return {
          error: Object.assign(error, { surname: "Soyad giriniz" })
        };
      });
    }

    if (!this.state.mail) {
      this.setState(() => {
        return {
          error: Object.assign(error, { mail: "Eposta giriniz" })
        };
      });
    }
    if (
      this.state.username &&
      this.state.password &&
      this.state.name &&
      this.state.surname &&
      this.state.mail
    ) {
      this.props.registerUser({
        username: this.state.username,
        name: this.state.name,
        surname: this.state.surname,
        mail: this.state.mail,
        password: this.state.password
      });
    }
  };

  renderInputs = (
    labelText,
    id,
    value,
    fieldName,
    error,
    errorMsg,
    inputType,
    children
  ) => {
    return (
      <CustomInput
        labelText={labelText}
        id={id}
        onChange={this.handleChange(fieldName, errorMsg)}
        value={value}
        errorMessage={error[fieldName]}
        error={error[fieldName] !== ""}
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          type: inputType
          /* endAdornment: (
            <InputAdornment position="end">{children}</InputAdornment>
          ) */
        }}
      />
    );
  };

  render() {
    const { classes, userRegistered } = this.props;
    const { username, name, surname, mail, password, error } = this.state;

    return (
      <form className={classes.form}>
        <CardBody>
          {this.renderInputs(
            "Kullanıcı Adı...",
            "usernameReg",
            username,
            "username",
            error,
            "Kullanıcı Adı girin!",
            "text"
          )}
          {this.renderInputs(
            "Ad...",
            "name",
            name,
            "name",
            error,
            "Ad girin!",
            "text"
          )}
          {this.renderInputs(
            "Soyad...",
            "surname",
            surname,
            "surname",
            error,
            "Soyad girin!",
            "text"
          )}
          {this.renderInputs(
            "Eposta...",
            "email",
            mail,
            "mail",
            error,
            "E-posta girin!",
            "email"
          )}
          {this.renderInputs(
            "Şifre",
            "pass",
            password,
            "password",
            error,
            "Şifre girin!",
            "password"
          )}
          <div className={classes.register}>
            <Link to="/atlasapidocgiris">Giriş Yap</Link>
          </div>
          {userRegistered.isRegistered ? (
          <FormControl error={!userRegistered.isRegistered}>
            <div style={{ padding: "12px 0px", color: "green" }}>
              {userRegistered.message}
            </div>
          </FormControl>
          ) : null }
        </CardBody>
        <CardFooter
          className={classes.cardFooter}
          /* style={
            !userRegistered.isRegistered
              ? { justifyContent: "space-around !important" }
              : {}
          } */
        >
          <div
            style={{
              fontSize: "smaller",
              textAlign: "left",
              marginRight: "42px"
            }}
          >
            Kayıt olarak, HGM ATLAS Uygulama Geliştirme Platformu <Agreement />
            'ni okuduğumu ve onayladığımı kabul ediyorum.
          </div>
          <Button color="google" onClick={this.handleSubmit}>
            Üye Olun
          </Button>
        </CardFooter>
      </form>
    );
  }
}

function mapStateToProps({ user }) {
  return { userRegistered: user };
}

export default withStyles(loginPageStyle)(
  connect(
    mapStateToProps,
    { registerUser }
  )(Register)
);
