import { container, primaryColor } from "assets/jss/material-kit-react.jsx";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "0px 0px 0.9375rem 0px",
    fontWeight: "700",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    //float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0"
  },
  footer: {
    padding: "1.9375rem 0",
    textAlign: "center",
    zIndex: "2",
    position: "relative",
    bottom: "0px",
    backgroundColor: "#141625"
  },
  border: {
    borderTop: "3px solid black"
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "white"
    }
  },
  flexedContainer: {
    display: "flex",
    flexDirection: "row"
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly"
  },
  rowList: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  rowListItem: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
    color: "inherit",
    fontWeight: "400",
    fontSize: "12px",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  },
  copyright: {
    display: "flex",
    justifyContent: "center"
  }
};
export default footerStyle;
