import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import HeaderLinks from "../components/Header/HeaderLinks";
import Parallax from "../components/Parallax/Parallax";
import landingPageStyle from "../assets/jss/material-kit-react/mainPage";

//import map from "../assets/img/map2.png";
import staticMap from "../assets/img/map-image2.png";
import geocoder from "../assets/img/geocoder2.png";
import search from "../assets/img/search2.png";
import routing from "../assets/img/routing2.png";

const imgs = { /* map, */ staticMap, geocoder, search, routing };

const dashboardRoutes = [];
class CommonAPIPage extends React.Component {
  render() {
    const {
      classes,
      children,
      title,
      subtitle,
      img,
      width,
      ...rest
    } = this.props;

    return (
      <div className={classes.main}>
        <Header
          color="white"
          routes={dashboardRoutes}
          brand="Ana Sayfa"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "dark"
          }}
          {...rest}
        />
        <Parallax small filter image={isWidthUp("md", width) ? imgs[img] : ""}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} lg={6}>
                <h1 className={classes.title}>{title}</h1>
                <h4 className={classes.subtitle} style={{ marginRight: "60px" }}>{subtitle}</h4>
                <br />
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main)}>
          <div className={classes.container}>{children}</div>
        </div>
        <Footer />
      </div>
    );
  }
}

CommonAPIPage.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  img: PropTypes.string,
  width: PropTypes.string
};

export default withWidth()(withStyles(landingPageStyle)(CommonAPIPage));
