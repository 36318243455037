import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = {
  map: {
    width: "100%",
    height: "400px"
  }
};

class LeafletExample extends React.Component {
  render() {
    return (
      <div>
        <span>
          <iframe
            title="Leaflet-Map"
            width="100%"
            height="500"
            src="//jsfiddle.net/5vnwahm9/3/embedded/result,html,css/dark/"
            frameBorder="0"
          />
        </span>
      </div>
    );
  }
}

LeafletExample.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LeafletExample);
