import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LeafletExample from "./LeafletExample";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    position: "relative"
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    maxWidth: "666px"
  },
  listItemText: {
    fontSize: "0.75em"
  }
});
class GeocoderExamples extends React.Component {
  state = {
    selectedIndex: 1
  };

  handleListItemClick = (event, index) => {
    this.setState({ selectedIndex: index });
  };

  render() {
    const { classes } = this.props;
    const { selectedIndex } = this.state;

    return (
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="left"
        >
          <List>
            {["Leaflet", "OpenLayers"].map((text, index) => (
              <ListItem
                button
                key={text}
                selected={selectedIndex === index + 1}
                onClick={event => this.handleListItemClick(event, index + 1)}
              >
                <ListItemText
                  primary={`${text}`}
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <main className={classes.content}>
          {selectedIndex === 1 ? <LeafletExample /> : ""}
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(GeocoderExamples);
