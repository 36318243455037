/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { List, ListItem, withStyles } from "@material-ui/core";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";

function Footer({ ...props }) {
  const { classes, whiteFont, border, width } = props;

  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
    [classes.border]: false
  });

  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  const containerClasses = classNames({
    [classes.container]: true,
    [classes.flexedContainer]: true
  });

  const copyrightClasses = classNames({
    [classes.rowListItem]: true,
    [classes.copyright]: true
  });

  return (
    <footer className={footerClasses}>
      <div className={containerClasses}>
        <List className={classes.list}>
          <ListItem className={classes.inlineBlock}>
            <a
              href="https://www.harita.gov.tr/"
              className={classes.rowListItem}
              target="_blank"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={
                  isWidthUp("md", width)
                    ? require("../../assets/img/ss.png")
                    : ""
                }
              />
            </a>
            <div className={copyrightClasses}>
              &copy; {1900 + new Date().getYear()} Harita Genel Müdürlüğü
            </div>
            <div className={copyrightClasses}>{"Beta Sürüm"}</div>
          </ListItem>
          <ListItem className={classes.inlineBlock}>
            <List className={classes.rowList}>
              <ListItem className={classes.block}>
                HARİTA Genel Müdürlüğü
              </ListItem>
              <ListItem className={classes.rowListItem}>
                <a
                  href="https://www.harita.gov.tr/anasayfa"
                  className={classes.rowListItem}
                  target="_blank"
                >
                  HGM-Anasayfa
                </a>
              </ListItem>
              <ListItem className={classes.rowListItem}>
                <a
                  href="https://atlas.harita.gov.tr/"
                  className={classes.rowListItem}
                  target="_blank"
                >
                  HGM-Atlas
                </a>
              </ListItem>
              <ListItem className={classes.rowListItem}>
                <a
                  href="https://geoportal.harita.gov.tr/"
                  className={classes.rowListItem}
                  target="_blank"
                >
                  HGM-Geoportal
                </a>
              </ListItem>
              <ListItem className={classes.rowListItem}>
                <a
                  href="https://hgmkure.btk.gov.tr/"
                  className={classes.rowListItem}
                  target="_blank"
                >
                  HGM-Küre
                </a>
              </ListItem>
            </List>
          </ListItem>
          <ListItem className={classes.inlineBlock}>
            <List className={classes.rowList}>
              <ListItem className={classes.block}>SERVİSLER</ListItem>
              <ListItem className={classes.rowListItem}>
                <Link to="/hari̇talar" className={classes.rowListItem}>
                  Haritalar
                </Link>
              </ListItem>
              <ListItem className={classes.rowListItem}>
                <Link to="/coğrafi̇-kodlama" className={classes.rowListItem}>
                  Coğrafi Kodlama
                </Link>
              </ListItem>
              <ListItem className={classes.rowListItem}>
                <Link to="/arama" className={classes.rowListItem}>
                  Arama
                </Link>
              </ListItem>
              <ListItem className={classes.rowListItem}>
                <Link to="/güzergah" className={classes.rowListItem}>
                  Güzergah
                </Link>
              </ListItem>
            </List>
          </ListItem>
          <ListItem className={classes.inlineBlock}>
            <List className={classes.rowList}>
              <ListItem className={classes.block}>Yazılım</ListItem>
              <ListItem className={classes.rowListItem}>
                <a
                  href="http://www.pirireis.com.tr/"
                  className={classes.rowListItem}
                  target="_blank"
                >
                  PiriReis Bilişim
                </a>
              </ListItem>
            </List>
          </ListItem>
          <ListItem className={classes.inlineBlock}>
            <List className={classes.rowList}>
              <ListItem className={classes.block}>İLETİŞİM</ListItem>
              <ListItem className={classes.rowListItem}>
                <Link to="/bize-ulasin" className={classes.rowListItem}>
                  <i
                    className="fas fa-envelope"
                    style={{ marginRight: "4px" }}
                  />
                  Bize Ulaşın
                </Link>
              </ListItem>
              <ListItem className={classes.rowListItem}>
                <a
                  href="https://www.twitter.com/harita_turkiye"
                  className={classes.rowListItem}
                  target="_blank"
                >
                  <i
                    className="fab fa-twitter"
                    style={{ marginRight: "4px" }}
                  />
                  Twitter
                </a>
              </ListItem>
              <ListItem className={classes.rowListItem}>
                <a
                  href="https://www.instagram.com/harita_turkiye/"
                  className={classes.rowListItem}
                  target="_blank"
                >
                  <i
                    className="fab fa-instagram"
                    style={{ marginRight: "4px" }}
                  />
                  Instagram
                </a>
              </ListItem>
            </List>
          </ListItem>
        </List>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool,
  border: PropTypes.bool
};

Footer.defaultProps = {
  border: true,
  whiteFont: true
};

export default withWidth()(withStyles(footerStyle)(Footer));
