import axios from "axios";

export const REGISTER_USER = "register_user";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const GET_KEY = "get_key";
export const GET_USER_INFO = "get-user-info";
export const CHECK_USER = "check-user";
export const UPDATE_USER = "update-user";
export const ADD_PROJECT = "add-project";
export const SEND_PASSWORD = "send_password";
export const NEW_PASSWORD = "new-password";
export const SEND_MAIL = "send-mail";

//const SERVICE_URL = "https://servis.pirireis.com.tr/atlasuser/api";
const SERVICE_URL = "http://localhost:5005/api";

function checkToken() {
  if (
    !axios.defaults.headers.common["token"] &&
    localStorage.getItem("reduxState")
  ) {
    const persistedState = JSON.parse(localStorage.getItem("reduxState"));

    if (persistedState.user && persistedState.user.token) {
      axios.defaults.headers.common["token"] = persistedState.user.token.token;
    }
  }
}

export async function registerUser({
  username,
  name,
  surname,
  mail,
  password
}) {
  try {
    await axios.post(`${SERVICE_URL}/register`, {
      username,
      password,
      email: mail,
      firstname: name,
      lastname: surname
    });

    return {
      type: REGISTER_USER,
      payload: {
        isRegistered: true,
        message:
          "Hesap başarıyla oluşturuldu. Hesabı doğrulamanız için size bir mai1300l gönderdik."
      }
    };
  } catch (error) {
    return {
      type: REGISTER_USER,
      payload: {
        isRegistered: false,
        message: "Başarısız işlem: Aynı adlı kullanıcı sistemde var."
      }
    };
  }
}

export async function login({ username, password }, pass, reject) {
  try {
    const token = await axios
      .post(`${SERVICE_URL}/auth`, {
        username,
        password
      })
      .then(res => res.data);

    pass();

    axios.defaults.headers.common["token"] = token.token;

    return {
      type: LOGIN,
      payload: { isLoggedIn: true, token, message: "" }
    };
  } catch (error) {
    if (typeof reject === "function") {
      reject();
    }

    return {
      type: LOGIN,
      payload: { isLoggedIn: false, message: error.message, token: {} }
    };
  }
}

export async function logout() {
  //localStorage.removeItem("reduxState");

  return {
    type: LOGOUT,
    payload: {
      isLoggedIn: false,
      isRegistered: false,
      token: "",
      message: "",
      user: {
        username: "Kullanıcı Adı",
        password: "Şifre",
        firstname: "İsim",
        projects: []
      }
    }
  };
}

async function getKeyIfAlreadyGenerated() {
  try {
    //checkToken();

    let res = await axios.get(`${SERVICE_URL}/keyauth`);
    let { apikey } = res.data;

    return apikey;
  } catch (error) {
    return null;
  }
}

export async function getApiKey(pass, reject) {
  try {
    checkToken();
    let apikey = await getKeyIfAlreadyGenerated();

    if (!apikey) {
      let res = await axios.post(`${SERVICE_URL}/keyauth`);
      apikey = res.data.apikey;
    }

    pass();

    return {
      type: GET_KEY,
      payload: { apiKey: apikey }
    };
  } catch (error) {
    const message = "Anahtar yaratılamadı!";
    if (typeof reject === "function") {
      reject(message);
    }

    return {
      type: GET_KEY,
      payload: { undefined }
    };
  }
}

export async function getUserInfo(pass, reject) {
  try {
    checkToken();
    const user = await axios.get(`${SERVICE_URL}/user/`);
    pass(user.data.data);

    return {
      type: GET_USER_INFO,
      payload: { user: user.data.data }
    };
  } catch (error) {
    if (typeof reject === "function") {
      reject("Kullanıcı bulunamadı!");
    }

    return {
      type: GET_USER_INFO,
      payload: {}
    };
  }
}

export async function checkValidityOfUser(username, password, pass, reject) {
  try {
    checkToken();
    await axios.get(`${SERVICE_URL}/user-valid/`, {
      params: {
        username,
        password
      }
    });
    pass();

    return {
      type: CHECK_USER,
      payload: { isUserValid: true }
    };
  } catch (error) {
    if (typeof reject === "function") {
      reject("Kullanıcı bulunamadı!");
    }

    return {
      type: CHECK_USER,
      payload: { isUserValid: false }
    };
  }
}

export async function updateUserInfo(
  { username, name, password, email },
  pass,
  reject
) {
  try {
    checkToken();
    await axios.post(`${SERVICE_URL}/user`, {
      params: {
        username,
        name,
        password,
        email
      }
    });

    pass();

    return {
      type: UPDATE_USER,
      payload: { updateSuccess: true }
    };
  } catch (error) {
    if (typeof reject === "function") {
      reject();
    }
    return {
      type: UPDATE_USER,
      payload: { updateSuccess: false }
    };
  }
}

export async function addProject(project, domain) {
  try {
    checkToken();
    const projects = await axios.post(`${SERVICE_URL}/project`, {
      project: {
        project,
        domain
      }
    });

    return {
      type: ADD_PROJECT,
      payload: { projects: projects.data.projects }
    };
  } catch (error) {
    return {
      type: ADD_PROJECT,
      payload: {}
    };
  }
}

export async function sendPassword(username, pass, reject) {
  try {
    await axios.post(`${SERVICE_URL}/password`, {
      username
    });

    pass();

    return {
      type: SEND_PASSWORD,
      payload: {}
    };
  } catch (error) {
    if (typeof reject === "function") {
      reject();
    }

    return {
      type: SEND_PASSWORD,
      payload: {}
    };
  }
}

export async function sendNewPassword({ token, password }, pass, reject) {
  try {
    await axios.post(`${SERVICE_URL}/password-update`, {
      token,
      password
    });

    pass();

    return {
      type: NEW_PASSWORD,
      payload: {}
    };
  } catch (error) {
    if (typeof reject === "function") {
      reject();
    }

    return {
      type: NEW_PASSWORD,
      payload: {}
    };
  }
}

export async function sendMail(mail, pass, reject) {
  try {
    checkToken();
    await axios.post(`${SERVICE_URL}/mail`, {
      mail
    });

    pass();

    return {
      type: SEND_MAIL,
      payload: {}
    };
  } catch (error) {
    if (typeof reject === "function") {
      reject();
    }

    return {
      type: SEND_MAIL,
      payload: {}
    };
  }
}
