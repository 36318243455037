import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { getApiKey, addProject } from "../../actions";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class Agreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classicModal: false
    };
  }

  handleClickOpen = () => () => this.setState({ classicModal: true });

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  render() {
    const { classes } = this.props;

    return (
      <span>
        <span
          style={{ display: "inline", color: "#9c27b0", cursor: "pointer" }}
          aria-label="Proje Ekle"
          onClick={this.handleClickOpen("classicModal")}
        >
          Kullanıcı Sözleşmesi
        </span>
        <Dialog
          classes={{
            paper: classes.modal
          }}
          open={this.state.classicModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("classicModal")}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          {/* <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>Kullanıcı Sözleşmesi</h4>
          </DialogTitle> */}
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}
          >
            <p style={{ fontSize: "smaller" }}>
              <h4>
                HGM ATLAS Uygulama Geliştirme Platformu Kullanıcı Kayıt
                Sözleşmesi
              </h4>
              Bu bir lisans sözleşmesi olup, HGM API vasıtasıyla sağlanan HGM
              Atlas haritaları, verileri, servisleri, uygulamaları ve ilgili
              dokümanlarını kapsamaktadır. Bu içeriğin kopyalanması,
              kullanılması ve kurulması durumunda, son kullanıcı sözleşmesindeki
              bütün şartların kabul edildiği var sayılmaktadır. Son kullanıcı
              sözleşmesinin kabul edilmemesi durumunda bütün içeriğin hemen iade
              edilmesi ve kullanımın kesilmesi gerekmektedir.
              <h4>Sahiplik</h4>
              Tüm içerikle ilgili telif hakları ile fikri mülkiyet hakları
              Harita Genel Müdürlüğü ve lisansçılarına aittir.
              <h4>Ücretsiz Lisans </h4>
              Bu lisans sadece kişisel kullanım içindir, ticari amaçla
              kullanılamaz. Bu lisans, burada belirtilen sınırlamalara tâbidir
              ve alt lisans verme hakkını içermez
              <h4>Kullanım Kısıtları </h4>
              İçerik, hangi sistem için oluşturulmuşsa onun için kullanılabilir.
              İçeriği herhangi bir kısmı çoğaltılamaz, değiştirilemez,
              uyarlanamaz, tercüme edilemez, parçalanamaz, tersine mühendislikle
              elde edilemez. HGM ATLAS API JavaScript kaynak kodları üzerinde
              değişiklik yapılıp kullanılması bu sözleşmeye aykırıdır. HGM ATLAS
              API Servisleri ile oluşturulan harita görüntülerinde HGM
              Filigranı’nın gösterilmesi zorunludur. İçerik, üçüncü şahısların
              lisansları altında sağlanan belirli bilgileri de içerir.
              <h4>Lisansın Devri </h4>
              İçerik üçüncü taraflara devredilemez veya satılamaz.
              <h4>Uyarılar</h4>
              Kullanıcı Sözleşmesi, HGM ATLAS Uygulama Geliştirme Platformu
              internet sitesi beta sürümüne aittir. İçerikte eksik veya yanlış
              bilgiler olabilir. Bu da yanlış sonuçlara yol açabilir. Verilerin
              kullanımı sonucunda oluşacak riskler kullanıcı tarafından kabul
              edilmiştir.
            </p>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              onClick={() => this.handleClose("classicModal")}
              color="danger"
              simple
            >
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    );
  }
}

function mapStateToProps({ key }) {
  return {
    message: key.message,
    apiKey: key.apiKey
  };
}

Agreement.propTypes = {
  classes: PropTypes.object.isRequired,
  getApiKey: PropTypes.func,
  apiKey: PropTypes.string
};

export default connect(
  mapStateToProps,
  { getApiKey, addProject }
)(Agreement);
