import React from "react";
import "react-app-polyfill/ie9";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import promise from "redux-promise";
import reducers from "./reducers";
import App from "./App";

import "assets/scss/material-kit-react.scss?v=1.4.0";

const createStoreWithMiddleware = applyMiddleware(promise)(createStore);
const persistedState = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState"))
  : {};

let store = createStoreWithMiddleware(reducers, persistedState);

store.subscribe(() => {
  localStorage.setItem("reduxState", JSON.stringify(store.getState()));
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
