import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = {
  map: {
    width: "100%",
    height: "400px"
  }
};

class OpenLayersExample extends React.Component {
  render() {
    return (
      <div>
        <span>
          <iframe
            title="OpenLayers-Map"
            width="100%"
            height="500"
            src="//jsfiddle.net/ka9gt7eo/6//embedded/result,html,css/dark/"
            frameBorder="0"
          />
        </span>
      </div>
    );
  }
}

OpenLayersExample.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(OpenLayersExample);
