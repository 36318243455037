const help = [
  {
    question:
      "Bir Geocoder (Coğrafi Kodlama) sorgusu ile hangi sonuca ulaşırım? ",
    answer:
      "Bu sorunun cevabı, sorgunuzun türüne göre değişmektedir.Eğer sorgunuz ileri coğrafi kodlama ise yani koordinatlardan adres bilgisine ulaşmak istiyorsanız, alacağınız  yanıt metin tabanlı adres bilgisi olacaktır. Eğer, sorgunuzda adres belirtip koordinat bilgisine ulaşmak istiyorsanız, alacağınız yanıt sayı tabanlı “enlem” ve “boylam” bilgisi olacaktır. "
  },
  {
    question:
      "İleri coğrafi kodlama sorgusu sonucunda yanıt olarak alacağım “Enlem” ve “Boylam” bilgisi hangi koordinat sisteminde tanımlıdır?",
    answer:
      "Sorgunuza yanıt olarak alacağınız koordinatlar  “World Geodetic System 84 (WGS84)” coğrafi koordinat sistemi üzerinde tanımlı enlem ve boylam bilgisine aittir."
  },
  {
    question:
      "Bir Geocoder (Coğrafi Kodlama) sorgusunun yanıtı hangi format üzerinde yanıtlanır? ",
    answer:
      "Kullanıcıların gerçekleştirdikleri sorgulara verilen yanıt standart bir GeoJSON nesnesidir. "
  }
];

export default help;
