export const leaflet = `<!DOCTYPE html>
<html>
<head>
	<title>Leaflet</title>
	<meta charset="utf-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0">
	<link rel="shortcut icon" type="image/x-icon" href="docs/images/favicon.ico" />
    <link rel="stylesheet" href="https://unpkg.com/leaflet@1.4.0/dist/leaflet.css" integrity="sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA==" crossorigin=""/>
    <script src="https://unpkg.com/leaflet@1.4.0/dist/leaflet.js" integrity="sha512-QVftwZFqvtRNi0ZyCtsznlKSWOStnDORoefr1enyq5mVL4tmKB3S/EnC3rRJcxCPavG10IcrVGSmPh6Qw5lwrg==" crossorigin=""></script>
    <script src="https://api.harita.gov.tr/atlas-js/v1.0.0/atlas.js"></script>
</head>
<body>
	<div id="map" style="width: 600px; height: 400px;"></div>
	<script>
	    var client = new atlas.Client({
        apiKey: 'AlkVjf0YFkkDuvu58l7Ndc1oiHk71IbF',
        mapType: atlas.MAPTYPES.LEAFLET
      });

      var layers = client.createLayer(atlas.BASEMAPS.TEMEL);
      var map = L.map('map', {
        layers: layers
      }).setView([39, 33.41], 5);
	</script>
</body>
</html>
`;

export const openLayers = `<!DOCTYPE html>
<html lang="en">
  <head>
    <link rel="stylesheet" href="https://cdn.rawgit.com/openlayers/openlayers.github.io/master/en/v5.3.0/css/ol.css" type="text/css">
    <style>
      .map {
        height: 400px;
        width: 100%;
      }
    </style>
    <script src="https://cdn.rawgit.com/openlayers/openlayers.github.io/master/en/v4.6.5/build/ol.js"></script>
    <script src="https://api.harita.gov.tr/atlas-js/v1.0.0/atlas.js"></script>
    <title>OpenLayers example</title>
  </head>
  <body>
    <div id="map" class="map"></div>
    <script type="text/javascript">
      var client = new atlas.Client({
        apiKey: 'AlkVjf0YFkkDuvu58l7Ndc1oiHk71IbF',
        mapType: atlas.MAPTYPES.OPENLAYERS
      });
      var layers = client.createLayer(atlas.BASEMAPS.UYDU);

      var map = new ol.Map({
        target: 'map',
        layers: layers,
        view: new ol.View({
          center: ol.proj.fromLonLat([33.41, 39]),
          zoom: 5
        })
      });
    </script>
  </body>
</html>`;
