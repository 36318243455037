import { container, title } from "assets/jss/material-kit-react.jsx";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#383c45",
    maxWidth: "1000px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: "40px",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "rgb(56, 60, 69)",
    textDecoration: "none"
  },
  subtitle: {
    color: "rgb(56, 60, 69)"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    minHeight: "77vh",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 10px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  marginLeft: {
    marginLeft: "auto !important"
  },
  top: {
    display: "flex"
  },
  section: {
    padding: "70px 0"
  }
};

export default landingPageStyle;
