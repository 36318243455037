import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainPage from "./MainPage";
import CommonAPI from "./views/CommonAPIPage";
import InteractiveMap from "./views/InteractiveMap/InteractiveMap";
import Geocoder from "./views/Geocoder/Geocoder";
import Routing from "./views/Routing/Routing";
import Search from "./views/Search/Search";
import LoginPage from "./views/LoginPage/LoginPage";
import Contact from "./views/Contact/Contact";
import UserPage from "./views/User/UserPage";
import Pricing from "./views/Pricing/Pricing";

class App extends React.Component {
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route
            path="/hari̇talar"
            render={props => (
              <CommonAPI
                {...props}
                title={"Haritalar"}
                subtitle={`Haritalar API, harita hizmeti sunan uygulamalarınızda
              veya web sayfalarınızda HGM ATLAS harita ekranının görüntülenmesini
              sağlar.`}
                img={"staticMap"}
              >
                <InteractiveMap />
              </CommonAPI>
            )}
          />
          <Route
            path="/coğrafi̇-kodlama"
            render={props => (
              <CommonAPI
                {...props}
                title={"Coğrafi Kodlama"}
                subtitle={
                  "Coğrafi Kodlama REST API, ileri coğrafi kodlama ve ters coğrafi kodlama gerçekleştirir."
                }
                img={"geocoder"}
              >
                <Geocoder />
              </CommonAPI>
            )}
          />
          <Route
            path="/güzergah"
            render={props => (
              <CommonAPI
                {...props}
                title={"Güzergah"}
                subtitle={`Güzergah REST API, kullanıcıların iki ya da daha fazla lokasyon
                arasında yol tarifi, seyahat süreleri ve mesafeleri almasına olanak
                sağlayan servistir.`}
                img={"routing"}
              >
                <Routing />
              </CommonAPI>
            )}
          />
          <Route
            path="/arama"
            render={props => (
              <CommonAPI
                {...props}
                title={"Arama"}
                subtitle={"Arama REST API, bir adres veya yer aramayı sağlar."}
                img={"search"}
              >
                <Search />
              </CommonAPI>
            )}
          />
          <Route path="/bize-ulasin" component={Contact} />
          <Route path="/atlasapidocgiris" component={LoginPage} />
          <Route path="/kayit-ol" component={LoginPage} />
          <Route path="/sifre" component={LoginPage} />
          <Route path="/yenisifre/:token" component={LoginPage} />
          <Route path="/ucret" component={Pricing} />
          <Route path="/kullanici" component={UserPage} />
          <Route path="/" component={MainPage} />
        </Switch>
      </Router>
    );
  }
}

export default App;
