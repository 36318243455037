/* eslint-disable */
!(function(t, e) {
  "object" == typeof exports && "object" == typeof module
    ? (module.exports = e())
    : "function" == typeof define && define.amd
    ? define("atlas", [], e)
    : "object" == typeof exports
    ? (exports.atlas = e())
    : (t.atlas = e());
})(window, function() {
  return (function(t) {
    var e = {};
    function i(r) {
      if (e[r]) return e[r].exports;
      var n = (e[r] = { i: r, l: !1, exports: {} });
      return t[r].call(n.exports, n, n.exports, i), (n.l = !0), n.exports;
    }
    return (
      (i.m = t),
      (i.c = e),
      (i.d = function(t, e, r) {
        i.o(t, e) || Object.defineProperty(t, e, { enumerable: !0, get: r });
      }),
      (i.r = function(t) {
        "undefined" != typeof Symbol &&
          Symbol.toStringTag &&
          Object.defineProperty(t, Symbol.toStringTag, { value: "Module" }),
          Object.defineProperty(t, "__esModule", { value: !0 });
      }),
      (i.t = function(t, e) {
        if ((1 & e && (t = i(t)), 8 & e)) return t;
        if (4 & e && "object" == typeof t && t && t.__esModule) return t;
        var r = Object.create(null);
        if (
          (i.r(r),
          Object.defineProperty(r, "default", { enumerable: !0, value: t }),
          2 & e && "string" != typeof t)
        )
          for (var n in t)
            i.d(
              r,
              n,
              function(e) {
                return t[e];
              }.bind(null, n)
            );
        return r;
      }),
      (i.n = function(t) {
        var e =
          t && t.__esModule
            ? function() {
                return t.default;
              }
            : function() {
                return t;
              };
        return i.d(e, "a", e), e;
      }),
      (i.o = function(t, e) {
        return Object.prototype.hasOwnProperty.call(t, e);
      }),
      (i.p = ""),
      i((i.s = 46))
    );
  })([
    function(t, e) {
      t.exports = function(t) {
        return "object" == typeof t ? null !== t : "function" == typeof t;
      };
    },
    function(t, e, i) {
      t.exports = !i(6)(function() {
        return (
          7 !=
          Object.defineProperty({}, "a", {
            get: function() {
              return 7;
            }
          }).a
        );
      });
    },
    function(t, e, i) {
      var r = i(3),
        n = i(4),
        o = i(8),
        s = i(22),
        a = i(25),
        u = function(t, e, i) {
          var c,
            h,
            l,
            p,
            f = t & u.F,
            g = t & u.G,
            _ = t & u.S,
            d = t & u.P,
            y = t & u.B,
            v = g ? r : _ ? r[e] || (r[e] = {}) : (r[e] || {}).prototype,
            m = g ? n : n[e] || (n[e] = {}),
            E = m.prototype || (m.prototype = {});
          for (c in (g && (i = e), i))
            (l = ((h = !f && v && void 0 !== v[c]) ? v : i)[c]),
              (p =
                y && h
                  ? a(l, r)
                  : d && "function" == typeof l
                  ? a(Function.call, l)
                  : l),
              v && s(v, c, l, t & u.U),
              m[c] != l && o(m, c, p),
              d && E[c] != l && (E[c] = l);
        };
      (r.core = n),
        (u.F = 1),
        (u.G = 2),
        (u.S = 4),
        (u.P = 8),
        (u.B = 16),
        (u.W = 32),
        (u.U = 64),
        (u.R = 128),
        (t.exports = u);
    },
    function(t, e) {
      var i = (t.exports =
        "undefined" != typeof window && window.Math == Math
          ? window
          : "undefined" != typeof self && self.Math == Math
          ? self
          : Function("return this")());
      "number" == typeof __g && (__g = i);
    },
    function(t, e) {
      var i = (t.exports = { version: "2.6.5" });
      "number" == typeof __e && (__e = i);
    },
    function(t, e, i) {
      var r = i(12),
        n = i(18),
        o = i(20),
        s = Object.defineProperty;
      e.f = i(1)
        ? Object.defineProperty
        : function(t, e, i) {
            if ((r(t), (e = o(e, !0)), r(i), n))
              try {
                return s(t, e, i);
              } catch (t) {}
            if ("get" in i || "set" in i)
              throw TypeError("Accessors not supported!");
            return "value" in i && (t[e] = i.value), t;
          };
    },
    function(t, e) {
      t.exports = function(t) {
        try {
          return !!t();
        } catch (t) {
          return !0;
        }
      };
    },
    function(t, e) {
      var i = 0,
        r = Math.random();
      t.exports = function(t) {
        return "Symbol(".concat(
          void 0 === t ? "" : t,
          ")_",
          (++i + r).toString(36)
        );
      };
    },
    function(t, e, i) {
      var r = i(5),
        n = i(21);
      t.exports = i(1)
        ? function(t, e, i) {
            return r.f(t, e, n(1, i));
          }
        : function(t, e, i) {
            return (t[e] = i), t;
          };
    },
    function(t, e) {
      var i = {}.hasOwnProperty;
      t.exports = function(t, e) {
        return i.call(t, e);
      };
    },
    function(t, e, i) {
      var r = i(4),
        n = i(3),
        o = n["__core-js_shared__"] || (n["__core-js_shared__"] = {});
      (t.exports = function(t, e) {
        return o[t] || (o[t] = void 0 !== e ? e : {});
      })("versions", []).push({
        version: r.version,
        mode: i(24) ? "pure" : "global",
        copyright: "© 2019 Denis Pushkarev (zloirock.ru)"
      });
    },
    function(t) {
      t.exports = { a: "1.0.0" };
    },
    function(t, e, i) {
      var r = i(0);
      t.exports = function(t) {
        if (!r(t)) throw TypeError(t + " is not an object!");
        return t;
      };
    },
    function(t, e) {
      t.exports = function(t) {
        if ("function" != typeof t) throw TypeError(t + " is not a function!");
        return t;
      };
    },
    function(t, e, i) {
      var r = i(29),
        n = i(31);
      t.exports = function(t) {
        return r(n(t));
      };
    },
    function(t, e, i) {
      var r = i(14),
        n = i(32),
        o = i(33);
      t.exports = function(t) {
        return function(e, i, s) {
          var a,
            u = r(e),
            c = n(u.length),
            h = o(s, c);
          if (t && i != i) {
            for (; c > h; ) if ((a = u[h++]) != a) return !0;
          } else
            for (; c > h; h++)
              if ((t || h in u) && u[h] === i) return t || h || 0;
          return !t && -1;
        };
      };
    },
    function(t, e) {
      var i = Math.ceil,
        r = Math.floor;
      t.exports = function(t) {
        return isNaN((t = +t)) ? 0 : (t > 0 ? r : i)(t);
      };
    },
    function(t, e, i) {
      var r = i(2);
      r(r.S + r.F * !i(1), "Object", { defineProperties: i(26) });
    },
    function(t, e, i) {
      t.exports =
        !i(1) &&
        !i(6)(function() {
          return (
            7 !=
            Object.defineProperty(i(19)("div"), "a", {
              get: function() {
                return 7;
              }
            }).a
          );
        });
    },
    function(t, e, i) {
      var r = i(0),
        n = i(3).document,
        o = r(n) && r(n.createElement);
      t.exports = function(t) {
        return o ? n.createElement(t) : {};
      };
    },
    function(t, e, i) {
      var r = i(0);
      t.exports = function(t, e) {
        if (!r(t)) return t;
        var i, n;
        if (e && "function" == typeof (i = t.toString) && !r((n = i.call(t))))
          return n;
        if ("function" == typeof (i = t.valueOf) && !r((n = i.call(t))))
          return n;
        if (!e && "function" == typeof (i = t.toString) && !r((n = i.call(t))))
          return n;
        throw TypeError("Can't convert object to primitive value");
      };
    },
    function(t, e) {
      t.exports = function(t, e) {
        return {
          enumerable: !(1 & t),
          configurable: !(2 & t),
          writable: !(4 & t),
          value: e
        };
      };
    },
    function(t, e, i) {
      var r = i(3),
        n = i(8),
        o = i(9),
        s = i(7)("src"),
        a = i(23),
        u = ("" + a).split("toString");
      (i(4).inspectSource = function(t) {
        return a.call(t);
      }),
        (t.exports = function(t, e, i, a) {
          var c = "function" == typeof i;
          c && (o(i, "name") || n(i, "name", e)),
            t[e] !== i &&
              (c && (o(i, s) || n(i, s, t[e] ? "" + t[e] : u.join(String(e)))),
              t === r
                ? (t[e] = i)
                : a
                ? t[e]
                  ? (t[e] = i)
                  : n(t, e, i)
                : (delete t[e], n(t, e, i)));
        })(Function.prototype, "toString", function() {
          return ("function" == typeof this && this[s]) || a.call(this);
        });
    },
    function(t, e, i) {
      t.exports = i(10)("native-function-to-string", Function.toString);
    },
    function(t, e) {
      t.exports = !1;
    },
    function(t, e, i) {
      var r = i(13);
      t.exports = function(t, e, i) {
        if ((r(t), void 0 === e)) return t;
        switch (i) {
          case 1:
            return function(i) {
              return t.call(e, i);
            };
          case 2:
            return function(i, r) {
              return t.call(e, i, r);
            };
          case 3:
            return function(i, r, n) {
              return t.call(e, i, r, n);
            };
        }
        return function() {
          return t.apply(e, arguments);
        };
      };
    },
    function(t, e, i) {
      var r = i(5),
        n = i(12),
        o = i(27);
      t.exports = i(1)
        ? Object.defineProperties
        : function(t, e) {
            n(t);
            for (var i, s = o(e), a = s.length, u = 0; a > u; )
              r.f(t, (i = s[u++]), e[i]);
            return t;
          };
    },
    function(t, e, i) {
      var r = i(28),
        n = i(35);
      t.exports =
        Object.keys ||
        function(t) {
          return r(t, n);
        };
    },
    function(t, e, i) {
      var r = i(9),
        n = i(14),
        o = i(15)(!1),
        s = i(34)("IE_PROTO");
      t.exports = function(t, e) {
        var i,
          a = n(t),
          u = 0,
          c = [];
        for (i in a) i != s && r(a, i) && c.push(i);
        for (; e.length > u; ) r(a, (i = e[u++])) && (~o(c, i) || c.push(i));
        return c;
      };
    },
    function(t, e, i) {
      var r = i(30);
      t.exports = Object("z").propertyIsEnumerable(0)
        ? Object
        : function(t) {
            return "String" == r(t) ? t.split("") : Object(t);
          };
    },
    function(t, e) {
      var i = {}.toString;
      t.exports = function(t) {
        return i.call(t).slice(8, -1);
      };
    },
    function(t, e) {
      t.exports = function(t) {
        if (null == t) throw TypeError("Can't call method on  " + t);
        return t;
      };
    },
    function(t, e, i) {
      var r = i(16),
        n = Math.min;
      t.exports = function(t) {
        return t > 0 ? n(r(t), 9007199254740991) : 0;
      };
    },
    function(t, e, i) {
      var r = i(16),
        n = Math.max,
        o = Math.min;
      t.exports = function(t, e) {
        return (t = r(t)) < 0 ? n(t + e, 0) : o(t, e);
      };
    },
    function(t, e, i) {
      var r = i(10)("keys"),
        n = i(7);
      t.exports = function(t) {
        return r[t] || (r[t] = n(t));
      };
    },
    function(t, e) {
      t.exports = "constructor,hasOwnProperty,isPrototypeOf,propertyIsEnumerable,toLocaleString,toString,valueOf".split(
        ","
      );
    },
    function(t, e, i) {
      var r = i(0),
        n = i(37).onFreeze;
      i(38)("freeze", function(t) {
        return function(e) {
          return t && r(e) ? t(n(e)) : e;
        };
      });
    },
    function(t, e, i) {
      var r = i(7)("meta"),
        n = i(0),
        o = i(9),
        s = i(5).f,
        a = 0,
        u =
          Object.isExtensible ||
          function() {
            return !0;
          },
        c = !i(6)(function() {
          return u(Object.preventExtensions({}));
        }),
        h = function(t) {
          s(t, r, { value: { i: "O" + ++a, w: {} } });
        },
        l = (t.exports = {
          KEY: r,
          NEED: !1,
          fastKey: function(t, e) {
            if (!n(t))
              return "symbol" == typeof t
                ? t
                : ("string" == typeof t ? "S" : "P") + t;
            if (!o(t, r)) {
              if (!u(t)) return "F";
              if (!e) return "E";
              h(t);
            }
            return t[r].i;
          },
          getWeak: function(t, e) {
            if (!o(t, r)) {
              if (!u(t)) return !0;
              if (!e) return !1;
              h(t);
            }
            return t[r].w;
          },
          onFreeze: function(t) {
            return c && l.NEED && u(t) && !o(t, r) && h(t), t;
          }
        });
    },
    function(t, e, i) {
      var r = i(2),
        n = i(4),
        o = i(6);
      t.exports = function(t, e) {
        var i = (n.Object || {})[t] || Object[t],
          s = {};
        (s[t] = e(i)),
          r(
            r.S +
              r.F *
                o(function() {
                  i(1);
                }),
            "Object",
            s
          );
      };
    },
    function(t, e, i) {
      var r = i(2);
      r(r.S + r.F * !i(1), "Object", { defineProperty: i(5).f });
    },
    function(t, e, i) {
      var r = i(2);
      r(r.P, "Function", { bind: i(41) });
    },
    function(t, e, i) {
      "use strict";
      var r = i(13),
        n = i(0),
        o = i(42),
        s = [].slice,
        a = {};
      t.exports =
        Function.bind ||
        function(t) {
          var e = r(this),
            i = s.call(arguments, 1),
            u = function() {
              var r = i.concat(s.call(arguments));
              return this instanceof u
                ? (function(t, e, i) {
                    if (!(e in a)) {
                      for (var r = [], n = 0; n < e; n++) r[n] = "a[" + n + "]";
                      a[e] = Function(
                        "F,a",
                        "return new F(" + r.join(",") + ")"
                      );
                    }
                    return a[e](t, i);
                  })(e, r.length, r)
                : o(e, r, t);
            };
          return n(e.prototype) && (u.prototype = e.prototype), u;
        };
    },
    function(t, e) {
      t.exports = function(t, e, i) {
        var r = void 0 === i;
        switch (e.length) {
          case 0:
            return r ? t() : t.call(i);
          case 1:
            return r ? t(e[0]) : t.call(i, e[0]);
          case 2:
            return r ? t(e[0], e[1]) : t.call(i, e[0], e[1]);
          case 3:
            return r ? t(e[0], e[1], e[2]) : t.call(i, e[0], e[1], e[2]);
          case 4:
            return r
              ? t(e[0], e[1], e[2], e[3])
              : t.call(i, e[0], e[1], e[2], e[3]);
        }
        return t.apply(i, e);
      };
    },
    function(t, e, i) {
      "use strict";
      var r = i(2),
        n = i(15)(!0);
      r(r.P, "Array", {
        includes: function(t) {
          return n(this, t, arguments.length > 1 ? arguments[1] : void 0);
        }
      }),
        i(44)("includes");
    },
    function(t, e, i) {
      var r = i(45)("unscopables"),
        n = Array.prototype;
      null == n[r] && i(8)(n, r, {}),
        (t.exports = function(t) {
          n[r][t] = !0;
        });
    },
    function(t, e, i) {
      var r = i(10)("wks"),
        n = i(7),
        o = i(3).Symbol,
        s = "function" == typeof o;
      (t.exports = function(t) {
        return r[t] || (r[t] = (s && o[t]) || (s ? o : n)("Symbol." + t));
      }).store = r;
    },
    function(t, e, i) {
      "use strict";
      i.r(e);
      var r = i(11),
        n =
          (i(17),
          i(36),
          i(39),
          i(40),
          i(43),
          { LEAFLET: "leaflet", OPENLAYERS: "openlayers", GMAP: "gmap" }),
        o = {
          TEMEL: "temel",
          UYDU: "uydu",
          GECE: "gece",
          SIYASI: "siyasi",
          YUKSEKLIK: "yukseklik"
        },
        s = {
          IMAGE: "IMAGE",
          TILE: "TILE",
          VECTOR_TILE: "VECTOR_TILE",
          VECTOR: "VECTOR"
        },
        a =
          "function" == typeof Object.assign
            ? Object.assign
            : function(t, e) {
                var i = arguments;
                if (null == t)
                  throw new TypeError(
                    "Cannot convert undefined or null to object"
                  );
                for (
                  var r = Object(t), n = 1, o = arguments.length;
                  n < o;
                  ++n
                ) {
                  var s = i[n];
                  if (null != s)
                    for (var a in s) s.hasOwnProperty(a) && (r[a] = s[a]);
                }
                return r;
              };
      function u(t) {
        for (var e in t) delete t[e];
      }
      function c(t, e, i, r) {
        for (var n, o = 0, s = t.length; o < s; ++o)
          if ((n = t[o]).listener === e && n.bindTo === i)
            return r && (n.deleteIndex = o), n;
      }
      function h(t, e) {
        var i = l(t);
        return i ? i[e] : void 0;
      }
      function l(t, e) {
        var i = t.ol_lm;
        return !i && e && (i = t.ol_lm = {}), i;
      }
      function p(t, e) {
        var i = h(t, e);
        if (i) {
          for (var r = 0, n = i.length; r < n; ++r)
            t.removeEventListener(e, i[r].boundListener), u(i[r]);
          i.length = 0;
          var o = l(t);
          o &&
            (delete o[e],
            0 === Object.keys(o).length &&
              (function(t) {
                delete t.ol_lm;
              })(t));
        }
      }
      function f(t, e, i, r, n) {
        var o = l(t, !0),
          s = o[e];
        s || (s = o[e] = []);
        var a = c(s, i, r, !1);
        return (
          a
            ? n || (a.callOnce = !1)
            : ((a = {
                bindTo: r,
                callOnce: !!n,
                listener: i,
                target: t,
                type: e
              }),
              t.addEventListener(
                e,
                (function(t) {
                  var e = function(e) {
                    var i = t.listener,
                      r = t.bindTo || t.target;
                    return t.callOnce && d(t), i.call(r, e);
                  };
                  return (t.boundListener = e), e;
                })(a)
              ),
              s.push(a)),
          a
        );
      }
      function g(t, e, i, r) {
        return f(t, e, i, r, !0);
      }
      function _(t, e, i, r) {
        var n = h(t, e);
        if (n) {
          var o = c(n, i, r, !0);
          o && d(o);
        }
      }
      function d(t) {
        if (t && t.target) {
          t.target.removeEventListener(t.type, t.boundListener);
          var e = h(t.target, t.type);
          if (e) {
            var i = "deleteIndex" in t ? t.deleteIndex : e.indexOf(t);
            -1 !== i && e.splice(i, 1), 0 === e.length && p(t.target, t.type);
          }
          u(t);
        }
      }
      var y = "change",
        v = "clear",
        m = "error",
        E = "load";
      function T() {
        return (function() {
          throw new Error("Unimplemented abstract method.");
        })();
      }
      var x = 0;
      function b(t) {
        return t.ol_uid || (t.ol_uid = String(++x));
      }
      var w = "5.3.1",
        S = "propertychange",
        O = function() {
          this.disposed_ = !1;
        };
      (O.prototype.dispose = function() {
        this.disposed_ || ((this.disposed_ = !0), this.disposeInternal());
      }),
        (O.prototype.disposeInternal = function() {});
      var P = O;
      function C() {}
      var R = function(t) {
        this.propagationStopped, (this.type = t), (this.target = null);
      };
      (R.prototype.preventDefault = function() {
        this.propagationStopped = !0;
      }),
        (R.prototype.stopPropagation = function() {
          this.propagationStopped = !0;
        });
      var j = R,
        F = (function(t) {
          function e() {
            t.call(this),
              (this.pendingRemovals_ = {}),
              (this.dispatching_ = {}),
              (this.listeners_ = {});
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            (e.prototype.addEventListener = function(t, e) {
              var i = this.listeners_[t];
              i || (i = this.listeners_[t] = []),
                -1 === i.indexOf(e) && i.push(e);
            }),
            (e.prototype.dispatchEvent = function(t) {
              var e = "string" == typeof t ? new j(t) : t,
                i = e.type;
              e.target = this;
              var r,
                n = this.listeners_[i];
              if (n) {
                i in this.dispatching_ ||
                  ((this.dispatching_[i] = 0), (this.pendingRemovals_[i] = 0)),
                  ++this.dispatching_[i];
                for (var o = 0, s = n.length; o < s; ++o)
                  if (!1 === n[o].call(this, e) || e.propagationStopped) {
                    r = !1;
                    break;
                  }
                if ((--this.dispatching_[i], 0 === this.dispatching_[i])) {
                  var a = this.pendingRemovals_[i];
                  for (delete this.pendingRemovals_[i]; a--; )
                    this.removeEventListener(i, C);
                  delete this.dispatching_[i];
                }
                return r;
              }
            }),
            (e.prototype.disposeInternal = function() {
              !(function(t) {
                var e = l(t);
                if (e) for (var i in e) p(t, i);
              })(this);
            }),
            (e.prototype.getListeners = function(t) {
              return this.listeners_[t];
            }),
            (e.prototype.hasListener = function(t) {
              return t
                ? t in this.listeners_
                : Object.keys(this.listeners_).length > 0;
            }),
            (e.prototype.removeEventListener = function(t, e) {
              var i = this.listeners_[t];
              if (i) {
                var r = i.indexOf(e);
                t in this.pendingRemovals_
                  ? ((i[r] = C), ++this.pendingRemovals_[t])
                  : (i.splice(r, 1),
                    0 === i.length && delete this.listeners_[t]);
              }
            }),
            e
          );
        })(P);
      var I = (function(t) {
          function e() {
            t.call(this), (this.revision_ = 0);
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            (e.prototype.changed = function() {
              ++this.revision_, this.dispatchEvent(y);
            }),
            (e.prototype.getRevision = function() {
              return this.revision_;
            }),
            (e.prototype.on = function(t, e) {
              if (Array.isArray(t)) {
                for (var i = t.length, r = new Array(i), n = 0; n < i; ++n)
                  r[n] = f(this, t[n], e);
                return r;
              }
              return f(this, t, e);
            }),
            (e.prototype.once = function(t, e) {
              if (Array.isArray(t)) {
                for (var i = t.length, r = new Array(i), n = 0; n < i; ++n)
                  r[n] = g(this, t[n], e);
                return r;
              }
              return g(this, t, e);
            }),
            (e.prototype.un = function(t, e) {
              if (Array.isArray(t))
                for (var i = 0, r = t.length; i < r; ++i) _(this, t[i], e);
              else _(this, t, e);
            }),
            e
          );
        })(F),
        M = (function(t) {
          function e(e, i, r) {
            t.call(this, e), (this.key = i), (this.oldValue = r);
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            e
          );
        })(j),
        G = {};
      function A(t) {
        return G.hasOwnProperty(t) ? G[t] : (G[t] = "change:" + t);
      }
      var X = (function(t) {
          function e(e) {
            t.call(this),
              b(this),
              (this.values_ = {}),
              void 0 !== e && this.setProperties(e);
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            (e.prototype.get = function(t) {
              var e;
              return this.values_.hasOwnProperty(t) && (e = this.values_[t]), e;
            }),
            (e.prototype.getKeys = function() {
              return Object.keys(this.values_);
            }),
            (e.prototype.getProperties = function() {
              return a({}, this.values_);
            }),
            (e.prototype.notify = function(t, e) {
              var i;
              (i = A(t)),
                this.dispatchEvent(new M(i, t, e)),
                (i = S),
                this.dispatchEvent(new M(i, t, e));
            }),
            (e.prototype.set = function(t, e, i) {
              if (i) this.values_[t] = e;
              else {
                var r = this.values_[t];
                (this.values_[t] = e), r !== e && this.notify(t, r);
              }
            }),
            (e.prototype.setProperties = function(t, e) {
              for (var i in t) this.set(i, t[i], e);
            }),
            (e.prototype.unset = function(t, e) {
              if (t in this.values_) {
                var i = this.values_[t];
                delete this.values_[t], e || this.notify(t, i);
              }
            }),
            e
          );
        })(I),
        z = {
          OPACITY: "opacity",
          VISIBLE: "visible",
          EXTENT: "extent",
          Z_INDEX: "zIndex",
          MAX_RESOLUTION: "maxResolution",
          MIN_RESOLUTION: "minResolution",
          SOURCE: "source"
        },
        U = (function(t) {
          function e(e) {
            var i =
              "Assertion failed. See https://openlayers.org/en/" +
              ("latest" === w ? w : "v" + w.split("-")[0]) +
              "/doc/errors/#" +
              e +
              " for details.";
            t.call(this, i),
              (this.code = e),
              (this.name = "AssertionError"),
              (this.message = i);
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            e
          );
        })(Error);
      function k(t, e) {
        if (!t) throw new U(e);
      }
      function Y(t, e, i) {
        return Math.min(Math.max(t, e), i);
      }
      var K =
        "cosh" in Math
          ? Math.cosh
          : function(t) {
              var e = Math.exp(t);
              return (e + 1 / e) / 2;
            };
      function D(t) {
        return (t * Math.PI) / 180;
      }
      function W(t, e) {
        var i = t % e;
        return i * e < 0 ? i + e : i;
      }
      var Z = "precompose",
        N = {
          UNDEFINED: "undefined",
          LOADING: "loading",
          READY: "ready",
          ERROR: "error"
        };
      var q = "preload",
        H = "useInterimTilesOnError",
        B = (function(t) {
          function e(e) {
            var i = e || {},
              r = a({}, i);
            delete r.preload,
              delete r.useInterimTilesOnError,
              t.call(this, r),
              this.setPreload(void 0 !== i.preload ? i.preload : 0),
              this.setUseInterimTilesOnError(
                void 0 === i.useInterimTilesOnError || i.useInterimTilesOnError
              ),
              (this.type = s.TILE);
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            (e.prototype.getPreload = function() {
              return this.get(q);
            }),
            (e.prototype.setPreload = function(t) {
              this.set(q, t);
            }),
            (e.prototype.getUseInterimTilesOnError = function() {
              return this.get(H);
            }),
            (e.prototype.setUseInterimTilesOnError = function(t) {
              this.set(H, t);
            }),
            e
          );
        })(
          (function(t) {
            function e(e) {
              var i = a({}, e);
              delete i.source,
                t.call(this, i),
                (this.mapPrecomposeKey_ = null),
                (this.mapRenderKey_ = null),
                (this.sourceChangeKey_ = null),
                e.map && this.setMap(e.map),
                f(this, A(z.SOURCE), this.handleSourcePropertyChange_, this);
              var r = e.source ? e.source : null;
              this.setSource(r);
            }
            return (
              t && (e.__proto__ = t),
              (e.prototype = Object.create(t && t.prototype)),
              (e.prototype.constructor = e),
              (e.prototype.getLayersArray = function(t) {
                var e = t || [];
                return e.push(this), e;
              }),
              (e.prototype.getLayerStatesArray = function(t) {
                var e = t || [];
                return e.push(this.getLayerState()), e;
              }),
              (e.prototype.getSource = function() {
                return this.get(z.SOURCE) || null;
              }),
              (e.prototype.getSourceState = function() {
                var t = this.getSource();
                return t ? t.getState() : N.UNDEFINED;
              }),
              (e.prototype.handleSourceChange_ = function() {
                this.changed();
              }),
              (e.prototype.handleSourcePropertyChange_ = function() {
                this.sourceChangeKey_ &&
                  (d(this.sourceChangeKey_), (this.sourceChangeKey_ = null));
                var t = this.getSource();
                t &&
                  (this.sourceChangeKey_ = f(
                    t,
                    y,
                    this.handleSourceChange_,
                    this
                  )),
                  this.changed();
              }),
              (e.prototype.setMap = function(t) {
                this.mapPrecomposeKey_ &&
                  (d(this.mapPrecomposeKey_), (this.mapPrecomposeKey_ = null)),
                  t || this.changed(),
                  this.mapRenderKey_ &&
                    (d(this.mapRenderKey_), (this.mapRenderKey_ = null)),
                  t &&
                    ((this.mapPrecomposeKey_ = f(
                      t,
                      Z,
                      function(t) {
                        var e = t,
                          i = this.getLayerState();
                        (i.managed = !1),
                          void 0 === this.getZIndex() && (i.zIndex = 1 / 0),
                          e.frameState.layerStatesArray.push(i),
                          (e.frameState.layerStates[b(this)] = i);
                      },
                      this
                    )),
                    (this.mapRenderKey_ = f(this, y, t.render, t)),
                    this.changed());
              }),
              (e.prototype.setSource = function(t) {
                this.set(z.SOURCE, t);
              }),
              e
            );
          })(
            (function(t) {
              function e(e) {
                t.call(this);
                var i = a({}, e);
                (i[z.OPACITY] = void 0 !== e.opacity ? e.opacity : 1),
                  (i[z.VISIBLE] = void 0 === e.visible || e.visible),
                  (i[z.Z_INDEX] = e.zIndex),
                  (i[z.MAX_RESOLUTION] =
                    void 0 !== e.maxResolution ? e.maxResolution : 1 / 0),
                  (i[z.MIN_RESOLUTION] =
                    void 0 !== e.minResolution ? e.minResolution : 0),
                  this.setProperties(i),
                  (this.state_ = null),
                  this.type;
              }
              return (
                t && (e.__proto__ = t),
                (e.prototype = Object.create(t && t.prototype)),
                (e.prototype.constructor = e),
                (e.prototype.getType = function() {
                  return this.type;
                }),
                (e.prototype.getLayerState = function() {
                  var t = this.state_ || { layer: this, managed: !0 };
                  return (
                    (t.opacity = Y(this.getOpacity(), 0, 1)),
                    (t.sourceState = this.getSourceState()),
                    (t.visible = this.getVisible()),
                    (t.extent = this.getExtent()),
                    (t.zIndex = this.getZIndex() || 0),
                    (t.maxResolution = this.getMaxResolution()),
                    (t.minResolution = Math.max(this.getMinResolution(), 0)),
                    (this.state_ = t),
                    t
                  );
                }),
                (e.prototype.getLayersArray = function(t) {
                  return T();
                }),
                (e.prototype.getLayerStatesArray = function(t) {
                  return T();
                }),
                (e.prototype.getExtent = function() {
                  return this.get(z.EXTENT);
                }),
                (e.prototype.getMaxResolution = function() {
                  return this.get(z.MAX_RESOLUTION);
                }),
                (e.prototype.getMinResolution = function() {
                  return this.get(z.MIN_RESOLUTION);
                }),
                (e.prototype.getOpacity = function() {
                  return this.get(z.OPACITY);
                }),
                (e.prototype.getSourceState = function() {
                  return T();
                }),
                (e.prototype.getVisible = function() {
                  return this.get(z.VISIBLE);
                }),
                (e.prototype.getZIndex = function() {
                  return this.get(z.Z_INDEX);
                }),
                (e.prototype.setExtent = function(t) {
                  this.set(z.EXTENT, t);
                }),
                (e.prototype.setMaxResolution = function(t) {
                  this.set(z.MAX_RESOLUTION, t);
                }),
                (e.prototype.setMinResolution = function(t) {
                  this.set(z.MIN_RESOLUTION, t);
                }),
                (e.prototype.setOpacity = function(t) {
                  this.set(z.OPACITY, t);
                }),
                (e.prototype.setVisible = function(t) {
                  this.set(z.VISIBLE, t);
                }),
                (e.prototype.setZIndex = function(t) {
                  this.set(z.Z_INDEX, t);
                }),
                e
              );
            })(X)
          )
        );
      B.prototype.getSource;
      var V = B,
        Q = 0.5,
        J = { IDLE: 0, LOADING: 1, LOADED: 2, ERROR: 3, EMPTY: 4, ABORT: 5 };
      function $(t) {
        return Math.pow(t, 3);
      }
      var tt = (function(t) {
        function e(e, i, r) {
          t.call(this);
          var n = r || {};
          (this.tileCoord = e),
            (this.state = i),
            (this.interimTile = null),
            (this.key = ""),
            (this.transition_ = void 0 === n.transition ? 250 : n.transition),
            (this.transitionStarts_ = {});
        }
        return (
          t && (e.__proto__ = t),
          (e.prototype = Object.create(t && t.prototype)),
          (e.prototype.constructor = e),
          (e.prototype.changed = function() {
            this.dispatchEvent(y);
          }),
          (e.prototype.getKey = function() {
            return this.key + "/" + this.tileCoord;
          }),
          (e.prototype.getInterimTile = function() {
            if (!this.interimTile) return this;
            var t = this.interimTile;
            do {
              if (t.getState() == J.LOADED) return t;
              t = t.interimTile;
            } while (t);
            return this;
          }),
          (e.prototype.refreshInterimChain = function() {
            if (this.interimTile) {
              var t = this.interimTile,
                e = this;
              do {
                if (t.getState() == J.LOADED) {
                  t.interimTile = null;
                  break;
                }
                t.getState() == J.LOADING
                  ? (e = t)
                  : t.getState() == J.IDLE
                  ? (e.interimTile = t.interimTile)
                  : (e = t),
                  (t = e.interimTile);
              } while (t);
            }
          }),
          (e.prototype.getTileCoord = function() {
            return this.tileCoord;
          }),
          (e.prototype.getState = function() {
            return this.state;
          }),
          (e.prototype.setState = function(t) {
            (this.state = t), this.changed();
          }),
          (e.prototype.load = function() {}),
          (e.prototype.getAlpha = function(t, e) {
            if (!this.transition_) return 1;
            var i = this.transitionStarts_[t];
            if (i) {
              if (-1 === i) return 1;
            } else (i = e), (this.transitionStarts_[t] = i);
            var r = e - i + 1e3 / 60;
            return r >= this.transition_ ? 1 : $(r / this.transition_);
          }),
          (e.prototype.inTransition = function(t) {
            return !!this.transition_ && -1 !== this.transitionStarts_[t];
          }),
          (e.prototype.endTransition = function(t) {
            this.transition_ && (this.transitionStarts_[t] = -1);
          }),
          e
        );
      })(F);
      function et(t, e) {
        var i = document.createElement("canvas");
        return t && (i.width = t), e && (i.height = e), i.getContext("2d");
      }
      function it() {
        var t = et(1, 1);
        return (
          (t.fillStyle = "rgba(0,0,0,0)"), t.fillRect(0, 0, 1, 1), t.canvas
        );
      }
      var rt = (function(t) {
        function e(e, i, r, n, o, s) {
          t.call(this, e, i, s),
            (this.crossOrigin_ = n),
            (this.src_ = r),
            (this.image_ = new Image()),
            null !== n && (this.image_.crossOrigin = n),
            (this.imageListenerKeys_ = null),
            (this.tileLoadFunction_ = o);
        }
        return (
          t && (e.__proto__ = t),
          (e.prototype = Object.create(t && t.prototype)),
          (e.prototype.constructor = e),
          (e.prototype.disposeInternal = function() {
            this.state == J.LOADING &&
              (this.unlistenImage_(), (this.image_ = it())),
              this.interimTile && this.interimTile.dispose(),
              (this.state = J.ABORT),
              this.changed(),
              t.prototype.disposeInternal.call(this);
          }),
          (e.prototype.getImage = function() {
            return this.image_;
          }),
          (e.prototype.getKey = function() {
            return this.src_;
          }),
          (e.prototype.handleImageError_ = function() {
            (this.state = J.ERROR),
              this.unlistenImage_(),
              (this.image_ = it()),
              this.changed();
          }),
          (e.prototype.handleImageLoad_ = function() {
            var t = this.image_;
            t.naturalWidth && t.naturalHeight
              ? (this.state = J.LOADED)
              : (this.state = J.EMPTY),
              this.unlistenImage_(),
              this.changed();
          }),
          (e.prototype.load = function() {
            this.state == J.ERROR &&
              ((this.state = J.IDLE),
              (this.image_ = new Image()),
              null !== this.crossOrigin_ &&
                (this.image_.crossOrigin = this.crossOrigin_)),
              this.state == J.IDLE &&
                ((this.state = J.LOADING),
                this.changed(),
                (this.imageListenerKeys_ = [
                  g(this.image_, m, this.handleImageError_, this),
                  g(this.image_, E, this.handleImageLoad_, this)
                ]),
                this.tileLoadFunction_(this, this.src_));
          }),
          (e.prototype.unlistenImage_ = function() {
            this.imageListenerKeys_.forEach(d),
              (this.imageListenerKeys_ = null);
          }),
          e
        );
      })(tt);
      function nt(t, e, i, r) {
        return void 0 !== r
          ? ((r[0] = t), (r[1] = e), (r[2] = i), r)
          : [t, e, i];
      }
      function ot(t, e, i) {
        return t + "/" + e + "/" + i;
      }
      function st(t) {
        return ot(t[0], t[1], t[2]);
      }
      var at = (function(t) {
          function e(e) {
            t.call(this, e);
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            (e.prototype.expireCache = function(t) {
              for (; this.canExpireCache(); ) {
                var e = this.peekLast(),
                  i = e.tileCoord[0].toString();
                if (i in t && t[i].contains(e.tileCoord)) break;
                this.pop().dispose();
              }
            }),
            (e.prototype.pruneExceptNewestZ = function() {
              if (0 !== this.getCount()) {
                var t = (function(t) {
                  return t.split("/").map(Number);
                })(this.peekFirstKey())[0];
                this.forEach(function(e) {
                  e.tileCoord[0] !== t &&
                    (this.remove(st(e.tileCoord)), e.dispose());
                }, this);
              }
            }),
            e
          );
        })(
          (function(t) {
            function e(e) {
              t.call(this),
                (this.highWaterMark = void 0 !== e ? e : 2048),
                (this.count_ = 0),
                (this.entries_ = {}),
                (this.oldest_ = null),
                (this.newest_ = null);
            }
            return (
              t && (e.__proto__ = t),
              (e.prototype = Object.create(t && t.prototype)),
              (e.prototype.constructor = e),
              (e.prototype.canExpireCache = function() {
                return this.getCount() > this.highWaterMark;
              }),
              (e.prototype.clear = function() {
                (this.count_ = 0),
                  (this.entries_ = {}),
                  (this.oldest_ = null),
                  (this.newest_ = null),
                  this.dispatchEvent(v);
              }),
              (e.prototype.containsKey = function(t) {
                return this.entries_.hasOwnProperty(t);
              }),
              (e.prototype.forEach = function(t, e) {
                for (var i = this.oldest_; i; )
                  t.call(e, i.value_, i.key_, this), (i = i.newer);
              }),
              (e.prototype.get = function(t) {
                var e = this.entries_[t];
                return (
                  k(void 0 !== e, 15),
                  e === this.newest_
                    ? e.value_
                    : (e === this.oldest_
                        ? ((this.oldest_ = this.oldest_.newer),
                          (this.oldest_.older = null))
                        : ((e.newer.older = e.older),
                          (e.older.newer = e.newer)),
                      (e.newer = null),
                      (e.older = this.newest_),
                      (this.newest_.newer = e),
                      (this.newest_ = e),
                      e.value_)
                );
              }),
              (e.prototype.remove = function(t) {
                var e = this.entries_[t];
                return (
                  k(void 0 !== e, 15),
                  e === this.newest_
                    ? ((this.newest_ = e.older),
                      this.newest_ && (this.newest_.newer = null))
                    : e === this.oldest_
                    ? ((this.oldest_ = e.newer),
                      this.oldest_ && (this.oldest_.older = null))
                    : ((e.newer.older = e.older), (e.older.newer = e.newer)),
                  delete this.entries_[t],
                  --this.count_,
                  e.value_
                );
              }),
              (e.prototype.getCount = function() {
                return this.count_;
              }),
              (e.prototype.getKeys = function() {
                var t,
                  e = new Array(this.count_),
                  i = 0;
                for (t = this.newest_; t; t = t.older) e[i++] = t.key_;
                return e;
              }),
              (e.prototype.getValues = function() {
                var t,
                  e = new Array(this.count_),
                  i = 0;
                for (t = this.newest_; t; t = t.older) e[i++] = t.value_;
                return e;
              }),
              (e.prototype.peekLast = function() {
                return this.oldest_.value_;
              }),
              (e.prototype.peekLastKey = function() {
                return this.oldest_.key_;
              }),
              (e.prototype.peekFirstKey = function() {
                return this.newest_.key_;
              }),
              (e.prototype.pop = function() {
                var t = this.oldest_;
                return (
                  delete this.entries_[t.key_],
                  t.newer && (t.newer.older = null),
                  (this.oldest_ = t.newer),
                  this.oldest_ || (this.newest_ = null),
                  --this.count_,
                  t.value_
                );
              }),
              (e.prototype.replace = function(t, e) {
                this.get(t), (this.entries_[t].value_ = e);
              }),
              (e.prototype.set = function(t, e) {
                k(!(t in this.entries_), 16);
                var i = {
                  key_: t,
                  newer: null,
                  older: this.newest_,
                  value_: e
                };
                this.newest_ ? (this.newest_.newer = i) : (this.oldest_ = i),
                  (this.newest_ = i),
                  (this.entries_[t] = i),
                  ++this.count_;
              }),
              (e.prototype.setSize = function(t) {
                this.highWaterMark = t;
              }),
              (e.prototype.prune = function() {
                for (; this.canExpireCache(); ) this.pop();
              }),
              e
            );
          })(F)
        ),
        ut = 6371008.8;
      function ct(t, e, i) {
        var r = i || ut,
          n = D(t[1]),
          o = D(e[1]),
          s = (o - n) / 2,
          a = D(e[0] - t[0]) / 2,
          u =
            Math.sin(s) * Math.sin(s) +
            Math.sin(a) * Math.sin(a) * Math.cos(n) * Math.cos(o);
        return 2 * r * Math.atan2(Math.sqrt(u), Math.sqrt(1 - u));
      }
      var ht = {
        BOTTOM_LEFT: "bottom-left",
        BOTTOM_RIGHT: "bottom-right",
        TOP_LEFT: "top-left",
        TOP_RIGHT: "top-right"
      };
      function lt(t) {
        for (var e = ft(), i = 0, r = t.length; i < r; ++i) dt(e, t[i]);
        return e;
      }
      function pt(t, e) {
        return (function(t, e, i) {
          return t[0] <= e && e <= t[2] && t[1] <= i && i <= t[3];
        })(t, e[0], e[1]);
      }
      function ft() {
        return [1 / 0, 1 / 0, -1 / 0, -1 / 0];
      }
      function gt(t, e, i, r, n) {
        return n
          ? ((n[0] = t), (n[1] = e), (n[2] = i), (n[3] = r), n)
          : [t, e, i, r];
      }
      function _t(t) {
        return gt(1 / 0, 1 / 0, -1 / 0, -1 / 0, t);
      }
      function dt(t, e) {
        e[0] < t[0] && (t[0] = e[0]),
          e[0] > t[2] && (t[2] = e[0]),
          e[1] < t[1] && (t[1] = e[1]),
          e[1] > t[3] && (t[3] = e[1]);
      }
      function yt(t) {
        var e = 0;
        return (
          (function(t) {
            return t[2] < t[0] || t[3] < t[1];
          })(t) || (e = St(t) * Tt(t)),
          e
        );
      }
      function vt(t) {
        return [t[0], t[1]];
      }
      function mt(t) {
        return [t[2], t[1]];
      }
      function Et(t, e) {
        var i;
        return (
          e === ht.BOTTOM_LEFT
            ? (i = vt(t))
            : e === ht.BOTTOM_RIGHT
            ? (i = mt(t))
            : e === ht.TOP_LEFT
            ? (i = bt(t))
            : e === ht.TOP_RIGHT
            ? (i = wt(t))
            : k(!1, 13),
          i
        );
      }
      function Tt(t) {
        return t[3] - t[1];
      }
      function xt(t, e, i) {
        var r = i || [1 / 0, 1 / 0, -1 / 0, -1 / 0];
        return (
          Ot(t, e)
            ? (t[0] > e[0] ? (r[0] = t[0]) : (r[0] = e[0]),
              t[1] > e[1] ? (r[1] = t[1]) : (r[1] = e[1]),
              t[2] < e[2] ? (r[2] = t[2]) : (r[2] = e[2]),
              t[3] < e[3] ? (r[3] = t[3]) : (r[3] = e[3]))
            : _t(r),
          r
        );
      }
      function bt(t) {
        return [t[0], t[3]];
      }
      function wt(t) {
        return [t[2], t[3]];
      }
      function St(t) {
        return t[2] - t[0];
      }
      function Ot(t, e) {
        return t[0] <= e[2] && t[2] >= e[0] && t[1] <= e[3] && t[3] >= e[1];
      }
      var Pt = {
          DEGREES: "degrees",
          FEET: "ft",
          METERS: "m",
          PIXELS: "pixels",
          TILE_PIXELS: "tile-pixels",
          USFEET: "us-ft"
        },
        Ct = {};
      (Ct[Pt.DEGREES] = (2 * Math.PI * 6370997) / 360),
        (Ct[Pt.FEET] = 0.3048),
        (Ct[Pt.METERS] = 1),
        (Ct[Pt.USFEET] = 1200 / 3937);
      var Rt = Pt,
        jt = function(t) {
          (this.code_ = t.code),
            (this.units_ = t.units),
            (this.extent_ = void 0 !== t.extent ? t.extent : null),
            (this.worldExtent_ =
              void 0 !== t.worldExtent ? t.worldExtent : null),
            (this.axisOrientation_ =
              void 0 !== t.axisOrientation ? t.axisOrientation : "enu"),
            (this.global_ = void 0 !== t.global && t.global),
            (this.canWrapX_ = !(!this.global_ || !this.extent_)),
            (this.getPointResolutionFunc_ = t.getPointResolution),
            (this.defaultTileGrid_ = null),
            (this.metersPerUnit_ = t.metersPerUnit);
        };
      (jt.prototype.canWrapX = function() {
        return this.canWrapX_;
      }),
        (jt.prototype.getCode = function() {
          return this.code_;
        }),
        (jt.prototype.getExtent = function() {
          return this.extent_;
        }),
        (jt.prototype.getUnits = function() {
          return this.units_;
        }),
        (jt.prototype.getMetersPerUnit = function() {
          return this.metersPerUnit_ || Ct[this.units_];
        }),
        (jt.prototype.getWorldExtent = function() {
          return this.worldExtent_;
        }),
        (jt.prototype.getAxisOrientation = function() {
          return this.axisOrientation_;
        }),
        (jt.prototype.isGlobal = function() {
          return this.global_;
        }),
        (jt.prototype.setGlobal = function(t) {
          (this.global_ = t), (this.canWrapX_ = !(!t || !this.extent_));
        }),
        (jt.prototype.getDefaultTileGrid = function() {
          return this.defaultTileGrid_;
        }),
        (jt.prototype.setDefaultTileGrid = function(t) {
          this.defaultTileGrid_ = t;
        }),
        (jt.prototype.setExtent = function(t) {
          (this.extent_ = t), (this.canWrapX_ = !(!this.global_ || !t));
        }),
        (jt.prototype.setWorldExtent = function(t) {
          this.worldExtent_ = t;
        }),
        (jt.prototype.setGetPointResolution = function(t) {
          this.getPointResolutionFunc_ = t;
        }),
        (jt.prototype.getPointResolutionFunc = function() {
          return this.getPointResolutionFunc_;
        });
      var Ft = jt,
        It = 6378137,
        Mt = Math.PI * It,
        Lt = [-Mt, -Mt, Mt, Mt],
        Gt = [-180, -85, 180, 85],
        At = (function(t) {
          function e(e) {
            t.call(this, {
              code: e,
              units: Rt.METERS,
              extent: Lt,
              global: !0,
              worldExtent: Gt,
              getPointResolution: function(t, e) {
                return t / K(e[1] / It);
              }
            });
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            e
          );
        })(Ft),
        Xt = [
          new At("EPSG:3857"),
          new At("EPSG:102100"),
          new At("EPSG:102113"),
          new At("EPSG:900913"),
          new At("urn:ogc:def:crs:EPSG:6.18:3:3857"),
          new At("urn:ogc:def:crs:EPSG::3857"),
          new At("http://www.opengis.net/gml/srs/epsg.xml#3857")
        ];
      function zt(t, e, i) {
        var r = t.length,
          n = i > 1 ? i : 2,
          o = e;
        void 0 === o && (o = n > 2 ? t.slice() : new Array(r));
        for (var s = Mt, a = 0; a < r; a += n) {
          o[a] = (s * t[a]) / 180;
          var u = It * Math.log(Math.tan((Math.PI * (t[a + 1] + 90)) / 360));
          u > s ? (u = s) : u < -s && (u = -s), (o[a + 1] = u);
        }
        return o;
      }
      function Ut(t, e, i) {
        var r = t.length,
          n = i > 1 ? i : 2,
          o = e;
        void 0 === o && (o = n > 2 ? t.slice() : new Array(r));
        for (var s = 0; s < r; s += n)
          (o[s] = (180 * t[s]) / Mt),
            (o[s + 1] =
              (360 * Math.atan(Math.exp(t[s + 1] / It))) / Math.PI - 90);
        return o;
      }
      var kt = [-180, -90, 180, 90],
        Yt = (6378137 * Math.PI) / 180,
        Kt = (function(t) {
          function e(e, i) {
            t.call(this, {
              code: e,
              units: Rt.DEGREES,
              extent: kt,
              axisOrientation: i,
              global: !0,
              metersPerUnit: Yt,
              worldExtent: kt
            });
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            e
          );
        })(Ft),
        Dt = [
          new Kt("CRS:84"),
          new Kt("EPSG:4326", "neu"),
          new Kt("urn:ogc:def:crs:EPSG::4326", "neu"),
          new Kt("urn:ogc:def:crs:EPSG:6.6:4326", "neu"),
          new Kt("urn:ogc:def:crs:OGC:1.3:CRS84"),
          new Kt("urn:ogc:def:crs:OGC:2:84"),
          new Kt("http://www.opengis.net/gml/srs/epsg.xml#4326", "neu"),
          new Kt("urn:x-ogc:def:crs:EPSG:4326", "neu")
        ],
        Wt = {};
      var Zt,
        Nt,
        qt,
        Ht = {};
      function Bt(t, e, i) {
        var r = t.getCode(),
          n = e.getCode();
        r in Ht || (Ht[r] = {}), (Ht[r][n] = i);
      }
      function Vt(t, e, i) {
        var r;
        if (void 0 !== e) {
          for (var n = 0, o = t.length; n < o; ++n) e[n] = t[n];
          r = e;
        } else r = t.slice();
        return r;
      }
      function Qt(t, e, i) {
        if (void 0 !== e && t !== e) {
          for (var r = 0, n = t.length; r < n; ++r) e[r] = t[r];
          t = e;
        }
        return t;
      }
      function Jt(t) {
        !(function(t, e) {
          Wt[t] = e;
        })(t.getCode(), t),
          Bt(t, t, Vt);
      }
      function $t(t) {
        return "string" == typeof t ? Wt[t] || null : t || null;
      }
      function te(t, e, i, r) {
        var n,
          o = (t = $t(t)).getPointResolutionFunc();
        if (o) n = o(e, i);
        else if ((t.getUnits() == Rt.DEGREES && !r) || r == Rt.DEGREES) n = e;
        else {
          var s = re(t, $t("EPSG:4326")),
            a = [
              i[0] - e / 2,
              i[1],
              i[0] + e / 2,
              i[1],
              i[0],
              i[1] - e / 2,
              i[0],
              i[1] + e / 2
            ];
          n =
            (ct((a = s(a, a, 2)).slice(0, 2), a.slice(2, 4)) +
              ct(a.slice(4, 6), a.slice(6, 8))) /
            2;
          var u = r ? Ct[r] : t.getMetersPerUnit();
          void 0 !== u && (n /= u);
        }
        return n;
      }
      function ee(t) {
        !(function(t) {
          t.forEach(Jt);
        })(t),
          t.forEach(function(e) {
            t.forEach(function(t) {
              e !== t && Bt(e, t, Vt);
            });
          });
      }
      function ie(t, e) {
        if (t === e) return !0;
        var i = t.getUnits() === e.getUnits();
        return t.getCode() === e.getCode() ? i : re(t, e) === Vt && i;
      }
      function re(t, e) {
        var i = (function(t, e) {
          var i;
          return t in Ht && e in Ht[t] && (i = Ht[t][e]), i;
        })(t.getCode(), e.getCode());
        return i || (i = Qt), i;
      }
      function ne(t, e) {
        return re($t(t), $t(e));
      }
      function oe(t, e, i) {
        return ne(e, i)(t, void 0, t.length);
      }
      function se(t, e, i, r) {
        var n = i - t,
          o = r - e,
          s = Math.sqrt(n * n + o * o);
        return [Math.round(i + n / s), Math.round(r + o / s)];
      }
      function ae(t, e, i, r, n, o, s, a, u, c, h) {
        var l = et(Math.round(i * t), Math.round(i * e));
        if (0 === u.length) return l.canvas;
        l.scale(i, i);
        var p = [1 / 0, 1 / 0, -1 / 0, -1 / 0];
        u.forEach(function(t, e, i) {
          var r, n;
          (r = p),
            (n = t.extent)[0] < r[0] && (r[0] = n[0]),
            n[2] > r[2] && (r[2] = n[2]),
            n[1] < r[1] && (r[1] = n[1]),
            n[3] > r[3] && (r[3] = n[3]);
        });
        var f = St(p),
          g = Tt(p),
          _ = et(Math.round((i * f) / r), Math.round((i * g) / r)),
          d = i / r;
        u.forEach(function(t, e, i) {
          var r = t.extent[0] - p[0],
            n = -(t.extent[3] - p[3]),
            o = St(t.extent),
            s = Tt(t.extent);
          _.drawImage(
            t.image,
            c,
            c,
            t.image.width - 2 * c,
            t.image.height - 2 * c,
            r * d,
            n * d,
            o * d,
            s * d
          );
        });
        var y = bt(s);
        return (
          a.getTriangles().forEach(function(t, e, n) {
            var s = t.source,
              a = t.target,
              u = s[0][0],
              c = s[0][1],
              h = s[1][0],
              f = s[1][1],
              g = s[2][0],
              d = s[2][1],
              v = (a[0][0] - y[0]) / o,
              m = -(a[0][1] - y[1]) / o,
              E = (a[1][0] - y[0]) / o,
              T = -(a[1][1] - y[1]) / o,
              x = (a[2][0] - y[0]) / o,
              b = -(a[2][1] - y[1]) / o,
              w = u,
              S = c;
            (u = 0), (c = 0);
            var O = (function(t) {
              for (var e = t.length, i = 0; i < e; i++) {
                for (var r = i, n = Math.abs(t[i][i]), o = i + 1; o < e; o++) {
                  var s = Math.abs(t[o][i]);
                  s > n && ((n = s), (r = o));
                }
                if (0 === n) return null;
                var a = t[r];
                (t[r] = t[i]), (t[i] = a);
                for (var u = i + 1; u < e; u++)
                  for (var c = -t[u][i] / t[i][i], h = i; h < e + 1; h++)
                    i == h ? (t[u][h] = 0) : (t[u][h] += c * t[i][h]);
              }
              for (var l = new Array(e), p = e - 1; p >= 0; p--) {
                l[p] = t[p][e] / t[p][p];
                for (var f = p - 1; f >= 0; f--) t[f][e] -= t[f][p] * l[p];
              }
              return l;
            })([
              [(h -= w), (f -= S), 0, 0, E - v],
              [(g -= w), (d -= S), 0, 0, x - v],
              [0, 0, h, f, T - m],
              [0, 0, g, d, b - m]
            ]);
            if (O) {
              l.save(), l.beginPath();
              var P = (v + E + x) / 3,
                C = (m + T + b) / 3,
                R = se(P, C, v, m),
                j = se(P, C, E, T),
                F = se(P, C, x, b);
              l.moveTo(j[0], j[1]),
                l.lineTo(R[0], R[1]),
                l.lineTo(F[0], F[1]),
                l.clip(),
                l.transform(O[0], O[2], O[1], O[3], v, m),
                l.translate(p[0] - w, p[3] - S),
                l.scale(r / i, -r / i),
                l.drawImage(_.canvas, 0, 0),
                l.restore();
            }
          }),
          h &&
            (l.save(),
            (l.strokeStyle = "black"),
            (l.lineWidth = 1),
            a.getTriangles().forEach(function(t, e, i) {
              var r = t.target,
                n = (r[0][0] - y[0]) / o,
                s = -(r[0][1] - y[1]) / o,
                a = (r[1][0] - y[0]) / o,
                u = -(r[1][1] - y[1]) / o,
                c = (r[2][0] - y[0]) / o,
                h = -(r[2][1] - y[1]) / o;
              l.beginPath(),
                l.moveTo(a, u),
                l.lineTo(n, s),
                l.lineTo(c, h),
                l.closePath(),
                l.stroke();
            }),
            l.restore()),
          l.canvas
        );
      }
      ee(Xt),
        ee(Dt),
        (Zt = Xt),
        (Nt = zt),
        (qt = Ut),
        Dt.forEach(function(t) {
          Zt.forEach(function(e) {
            Bt(t, e, Nt), Bt(e, t, qt);
          });
        });
      var ue = function(t, e, i, r, n) {
        (this.sourceProj_ = t), (this.targetProj_ = e);
        var o = {},
          s = ne(this.targetProj_, this.sourceProj_);
        (this.transformInv_ = function(t) {
          var e = t[0] + "/" + t[1];
          return o[e] || (o[e] = s(t)), o[e];
        }),
          (this.maxSourceExtent_ = r),
          (this.errorThresholdSquared_ = n * n),
          (this.triangles_ = []),
          (this.wrapsXInSource_ = !1),
          (this.canWrapXInSource_ =
            this.sourceProj_.canWrapX() &&
            !!r &&
            !!this.sourceProj_.getExtent() &&
            St(r) == St(this.sourceProj_.getExtent())),
          (this.sourceWorldWidth_ = this.sourceProj_.getExtent()
            ? St(this.sourceProj_.getExtent())
            : null),
          (this.targetWorldWidth_ = this.targetProj_.getExtent()
            ? St(this.targetProj_.getExtent())
            : null);
        var a = bt(i),
          u = wt(i),
          c = mt(i),
          h = vt(i),
          l = this.transformInv_(a),
          p = this.transformInv_(u),
          f = this.transformInv_(c),
          g = this.transformInv_(h);
        if ((this.addQuad_(a, u, c, h, l, p, f, g, 10), this.wrapsXInSource_)) {
          var _ = 1 / 0;
          this.triangles_.forEach(function(t, e, i) {
            _ = Math.min(_, t.source[0][0], t.source[1][0], t.source[2][0]);
          }),
            this.triangles_.forEach(
              function(t) {
                if (
                  Math.max(t.source[0][0], t.source[1][0], t.source[2][0]) - _ >
                  this.sourceWorldWidth_ / 2
                ) {
                  var e = [
                    [t.source[0][0], t.source[0][1]],
                    [t.source[1][0], t.source[1][1]],
                    [t.source[2][0], t.source[2][1]]
                  ];
                  e[0][0] - _ > this.sourceWorldWidth_ / 2 &&
                    (e[0][0] -= this.sourceWorldWidth_),
                    e[1][0] - _ > this.sourceWorldWidth_ / 2 &&
                      (e[1][0] -= this.sourceWorldWidth_),
                    e[2][0] - _ > this.sourceWorldWidth_ / 2 &&
                      (e[2][0] -= this.sourceWorldWidth_);
                  var i = Math.min(e[0][0], e[1][0], e[2][0]);
                  Math.max(e[0][0], e[1][0], e[2][0]) - i <
                    this.sourceWorldWidth_ / 2 && (t.source = e);
                }
              }.bind(this)
            );
        }
        o = {};
      };
      (ue.prototype.addTriangle_ = function(t, e, i, r, n, o) {
        this.triangles_.push({ source: [r, n, o], target: [t, e, i] });
      }),
        (ue.prototype.addQuad_ = function(t, e, i, r, n, o, s, a, u) {
          var c = lt([n, o, s, a]),
            h = this.sourceWorldWidth_ ? St(c) / this.sourceWorldWidth_ : null,
            l = this.sourceWorldWidth_,
            p = this.sourceProj_.canWrapX() && h > 0.5 && h < 1,
            f = !1;
          if (u > 0) {
            if (this.targetProj_.isGlobal() && this.targetWorldWidth_)
              f = St(lt([t, e, i, r])) / this.targetWorldWidth_ > 0.25 || f;
            !p && this.sourceProj_.isGlobal() && h && (f = h > 0.25 || f);
          }
          if (f || !this.maxSourceExtent_ || Ot(c, this.maxSourceExtent_)) {
            if (
              !(
                f ||
                (isFinite(n[0]) &&
                  isFinite(n[1]) &&
                  isFinite(o[0]) &&
                  isFinite(o[1]) &&
                  isFinite(s[0]) &&
                  isFinite(s[1]) &&
                  isFinite(a[0]) &&
                  isFinite(a[1]))
              )
            ) {
              if (!(u > 0)) return;
              f = !0;
            }
            if (u > 0) {
              if (!f) {
                var g,
                  _ = [(t[0] + i[0]) / 2, (t[1] + i[1]) / 2],
                  d = this.transformInv_(_);
                if (p) g = (W(n[0], l) + W(s[0], l)) / 2 - W(d[0], l);
                else g = (n[0] + s[0]) / 2 - d[0];
                var y = (n[1] + s[1]) / 2 - d[1];
                f = g * g + y * y > this.errorThresholdSquared_;
              }
              if (f) {
                if (Math.abs(t[0] - i[0]) <= Math.abs(t[1] - i[1])) {
                  var v = [(e[0] + i[0]) / 2, (e[1] + i[1]) / 2],
                    m = this.transformInv_(v),
                    E = [(r[0] + t[0]) / 2, (r[1] + t[1]) / 2],
                    T = this.transformInv_(E);
                  this.addQuad_(t, e, v, E, n, o, m, T, u - 1),
                    this.addQuad_(E, v, i, r, T, m, s, a, u - 1);
                } else {
                  var x = [(t[0] + e[0]) / 2, (t[1] + e[1]) / 2],
                    b = this.transformInv_(x),
                    w = [(i[0] + r[0]) / 2, (i[1] + r[1]) / 2],
                    S = this.transformInv_(w);
                  this.addQuad_(t, x, w, r, n, b, S, a, u - 1),
                    this.addQuad_(x, e, i, w, b, o, s, S, u - 1);
                }
                return;
              }
            }
            if (p) {
              if (!this.canWrapXInSource_) return;
              this.wrapsXInSource_ = !0;
            }
            this.addTriangle_(t, i, r, n, s, a),
              this.addTriangle_(t, e, i, n, o, s);
          }
        }),
        (ue.prototype.calculateSourceExtent = function() {
          var t = [1 / 0, 1 / 0, -1 / 0, -1 / 0];
          return (
            this.triangles_.forEach(function(e, i, r) {
              var n = e.source;
              dt(t, n[0]), dt(t, n[1]), dt(t, n[2]);
            }),
            t
          );
        }),
        (ue.prototype.getTriangles = function() {
          return this.triangles_;
        });
      var ce = ue,
        he = (function(t) {
          function e(e, i, r, n, o, s, a, u, c, h, l) {
            t.call(this, o, J.IDLE),
              (this.renderEdges_ = void 0 !== l && l),
              (this.pixelRatio_ = a),
              (this.gutter_ = u),
              (this.canvas_ = null),
              (this.sourceTileGrid_ = i),
              (this.targetTileGrid_ = n),
              (this.wrappedTileCoord_ = s || o),
              (this.sourceTiles_ = []),
              (this.sourcesListenerKeys_ = null),
              (this.sourceZ_ = 0);
            var p = n.getTileCoordExtent(this.wrappedTileCoord_),
              f = this.targetTileGrid_.getExtent(),
              g = this.sourceTileGrid_.getExtent(),
              _ = f ? xt(p, f) : p;
            if (0 !== yt(_)) {
              var d = e.getExtent();
              d && (g = g ? xt(g, d) : d);
              var y,
                v = n.getResolution(this.wrappedTileCoord_[0]),
                m = (function(t, e, i, r) {
                  var n = oe(i, e, t),
                    o = te(e, r, i),
                    s = e.getMetersPerUnit();
                  void 0 !== s && (o *= s);
                  var a = t.getMetersPerUnit();
                  void 0 !== a && (o /= a);
                  var u = t.getExtent();
                  if (!u || pt(u, n)) {
                    var c = te(t, o, n) / o;
                    isFinite(c) && c > 0 && (o /= c);
                  }
                  return o;
                })(e, r, [((y = _)[0] + y[2]) / 2, (y[1] + y[3]) / 2], v);
              if (!isFinite(m) || m <= 0) this.state = J.EMPTY;
              else {
                var E = void 0 !== h ? h : Q;
                if (
                  ((this.triangulation_ = new ce(e, r, _, g, m * E)),
                  0 !== this.triangulation_.getTriangles().length)
                ) {
                  this.sourceZ_ = i.getZForResolution(m);
                  var T = this.triangulation_.calculateSourceExtent();
                  if (
                    (g &&
                      (e.canWrapX()
                        ? ((T[1] = Y(T[1], g[1], g[3])),
                          (T[3] = Y(T[3], g[1], g[3])))
                        : (T = xt(T, g))),
                    yt(T))
                  ) {
                    for (
                      var x = i.getTileRangeForExtentAndZ(T, this.sourceZ_),
                        b = x.minX;
                      b <= x.maxX;
                      b++
                    )
                      for (var w = x.minY; w <= x.maxY; w++) {
                        var S = c(this.sourceZ_, b, w, a);
                        S && this.sourceTiles_.push(S);
                      }
                    0 === this.sourceTiles_.length && (this.state = J.EMPTY);
                  } else this.state = J.EMPTY;
                } else this.state = J.EMPTY;
              }
            } else this.state = J.EMPTY;
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            (e.prototype.disposeInternal = function() {
              this.state == J.LOADING && this.unlistenSources_(),
                t.prototype.disposeInternal.call(this);
            }),
            (e.prototype.getImage = function() {
              return this.canvas_;
            }),
            (e.prototype.reproject_ = function() {
              var t = [];
              if (
                (this.sourceTiles_.forEach(
                  function(e, i, r) {
                    e &&
                      e.getState() == J.LOADED &&
                      t.push({
                        extent: this.sourceTileGrid_.getTileCoordExtent(
                          e.tileCoord
                        ),
                        image: e.getImage()
                      });
                  }.bind(this)
                ),
                (this.sourceTiles_.length = 0),
                0 === t.length)
              )
                this.state = J.ERROR;
              else {
                var e = this.wrappedTileCoord_[0],
                  i = this.targetTileGrid_.getTileSize(e),
                  r = "number" == typeof i ? i : i[0],
                  n = "number" == typeof i ? i : i[1],
                  o = this.targetTileGrid_.getResolution(e),
                  s = this.sourceTileGrid_.getResolution(this.sourceZ_),
                  a = this.targetTileGrid_.getTileCoordExtent(
                    this.wrappedTileCoord_
                  );
                (this.canvas_ = ae(
                  r,
                  n,
                  this.pixelRatio_,
                  s,
                  this.sourceTileGrid_.getExtent(),
                  o,
                  a,
                  this.triangulation_,
                  t,
                  this.gutter_,
                  this.renderEdges_
                )),
                  (this.state = J.LOADED);
              }
              this.changed();
            }),
            (e.prototype.load = function() {
              if (this.state == J.IDLE) {
                (this.state = J.LOADING), this.changed();
                var t = 0;
                (this.sourcesListenerKeys_ = []),
                  this.sourceTiles_.forEach(
                    function(e, i, r) {
                      var n = e.getState();
                      if (n == J.IDLE || n == J.LOADING) {
                        t++;
                        var o = f(
                          e,
                          y,
                          function(i) {
                            var r = e.getState();
                            (r != J.LOADED && r != J.ERROR && r != J.EMPTY) ||
                              (d(o),
                              0 === --t &&
                                (this.unlistenSources_(), this.reproject_()));
                          },
                          this
                        );
                        this.sourcesListenerKeys_.push(o);
                      }
                    }.bind(this)
                  ),
                  this.sourceTiles_.forEach(function(t, e, i) {
                    t.getState() == J.IDLE && t.load();
                  }),
                  0 === t && setTimeout(this.reproject_.bind(this), 0);
              }
            }),
            (e.prototype.unlistenSources_ = function() {
              this.sourcesListenerKeys_.forEach(d),
                (this.sourcesListenerKeys_ = null);
            }),
            e
          );
        })(tt);
      function le(t, e) {
        var i = /\{z\}/g,
          r = /\{x\}/g,
          n = /\{y\}/g,
          o = /\{-y\}/g;
        return function(s, a, u) {
          return s
            ? t
                .replace(i, s[0].toString())
                .replace(r, s[1].toString())
                .replace(n, function() {
                  return (-s[2] - 1).toString();
                })
                .replace(o, function() {
                  var t = s[0],
                    i = e.getFullTileRange(t);
                  return k(i, 55), (i.getHeight() + s[2]).toString();
                })
            : void 0;
        };
      }
      function pe(t, e) {
        for (var i = t.length, r = new Array(i), n = 0; n < i; ++n)
          r[n] = le(t[n], e);
        return (function(t) {
          if (1 === t.length) return t[0];
          return function(e, i, r) {
            if (e) {
              var n = (function(t) {
                  return (t[1] << t[0]) + t[2];
                })(e),
                o = W(n, t.length);
              return t[o](e, i, r);
            }
          };
        })(r);
      }
      function fe(t, e, i) {}
      function ge(t, e) {
        return Array.isArray(t)
          ? t
          : (void 0 === e ? (e = [t, t]) : (e[0] = e[1] = t), e);
      }
      function _e(t) {
        return t
          ? Array.isArray(t)
            ? function(e) {
                return t;
              }
            : "function" == typeof t
            ? t
            : function(e) {
                return [t];
              }
          : null;
      }
      var de = (function(t) {
          function e(e) {
            t.call(this),
              (this.projection_ = $t(e.projection)),
              (this.attributions_ = _e(e.attributions)),
              (this.attributionsCollapsible_ =
                void 0 === e.attributionsCollapsible ||
                e.attributionsCollapsible),
              (this.loading = !1),
              (this.state_ = void 0 !== e.state ? e.state : N.READY),
              (this.wrapX_ = void 0 !== e.wrapX && e.wrapX);
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            (e.prototype.getAttributions = function() {
              return this.attributions_;
            }),
            (e.prototype.getAttributionsCollapsible = function() {
              return this.attributionsCollapsible_;
            }),
            (e.prototype.getProjection = function() {
              return this.projection_;
            }),
            (e.prototype.getResolutions = function() {
              return T();
            }),
            (e.prototype.getState = function() {
              return this.state_;
            }),
            (e.prototype.getWrapX = function() {
              return this.wrapX_;
            }),
            (e.prototype.refresh = function() {
              this.changed();
            }),
            (e.prototype.setAttributions = function(t) {
              (this.attributions_ = _e(t)), this.changed();
            }),
            (e.prototype.setState = function(t) {
              (this.state_ = t), this.changed();
            }),
            e
          );
        })(X),
        ye = 42,
        ve = 256,
        me = function(t, e, i, r) {
          (this.minX = t), (this.maxX = e), (this.minY = i), (this.maxY = r);
        };
      function Ee(t, e, i, r, n) {
        return void 0 !== n
          ? ((n.minX = t), (n.maxX = e), (n.minY = i), (n.maxY = r), n)
          : new me(t, e, i, r);
      }
      (me.prototype.contains = function(t) {
        return this.containsXY(t[1], t[2]);
      }),
        (me.prototype.containsTileRange = function(t) {
          return (
            this.minX <= t.minX &&
            t.maxX <= this.maxX &&
            this.minY <= t.minY &&
            t.maxY <= this.maxY
          );
        }),
        (me.prototype.containsXY = function(t, e) {
          return (
            this.minX <= t && t <= this.maxX && this.minY <= e && e <= this.maxY
          );
        }),
        (me.prototype.equals = function(t) {
          return (
            this.minX == t.minX &&
            this.minY == t.minY &&
            this.maxX == t.maxX &&
            this.maxY == t.maxY
          );
        }),
        (me.prototype.extend = function(t) {
          t.minX < this.minX && (this.minX = t.minX),
            t.maxX > this.maxX && (this.maxX = t.maxX),
            t.minY < this.minY && (this.minY = t.minY),
            t.maxY > this.maxY && (this.maxY = t.maxY);
        }),
        (me.prototype.getHeight = function() {
          return this.maxY - this.minY + 1;
        }),
        (me.prototype.getSize = function() {
          return [this.getWidth(), this.getHeight()];
        }),
        (me.prototype.getWidth = function() {
          return this.maxX - this.minX + 1;
        }),
        (me.prototype.intersects = function(t) {
          return (
            this.minX <= t.maxX &&
            this.maxX >= t.minX &&
            this.minY <= t.maxY &&
            this.maxY >= t.minY
          );
        });
      var Te = me;
      function xe(t, e) {
        return t > e ? 1 : t < e ? -1 : 0;
      }
      var be = [0, 0, 0],
        we = function(t) {
          var e, i, r, n;
          if (
            ((this.minZoom = void 0 !== t.minZoom ? t.minZoom : 0),
            (this.resolutions_ = t.resolutions),
            k(
              ((e = this.resolutions_),
              (i = !0),
              (r =
                function(t, e) {
                  return e - t;
                } || xe),
              e.every(function(t, n) {
                if (0 === n) return !0;
                var o = r(e[n - 1], t);
                return !(o > 0 || (i && 0 === o));
              })),
              17
            ),
            !t.origins)
          )
            for (var o = 0, s = this.resolutions_.length - 1; o < s; ++o)
              if (n) {
                if (this.resolutions_[o] / this.resolutions_[o + 1] !== n) {
                  n = void 0;
                  break;
                }
              } else n = this.resolutions_[o] / this.resolutions_[o + 1];
          (this.zoomFactor_ = n),
            (this.maxZoom = this.resolutions_.length - 1),
            (this.origin_ = void 0 !== t.origin ? t.origin : null),
            (this.origins_ = null),
            void 0 !== t.origins &&
              ((this.origins_ = t.origins),
              k(this.origins_.length == this.resolutions_.length, 20));
          var a = t.extent;
          void 0 === a ||
            this.origin_ ||
            this.origins_ ||
            (this.origin_ = bt(a)),
            k(
              (!this.origin_ && this.origins_) ||
                (this.origin_ && !this.origins_),
              18
            ),
            (this.tileSizes_ = null),
            void 0 !== t.tileSizes &&
              ((this.tileSizes_ = t.tileSizes),
              k(this.tileSizes_.length == this.resolutions_.length, 19)),
            (this.tileSize_ =
              void 0 !== t.tileSize ? t.tileSize : this.tileSizes_ ? null : ve),
            k(
              (!this.tileSize_ && this.tileSizes_) ||
                (this.tileSize_ && !this.tileSizes_),
              22
            ),
            (this.extent_ = void 0 !== a ? a : null),
            (this.fullTileRanges_ = null),
            (this.tmpSize_ = [0, 0]),
            void 0 !== t.sizes
              ? (this.fullTileRanges_ = t.sizes.map(function(t, e) {
                  return new Te(
                    Math.min(0, t[0]),
                    Math.max(t[0] - 1, -1),
                    Math.min(0, t[1]),
                    Math.max(t[1] - 1, -1)
                  );
                }, this))
              : a && this.calculateTileRanges_(a);
        };
      (we.prototype.forEachTileCoord = function(t, e, i) {
        for (
          var r = this.getTileRangeForExtentAndZ(t, e), n = r.minX, o = r.maxX;
          n <= o;
          ++n
        )
          for (var s = r.minY, a = r.maxY; s <= a; ++s) i([e, n, s]);
      }),
        (we.prototype.forEachTileCoordParentTileRange = function(
          t,
          e,
          i,
          r,
          n
        ) {
          var o,
            s,
            a,
            u = null,
            c = t[0] - 1;
          for (
            2 === this.zoomFactor_
              ? ((s = t[1]), (a = t[2]))
              : (u = this.getTileCoordExtent(t, n));
            c >= this.minZoom;

          ) {
            if (
              ((o =
                2 === this.zoomFactor_
                  ? Ee(
                      (s = Math.floor(s / 2)),
                      s,
                      (a = Math.floor(a / 2)),
                      a,
                      r
                    )
                  : this.getTileRangeForExtentAndZ(u, c, r)),
              e.call(i, c, o))
            )
              return !0;
            --c;
          }
          return !1;
        }),
        (we.prototype.getExtent = function() {
          return this.extent_;
        }),
        (we.prototype.getMaxZoom = function() {
          return this.maxZoom;
        }),
        (we.prototype.getMinZoom = function() {
          return this.minZoom;
        }),
        (we.prototype.getOrigin = function(t) {
          return this.origin_ ? this.origin_ : this.origins_[t];
        }),
        (we.prototype.getResolution = function(t) {
          return this.resolutions_[t];
        }),
        (we.prototype.getResolutions = function() {
          return this.resolutions_;
        }),
        (we.prototype.getTileCoordChildTileRange = function(t, e, i) {
          if (t[0] < this.maxZoom) {
            if (2 === this.zoomFactor_) {
              var r = 2 * t[1],
                n = 2 * t[2];
              return Ee(r, r + 1, n, n + 1, e);
            }
            var o = this.getTileCoordExtent(t, i);
            return this.getTileRangeForExtentAndZ(o, t[0] + 1, e);
          }
          return null;
        }),
        (we.prototype.getTileRangeExtent = function(t, e, i) {
          var r = this.getOrigin(t),
            n = this.getResolution(t),
            o = ge(this.getTileSize(t), this.tmpSize_),
            s = r[0] + e.minX * o[0] * n,
            a = r[0] + (e.maxX + 1) * o[0] * n;
          return gt(
            s,
            r[1] + e.minY * o[1] * n,
            a,
            r[1] + (e.maxY + 1) * o[1] * n,
            i
          );
        }),
        (we.prototype.getTileRangeForExtentAndZ = function(t, e, i) {
          var r = be;
          this.getTileCoordForXYAndZ_(t[0], t[1], e, !1, r);
          var n = r[1],
            o = r[2];
          return (
            this.getTileCoordForXYAndZ_(t[2], t[3], e, !0, r),
            Ee(n, r[1], o, r[2], i)
          );
        }),
        (we.prototype.getTileCoordCenter = function(t) {
          var e = this.getOrigin(t[0]),
            i = this.getResolution(t[0]),
            r = ge(this.getTileSize(t[0]), this.tmpSize_);
          return [
            e[0] + (t[1] + 0.5) * r[0] * i,
            e[1] + (t[2] + 0.5) * r[1] * i
          ];
        }),
        (we.prototype.getTileCoordExtent = function(t, e) {
          var i = this.getOrigin(t[0]),
            r = this.getResolution(t[0]),
            n = ge(this.getTileSize(t[0]), this.tmpSize_),
            o = i[0] + t[1] * n[0] * r,
            s = i[1] + t[2] * n[1] * r;
          return gt(o, s, o + n[0] * r, s + n[1] * r, e);
        }),
        (we.prototype.getTileCoordForCoordAndResolution = function(t, e, i) {
          return this.getTileCoordForXYAndResolution_(t[0], t[1], e, !1, i);
        }),
        (we.prototype.getTileCoordForXYAndResolution_ = function(
          t,
          e,
          i,
          r,
          n
        ) {
          var o = this.getZForResolution(i),
            s = i / this.getResolution(o),
            a = this.getOrigin(o),
            u = ge(this.getTileSize(o), this.tmpSize_),
            c = r ? 0.5 : 0,
            h = r ? 0 : 0.5,
            l = Math.floor((t - a[0]) / i + c),
            p = Math.floor((e - a[1]) / i + h),
            f = (s * l) / u[0],
            g = (s * p) / u[1];
          return (
            r
              ? ((f = Math.ceil(f) - 1), (g = Math.ceil(g) - 1))
              : ((f = Math.floor(f)), (g = Math.floor(g))),
            nt(o, f, g, n)
          );
        }),
        (we.prototype.getTileCoordForXYAndZ_ = function(t, e, i, r, n) {
          var o = this.getOrigin(i),
            s = this.getResolution(i),
            a = ge(this.getTileSize(i), this.tmpSize_),
            u = r ? 0.5 : 0,
            c = r ? 0 : 0.5,
            h = Math.floor((t - o[0]) / s + u),
            l = Math.floor((e - o[1]) / s + c),
            p = h / a[0],
            f = l / a[1];
          return (
            r
              ? ((p = Math.ceil(p) - 1), (f = Math.ceil(f) - 1))
              : ((p = Math.floor(p)), (f = Math.floor(f))),
            nt(i, p, f, n)
          );
        }),
        (we.prototype.getTileCoordForCoordAndZ = function(t, e, i) {
          return this.getTileCoordForXYAndZ_(t[0], t[1], e, !1, i);
        }),
        (we.prototype.getTileCoordResolution = function(t) {
          return this.resolutions_[t[0]];
        }),
        (we.prototype.getTileSize = function(t) {
          return this.tileSize_ ? this.tileSize_ : this.tileSizes_[t];
        }),
        (we.prototype.getFullTileRange = function(t) {
          return this.fullTileRanges_ ? this.fullTileRanges_[t] : null;
        }),
        (we.prototype.getZForResolution = function(t, e) {
          return Y(
            (function(t, e, i) {
              var r,
                n = t.length;
              if (t[0] <= e) return 0;
              if (e <= t[n - 1]) return n - 1;
              if (i > 0) {
                for (r = 1; r < n; ++r) if (t[r] < e) return r - 1;
              } else if (i < 0) {
                for (r = 1; r < n; ++r) if (t[r] <= e) return r;
              } else
                for (r = 1; r < n; ++r) {
                  if (t[r] == e) return r;
                  if (t[r] < e) return t[r - 1] - e < e - t[r] ? r - 1 : r;
                }
              return n - 1;
            })(this.resolutions_, t, e || 0),
            this.minZoom,
            this.maxZoom
          );
        }),
        (we.prototype.calculateTileRanges_ = function(t) {
          for (
            var e = this.resolutions_.length,
              i = new Array(e),
              r = this.minZoom;
            r < e;
            ++r
          )
            i[r] = this.getTileRangeForExtentAndZ(t, r);
          this.fullTileRanges_ = i;
        });
      var Se = we;
      function Oe(t) {
        var e = t.getDefaultTileGrid();
        return (
          e ||
            ((e = (function(t, e, i, r) {
              return (function(t, e, i, r) {
                var n = void 0 !== r ? r : ht.TOP_LEFT,
                  o = Pe(t, e, i);
                return new Se({
                  extent: t,
                  origin: Et(t, n),
                  resolutions: o,
                  tileSize: i
                });
              })(Ce(t), e, i, r);
            })(t)),
            t.setDefaultTileGrid(e)),
          e
        );
      }
      function Pe(t, e, i) {
        for (
          var r = void 0 !== e ? e : ye,
            n = Tt(t),
            o = St(t),
            s = ge(void 0 !== i ? i : ve),
            a = Math.max(o / s[0], n / s[1]),
            u = r + 1,
            c = new Array(u),
            h = 0;
          h < u;
          ++h
        )
          c[h] = a / Math.pow(2, h);
        return c;
      }
      function Ce(t) {
        var e = (t = $t(t)).getExtent();
        if (!e) {
          var i = (180 * Ct[Rt.DEGREES]) / t.getMetersPerUnit();
          e = gt(-i, -i, i, i);
        }
        return e;
      }
      var Re = (function(t) {
          function e(e) {
            t.call(this, {
              attributions: e.attributions,
              attributionsCollapsible: e.attributionsCollapsible,
              projection: e.projection,
              state: e.state,
              wrapX: e.wrapX
            }),
              (this.opaque_ = void 0 !== e.opaque && e.opaque),
              (this.tilePixelRatio_ =
                void 0 !== e.tilePixelRatio ? e.tilePixelRatio : 1),
              (this.tileGrid = void 0 !== e.tileGrid ? e.tileGrid : null),
              (this.tileCache = new at(e.cacheSize)),
              (this.tmpSize = [0, 0]),
              (this.key_ = e.key || ""),
              (this.tileOptions = { transition: e.transition });
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            (e.prototype.canExpireCache = function() {
              return this.tileCache.canExpireCache();
            }),
            (e.prototype.expireCache = function(t, e) {
              var i = this.getTileCacheForProjection(t);
              i && i.expireCache(e);
            }),
            (e.prototype.forEachLoadedTile = function(t, e, i, r) {
              var n = this.getTileCacheForProjection(t);
              if (!n) return !1;
              for (var o, s, a, u = !0, c = i.minX; c <= i.maxX; ++c)
                for (var h = i.minY; h <= i.maxY; ++h)
                  (s = ot(e, c, h)),
                    (a = !1),
                    n.containsKey(s) &&
                      (a = (o = n.get(s)).getState() === J.LOADED) &&
                      (a = !1 !== r(o)),
                    a || (u = !1);
              return u;
            }),
            (e.prototype.getGutterForProjection = function(t) {
              return 0;
            }),
            (e.prototype.getKey = function() {
              return this.key_;
            }),
            (e.prototype.setKey = function(t) {
              this.key_ !== t && ((this.key_ = t), this.changed());
            }),
            (e.prototype.getOpaque = function(t) {
              return this.opaque_;
            }),
            (e.prototype.getResolutions = function() {
              return this.tileGrid.getResolutions();
            }),
            (e.prototype.getTile = function(t, e, i, r, n) {
              return T();
            }),
            (e.prototype.getTileGrid = function() {
              return this.tileGrid;
            }),
            (e.prototype.getTileGridForProjection = function(t) {
              return this.tileGrid ? this.tileGrid : Oe(t);
            }),
            (e.prototype.getTileCacheForProjection = function(t) {
              var e = this.getProjection();
              return e && !ie(e, t) ? null : this.tileCache;
            }),
            (e.prototype.getTilePixelRatio = function(t) {
              return this.tilePixelRatio_;
            }),
            (e.prototype.getTilePixelSize = function(t, e, i) {
              var r,
                n,
                o,
                s = this.getTileGridForProjection(i),
                a = this.getTilePixelRatio(e),
                u = ge(s.getTileSize(t), this.tmpSize);
              return 1 == a
                ? u
                : ((r = u),
                  (n = a),
                  void 0 === (o = this.tmpSize) && (o = [0, 0]),
                  (o[0] = (r[0] * n + 0.5) | 0),
                  (o[1] = (r[1] * n + 0.5) | 0),
                  o);
            }),
            (e.prototype.getTileCoordForTileUrlFunction = function(t, e) {
              var i = void 0 !== e ? e : this.getProjection(),
                r = this.getTileGridForProjection(i);
              return (
                this.getWrapX() &&
                  i.isGlobal() &&
                  (t = (function(t, e, i) {
                    var r = e[0],
                      n = t.getTileCoordCenter(e),
                      o = Ce(i);
                    if (pt(o, n)) return e;
                    var s = St(o),
                      a = Math.ceil((o[0] - n[0]) / s);
                    return (n[0] += s * a), t.getTileCoordForCoordAndZ(n, r);
                  })(r, t, i)),
                (function(t, e) {
                  var i = t[0],
                    r = t[1],
                    n = t[2];
                  if (e.getMinZoom() > i || i > e.getMaxZoom()) return !1;
                  var o,
                    s = e.getExtent();
                  return (
                    !(o = s
                      ? e.getTileRangeForExtentAndZ(s, i)
                      : e.getFullTileRange(i)) || o.containsXY(r, n)
                  );
                })(t, r)
                  ? t
                  : null
              );
            }),
            (e.prototype.refresh = function() {
              this.tileCache.clear(), this.changed();
            }),
            (e.prototype.useTile = function(t, e, i, r) {}),
            e
          );
        })(de),
        je = (function(t) {
          function e(e, i) {
            t.call(this, e), (this.tile = i);
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            e
          );
        })(j),
        Fe = "tileloadstart",
        Ie = "tileloadend",
        Me = "tileloaderror";
      function Le(t, e) {
        t.getImage().src = e;
      }
      var Ge = (function(t) {
        function e(e) {
          var i = e || {},
            r = void 0 !== i.projection ? i.projection : "EPSG:3857",
            n =
              void 0 !== i.tileGrid
                ? i.tileGrid
                : (function(t) {
                    var e = t || {},
                      i = e.extent || $t("EPSG:3857").getExtent(),
                      r = {
                        extent: i,
                        minZoom: e.minZoom,
                        tileSize: e.tileSize,
                        resolutions: Pe(i, e.maxZoom, e.tileSize)
                      };
                    return new Se(r);
                  })({
                    extent: Ce(r),
                    maxZoom: i.maxZoom,
                    minZoom: i.minZoom,
                    tileSize: i.tileSize
                  });
          t.call(this, {
            attributions: i.attributions,
            cacheSize: i.cacheSize,
            crossOrigin: i.crossOrigin,
            opaque: i.opaque,
            projection: r,
            reprojectionErrorThreshold: i.reprojectionErrorThreshold,
            tileGrid: n,
            tileLoadFunction: i.tileLoadFunction,
            tilePixelRatio: i.tilePixelRatio,
            tileUrlFunction: i.tileUrlFunction,
            url: i.url,
            urls: i.urls,
            wrapX: void 0 === i.wrapX || i.wrapX,
            transition: i.transition,
            attributionsCollapsible: i.attributionsCollapsible
          });
        }
        return (
          t && (e.__proto__ = t),
          (e.prototype = Object.create(t && t.prototype)),
          (e.prototype.constructor = e),
          e
        );
      })(
        (function(t) {
          function e(e) {
            t.call(this, {
              attributions: e.attributions,
              cacheSize: e.cacheSize,
              opaque: e.opaque,
              projection: e.projection,
              state: e.state,
              tileGrid: e.tileGrid,
              tileLoadFunction: e.tileLoadFunction ? e.tileLoadFunction : Le,
              tilePixelRatio: e.tilePixelRatio,
              tileUrlFunction: e.tileUrlFunction,
              url: e.url,
              urls: e.urls,
              wrapX: e.wrapX,
              transition: e.transition,
              key: e.key,
              attributionsCollapsible: e.attributionsCollapsible
            }),
              (this.crossOrigin =
                void 0 !== e.crossOrigin ? e.crossOrigin : null),
              (this.tileClass = void 0 !== e.tileClass ? e.tileClass : rt),
              (this.tileCacheForProjection = {}),
              (this.tileGridForProjection = {}),
              (this.reprojectionErrorThreshold_ = e.reprojectionErrorThreshold),
              (this.renderReprojectionEdges_ = !1);
          }
          return (
            t && (e.__proto__ = t),
            (e.prototype = Object.create(t && t.prototype)),
            (e.prototype.constructor = e),
            (e.prototype.canExpireCache = function() {
              if (this.tileCache.canExpireCache()) return !0;
              for (var t in this.tileCacheForProjection)
                if (this.tileCacheForProjection[t].canExpireCache()) return !0;
              return !1;
            }),
            (e.prototype.expireCache = function(t, e) {
              var i = this.getTileCacheForProjection(t);
              for (var r in (this.tileCache.expireCache(
                this.tileCache == i ? e : {}
              ),
              this.tileCacheForProjection)) {
                var n = this.tileCacheForProjection[r];
                n.expireCache(n == i ? e : {});
              }
            }),
            (e.prototype.getGutterForProjection = function(t) {
              return this.getProjection() && t && !ie(this.getProjection(), t)
                ? 0
                : this.getGutter();
            }),
            (e.prototype.getGutter = function() {
              return 0;
            }),
            (e.prototype.getOpaque = function(e) {
              return (
                !(this.getProjection() && e && !ie(this.getProjection(), e)) &&
                t.prototype.getOpaque.call(this, e)
              );
            }),
            (e.prototype.getTileGridForProjection = function(t) {
              var e = this.getProjection();
              if (!this.tileGrid || (e && !ie(e, t))) {
                var i = b(t);
                return (
                  i in this.tileGridForProjection ||
                    (this.tileGridForProjection[i] = Oe(t)),
                  this.tileGridForProjection[i]
                );
              }
              return this.tileGrid;
            }),
            (e.prototype.getTileCacheForProjection = function(t) {
              var e = this.getProjection();
              if (!e || ie(e, t)) return this.tileCache;
              var i = b(t);
              return (
                i in this.tileCacheForProjection ||
                  (this.tileCacheForProjection[i] = new at(
                    this.tileCache.highWaterMark
                  )),
                this.tileCacheForProjection[i]
              );
            }),
            (e.prototype.createTile_ = function(t, e, i, r, n, o) {
              var s = [t, e, i],
                a = this.getTileCoordForTileUrlFunction(s, n),
                u = a ? this.tileUrlFunction(a, r, n) : void 0,
                c = new this.tileClass(
                  s,
                  void 0 !== u ? J.IDLE : J.EMPTY,
                  void 0 !== u ? u : "",
                  this.crossOrigin,
                  this.tileLoadFunction,
                  this.tileOptions
                );
              return (c.key = o), f(c, y, this.handleTileChange, this), c;
            }),
            (e.prototype.getTile = function(t, e, i, r, n) {
              var o = this.getProjection();
              if (o && n && !ie(o, n)) {
                var s,
                  a = this.getTileCacheForProjection(n),
                  u = [t, e, i],
                  c = st(u);
                a.containsKey(c) && (s = a.get(c));
                var h = this.getKey();
                if (s && s.key == h) return s;
                var l = this.getTileGridForProjection(o),
                  p = this.getTileGridForProjection(n),
                  f = this.getTileCoordForTileUrlFunction(u, n),
                  g = new he(
                    o,
                    l,
                    n,
                    p,
                    u,
                    f,
                    this.getTilePixelRatio(r),
                    this.getGutter(),
                    function(t, e, i, r) {
                      return this.getTileInternal(t, e, i, r, o);
                    }.bind(this),
                    this.reprojectionErrorThreshold_,
                    this.renderReprojectionEdges_
                  );
                return (
                  (g.key = h),
                  s
                    ? ((g.interimTile = s),
                      g.refreshInterimChain(),
                      a.replace(c, g))
                    : a.set(c, g),
                  g
                );
              }
              return this.getTileInternal(t, e, i, r, o || n);
            }),
            (e.prototype.getTileInternal = function(t, e, i, r, n) {
              var o = null,
                s = ot(t, e, i),
                a = this.getKey();
              if (this.tileCache.containsKey(s)) {
                if ((o = this.tileCache.get(s)).key != a) {
                  var u = o;
                  (o = this.createTile_(t, e, i, r, n, a)),
                    u.getState() == J.IDLE
                      ? (o.interimTile = u.interimTile)
                      : (o.interimTile = u),
                    o.refreshInterimChain(),
                    this.tileCache.replace(s, o);
                }
              } else
                (o = this.createTile_(t, e, i, r, n, a)),
                  this.tileCache.set(s, o);
              return o;
            }),
            (e.prototype.setRenderReprojectionEdges = function(t) {
              if (this.renderReprojectionEdges_ != t) {
                for (var e in ((this.renderReprojectionEdges_ = t),
                this.tileCacheForProjection))
                  this.tileCacheForProjection[e].clear();
                this.changed();
              }
            }),
            (e.prototype.setTileGridForProjection = function(t, e) {
              var i = $t(t);
              if (i) {
                var r = b(i);
                r in this.tileGridForProjection ||
                  (this.tileGridForProjection[r] = e);
              }
            }),
            e
          );
        })(
          (function(t) {
            function e(e) {
              t.call(this, {
                attributions: e.attributions,
                cacheSize: e.cacheSize,
                opaque: e.opaque,
                projection: e.projection,
                state: e.state,
                tileGrid: e.tileGrid,
                tilePixelRatio: e.tilePixelRatio,
                wrapX: e.wrapX,
                transition: e.transition,
                key: e.key,
                attributionsCollapsible: e.attributionsCollapsible
              }),
                (this.generateTileUrlFunction_ = !e.tileUrlFunction),
                (this.tileLoadFunction = e.tileLoadFunction),
                (this.tileUrlFunction = e.tileUrlFunction
                  ? e.tileUrlFunction.bind(this)
                  : fe),
                (this.urls = null),
                e.urls ? this.setUrls(e.urls) : e.url && this.setUrl(e.url),
                e.tileUrlFunction &&
                  this.setTileUrlFunction(e.tileUrlFunction, this.key_),
                (this.tileLoadingKeys_ = {});
            }
            return (
              t && (e.__proto__ = t),
              (e.prototype = Object.create(t && t.prototype)),
              (e.prototype.constructor = e),
              (e.prototype.getTileLoadFunction = function() {
                return this.tileLoadFunction;
              }),
              (e.prototype.getTileUrlFunction = function() {
                return this.tileUrlFunction;
              }),
              (e.prototype.getUrls = function() {
                return this.urls;
              }),
              (e.prototype.handleTileChange = function(t) {
                var e,
                  i = t.target,
                  r = b(i),
                  n = i.getState();
                n == J.LOADING
                  ? ((this.tileLoadingKeys_[r] = !0), (e = Fe))
                  : r in this.tileLoadingKeys_ &&
                    (delete this.tileLoadingKeys_[r],
                    (e =
                      n == J.ERROR
                        ? Me
                        : n == J.LOADED || n == J.ABORT
                        ? Ie
                        : void 0)),
                  null != e && this.dispatchEvent(new je(e, i));
              }),
              (e.prototype.setTileLoadFunction = function(t) {
                this.tileCache.clear(),
                  (this.tileLoadFunction = t),
                  this.changed();
              }),
              (e.prototype.setTileUrlFunction = function(t, e) {
                (this.tileUrlFunction = t),
                  this.tileCache.pruneExceptNewestZ(),
                  void 0 !== e ? this.setKey(e) : this.changed();
              }),
              (e.prototype.setUrl = function(t) {
                var e = (this.urls = (function(t) {
                  var e = [],
                    i = /\{([a-z])-([a-z])\}/.exec(t);
                  if (i) {
                    var r,
                      n = i[1].charCodeAt(0),
                      o = i[2].charCodeAt(0);
                    for (r = n; r <= o; ++r)
                      e.push(t.replace(i[0], String.fromCharCode(r)));
                    return e;
                  }
                  if ((i = i = /\{(\d+)-(\d+)\}/.exec(t))) {
                    for (
                      var s = parseInt(i[2], 10), a = parseInt(i[1], 10);
                      a <= s;
                      a++
                    )
                      e.push(t.replace(i[0], a.toString()));
                    return e;
                  }
                  return e.push(t), e;
                })(t));
                this.setUrls(e);
              }),
              (e.prototype.setUrls = function(t) {
                this.urls = t;
                var e = t.join("\n");
                this.generateTileUrlFunction_
                  ? this.setTileUrlFunction(pe(t, this.tileGrid), e)
                  : this.setKey(e);
              }),
              (e.prototype.useTile = function(t, e, i) {
                var r = ot(t, e, i);
                this.tileCache.containsKey(r) && this.tileCache.get(r);
              }),
              e
            );
          })(Re)
        )
      );
      function Ae() {
        var t = ze(["mapType"]);
        return (
          (Ae = function() {
            return t;
          }),
          t
        );
      }
      function Xe() {
        var t = ze(["apiKey"]);
        return (
          (Xe = function() {
            return t;
          }),
          t
        );
      }
      function ze(t, e) {
        return (
          e || (e = t.slice(0)),
          Object.freeze(
            Object.defineProperties(t, { raw: { value: Object.freeze(e) } })
          )
        );
      }
      function Ue(t, e) {
        for (var i = 0; i < e.length; i++) {
          var r = e[i];
          (r.enumerable = r.enumerable || !1),
            (r.configurable = !0),
            "value" in r && (r.writable = !0),
            Object.defineProperty(t, r.key, r);
        }
      }
      var ke = ["leaflet", "openlayers"],
        Ye = (function() {
          function t() {
            var e =
                arguments.length > 0 && void 0 !== arguments[0]
                  ? arguments[0]
                  : {},
              i = e.apiKey,
              r = void 0 === i ? De(Xe()) : i,
              n = e.mapType,
              o = void 0 === n ? De(Ae()) : n;
            if (
              ((function(t, e) {
                if (!(t instanceof e))
                  throw new TypeError("Cannot call a class as a function");
              })(this, t),
              !ke.includes(o))
            )
              throw new Error(
                ""
                  .concat(o, " not a valid type for mapType. Valid types: (")
                  .concat(ke.join(","), ")")
              );
            (this._apiKey = r),
              (this._mapType = o),
              (this.createLayer = this.createLayer.bind(this)),
              (this.changeLayersMapType = this.changeLayersMapType.bind(this));
          }
          var e, i, r;
          return (
            (e = t),
            (i = [
              {
                key: "createLayer",
                value: function() {
                  var t =
                      arguments.length > 0 && void 0 !== arguments[0]
                        ? arguments[0]
                        : o.temel,
                    e = [];
                  return (
                    this._mapType === n.OPENLAYERS
                      ? t === o.UYDU
                        ? (e.push(
                            new V({
                              source: new Ge({
                                url: "https://atlas.harita.gov.tr/webservis/ortofoto/{z}/{x}/{y}.jpg?apikey=".concat(
                                  this._apiKey
                                ),
                                attributions:
                                  '&copy; <a href="https://www.harita.gov.tr/">HGM Atlas</a>',
                                attributionsCollapsible: !1
                              })
                            })
                          ),
                          e.push(
                            new V({
                              source: new Ge({
                                url: "https://atlas.harita.gov.tr/webservis/harita/hgm_ortofoto/{z}/{x}/{y}.png?apikey=".concat(
                                  this._apiKey
                                ),
                                attributions:
                                  '&copy; <a href="https://www.harita.gov.tr/">HGM Atlas</a>',
                                attributionsCollapsible: !1
                              })
                            })
                          ))
                        : e.push(
                            new V({
                              source: new Ge({
                                url: "https://atlas.harita.gov.tr/webservis/harita/"
                                  .concat(Ke(t), "/{z}/{x}/{y}.png?apikey=")
                                  .concat(this._apiKey),
                                attributions:
                                  '&copy; <a href="https://www.harita.gov.tr/">HGM Atlas</a>',
                                attributionsCollapsible: !1
                              })
                            })
                          )
                      : this._mapType === n.LEAFLET &&
                        (t === o.UYDU
                          ? (e.push(
                              L.tileLayer(
                                "https://atlas.harita.gov.tr/webservis/ortofoto/{z}/{x}/{y}.jpg?apikey=".concat(
                                  this._apiKey
                                ),
                                {
                                  subdomains: ["1", "2", "3", "4"],
                                  attribution:
                                    '&copy; <a href="https://www.harita.gov.tr/">HGM Atlas</a>'
                                }
                              )
                            ),
                            e.push(
                              L.tileLayer(
                                "https://atlas.harita.gov.tr/webservis/harita/hgm_ortofoto/{z}/{x}/{y}.png?apikey=".concat(
                                  this._apiKey
                                ),
                                {
                                  subdomains: ["1", "2", "3", "4"],
                                  attribution:
                                    '&copy; <a href="https://www.harita.gov.tr/">HGM Atlas</a>'
                                }
                              )
                            ))
                          : e.push(
                              L.tileLayer(
                                "https://atlas.harita.gov.tr/webservis/harita/"
                                  .concat(Ke(t), "/{z}/{x}/{y}.png?apikey=")
                                  .concat(this._apiKey),
                                {
                                  subdomains: ["1", "2", "3", "4"],
                                  attribution:
                                    '&copy; <a href="https://www.harita.gov.tr/">HGM Atlas</a>'
                                }
                              )
                            )),
                    (e[0].copyright = function() {
                      return "Copyright (C) Piri Reis Bilisim Ltd Sti - All Rights Reserved - http://www.pirireis.com.tr";
                    }),
                    (this._layers = [].concat(e)),
                    e
                  );
                }
              },
              {
                key: "changeLayersMapType",
                value: function() {
                  var t =
                    arguments.length > 0 && void 0 !== arguments[0]
                      ? arguments[0]
                      : o.temel;
                  this._mapType === n.LEAFLET
                    ? t === o.UYDU
                      ? (this._layers[0].setUrl(
                          "https://atlas.harita.gov.tr/webservis/harita/hgm_ortofoto/{z}/{x}/{y}.png?apikey=".concat(
                            this._apiKey
                          )
                        ),
                        void 0 !== this._layers[1] || null !== this._layers[1]
                          ? this._layers[1].setUrl(
                              "https://atlas.harita.gov.tr/webservis/ortofoto/{z}/{x}/{y}.jpg?apikey=".concat(
                                this._apiKey
                              )
                            )
                          : (this._layers[1] = L.tileLayer(
                              "https://atlas.harita.gov.tr/webservis/ortofoto/{z}/{x}/{y}.jpg?apikey=".concat(
                                this._apiKey
                              ),
                              {
                                subdomains: ["1", "2", "3", "4"],
                                attribution:
                                  '&copy; <a href="https://www.harita.gov.tr/">HGM Atlas</a>'
                              }
                            )))
                      : (this._layers[0].setUrl(
                          "https://atlas.harita.gov.tr/webservis/harita/"
                            .concat(Ke(t), "/{z}/{x}/{y}.png?apikey=")
                            .concat(this._apiKey)
                        ),
                        void 0 !== this._layers[1] &&
                          null !== this._layers[1] &&
                          this._layers[1].setUrl(""))
                    : this._mapType === n.OPENLAYERS &&
                      (t === o.UYDU
                        ? (this._layers[0].setSource(
                            new Ge({
                              url: "https://atlas.harita.gov.tr/webservis/ortofoto/{z}/{x}/{y}.jpg?apikey=".concat(
                                this._apiKey
                              ),
                              attributions:
                                '&copy; <a href="https://www.harita.gov.tr/">HGM Atlas</a>',
                              attributionsCollapsible: !1
                            })
                          ),
                          void 0 !== this._layers[1] &&
                            null !== this._layers[1] &&
                            this._layers[1].setSource(
                              new Ge({
                                url: "https://atlas.harita.gov.tr/webservis/harita/hgm_ortofoto/{z}/{x}/{y}.png?apikey=".concat(
                                  this._apiKey
                                ),
                                attributions:
                                  '&copy; <a href="https://www.harita.gov.tr/">HGM Atlas</a>',
                                attributionsCollapsible: !1
                              })
                            ))
                        : (this._layers[0].setUrl(
                            "https://atlas.harita.gov.tr/webservis/harita/"
                              .concat(Ke(t), "/{z}/{x}/{y}.png?apikey=")
                              .concat(this._apiKey)
                          ),
                          (void 0 === this._layers[1] &&
                            null === this._layers[1]) ||
                            this._layers[1].setSource(
                              new Ge({
                                url: "",
                                attributions:
                                  '&copy; <a href="https://www.harita.gov.tr/">HGM Atlas</a>',
                                attributionsCollapsible: !1
                              })
                            )));
                }
              }
            ]) && Ue(e.prototype, i),
            r && Ue(e, r),
            t
          );
        })(),
        Ke = function(t) {
          var e = "hgm_harita";
          switch (t) {
            case o.TEMEL:
              e = "hgm_harita";
              break;
            case o.GECE:
              e = "hgm_gece";
              break;
            case o.UYDU:
              e = "hgm_ortofoto";
              break;
            case o.SIYASI:
              e = "hgm_siyasi";
              break;
            case o.YUKSEKLIK:
              e = "hgm_yukseklik";
              break;
            default:
              e = "hgm_harita";
          }
          return e;
        },
        De = function(t) {
          throw new Error("Missing parameter: ".concat(t));
        };
      i.d(e, "version", function() {
        return r.a;
      }),
        i.d(e, "Client", function() {
          return Ye;
        }),
        i.d(e, "MAPTYPES", function() {
          return n;
        }),
        i.d(e, "BASEMAPS", function() {
          return o;
        });
    }
  ]);
});
