const help = [
  {
    question:
      "Güzergah API ile iki nokta arası güzergah tarifleri nasıl oluşturulmaktadır? ",
    answer:
      "HGM ATLAS Güzergah API, rota oluşturmak istediğiniz noktalar arasında en optimize güzergahı hazırlarken arka planda birtakım matematiksel işlemler kullanmaktadır; örneğin “zaman matrisi” bunlardan bir tanesidir. Bu matematiksel hesaplamalar sonucunda kullanıcıya mesafeye bağlı en kısa yol, zamana bağlı en kısa yol veya daha farklı kriterlere göre en kısa yol analizleri sağlanabilmektedir."
  },
  {
    question:
      "Oluşturduğum rotaya üzerinde bulunmasını istediğim ilave durak noktaları ekleyebilir miyim? ",
    answer:
      "Evet, HGM ATLAS’ın sizin için oluşturduğu rota üzerinde istediğiniz noktaya tıklayıp rotanızın geçmesini istediğiniz ilave durakları ekleyebilirsiniz."
  },
  {
    question:
      "HGM ATLAS Güzergah API seçtiğim noktalar arasında birden fazla rota oluşturdu ne yapmalıyım? ",
    answer:
      "HGM ATLAS, güzergah oluşturmak istediğiniz noktalar arasında sizin için en optimize rotayı oluşturur. Bu rotalar arasında istediğiniz güzergahı seçip yol tarifinizi alabilirsiniz. "
  }
];

export default help;
