import {
  REGISTER_USER,
  LOGIN,
  GET_USER_INFO,
  UPDATE_USER,
  ADD_PROJECT,
  LOGOUT
} from "../actions";

const defaultState = {
  user: {
    username: "Kullanıcı Adı",
    password: "Şifre",
    firstname: "İsim",
    projects: []
  }
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case REGISTER_USER:
    case GET_USER_INFO:
    case UPDATE_USER:
    case LOGIN:
    case LOGOUT:
      return { ...state, ...action.payload };
    case ADD_PROJECT: {
      let newState = { ...state };
      newState.user.projects = [...action.payload.projects];
      return newState;
    }
    default:
      return state;
  }
}
