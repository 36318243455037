import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputLabel from "@material-ui/core/InputLabel";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import NewProject from "../../components/Modal/NewProject.jsx";

const styles = theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  titleSection: {
    display: "flex",
    justifyContent: "space-between"
  },
  heading: {
    fontWeight: "600"
  }
});

class Projects extends React.Component {
  render() {
    const { classes, projects, apiKey } = this.props;

    return (
      <div>
        <div className={classes.titleSection}>
          <h4> Projeler </h4>
          <NewProject />
        </div>
        {projects &&
          projects.map((project, index) => (
            <ExpansionPanel key={index}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.heading}>{project.project}</div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%"
                  }}
                >
                  <FormControl className={classes.form}>
                    <InputLabel
                      shrink
                      htmlFor="domain"
                      className={classes.formLabel}
                    >
                      Domain
                    </InputLabel>
                    <InputBase
                      id="domain"
                      placeholder="URL"
                      value={project.domain}
                      readOnly
                      classes={{
                        root: classes.root,
                        input: classes.input
                      }}
                      className={classNames({
                        [classes.fullWidth]: true,
                        [classes.margin]: false
                      })}
                    />
                  </FormControl>
                  <FormControl
                    className={classes.form}
                    style={{ marginTop: "12px" }}
                  >
                    <InputLabel
                      shrink
                      htmlFor="api-key"
                      className={classes.formLabel}
                    >
                      API Anahtarı
                    </InputLabel>
                    <InputBase
                      id="api-key"
                      placeholder="API Anahtarı"
                      value={apiKey}
                      readOnly
                      classes={{
                        root: classes.root,
                        input: classes.input
                      }}
                      className={classNames({
                        [classes.fullWidth]: true,
                        [classes.margin]: false
                      })}
                    />
                    {/* <CopyToClipboard text={apiKey}>
                  <Button color="danger" fullWidth className={classes.button}>
                    {this.state.copied ? "Kopyalandı" : "Kopyala"}
                  </Button>
                </CopyToClipboard> */}
                  </FormControl>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
      </div>
    );
  }
}

function mapStateToProps({ key, user }) {
  return {
    apiKey: key.apiKey,
    projects: user.user.projects
  };
}

Projects.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {}
  )(Projects)
);
