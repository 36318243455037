import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "../../components/Grid/GridContainer";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import HeaderLinks from "../../components/Header/HeaderLinks";

import imagesStyles from "assets/jss/material-kit-react/imagesStyles.jsx";
import { cardTitle } from "assets/jss/material-kit-react.jsx";

const style = {
  ...imagesStyles,
  cardTitle,
  main: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F3F3F4",
    overflowX: "hidden",
    minHeight: "100vh",
    paddingTop: "40px"
  },
  cardHeader: {
    height: "180px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F3F3F4",
    borderRadius: "1px 1px 0 0",
    boxSizing: "border-box",
    boxShadow: "0 2px 43px 0 rgba(0, 0, 0, 0.13)",
    minHeight: "18rem"
  },
  textAlign: {
    textAlign: "center"
  },
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "1px solid #F3F3F4",
    marginTop: "8vh"
  },
  card: {
    borderRadius: "1px",
    boxShadow: "0 2px 43px 0 rgba(0, 0, 0, 0.13)",
    maxWidth: "70rem",
    minHeight: "70vh"
  },
  cardMainTitle: {}
};

const dashboardRoutes = [];

class Pricing extends React.Component {
  render() {
    const { classes, history, user, ...rest } = this.props;
    return (
      <main className={classes.main}>
        <Header
          history={history}
          color="dark"
          brand="Ana Sayfa"
          routes={dashboardRoutes}
          rightLinks={<HeaderLinks />}
          fixed
          /* changeColorOnScroll={{
            height: 50,
            color: "dark"
          }} */
          {...rest}
        />
        <section className={classes.section}>
          <h2 style={{ marginBottom: "0px" }}>Ücretlendirme</h2>
        </section>
        <section>
          <GridContainer justify="center">
            <Card
              className={classes.card}
              style={{
                width: "20rem",
                marginRight: "20px"
              }}
            >
              <CardBody
                className={classes.textAlign}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <i
                  className="fas fa-map-marked-alt"
                  style={{
                    height: "50px",
                    width: "50px"
                  }}
                />
                <h3>Bireysel Kullanıcı</h3>
                <p style={{ textAlign: "center" }}>
                  HGM ATLAS Uygulama Geliştirme Platformu’nda Bireysel Kullanıcı
                  hesabı oluşturun ve API servislerini ücretsiz kullanmaya
                  başlayın.
                </p>
                <br />
                <h5 className={classes.cardMainTitle}>
                  <b>Servisler</b>
                </h5>
                <br />
                <p style={{ fontWeight: "500" }}>Aylık</p>
                <p style={{ fontWeight: "300" }}>
                  <b style={{ fontWeight: "500" }}>50 000</b> Harita
                  Görüntülenmesi
                </p>
                <p style={{ fontWeight: "300" }}>
                  <b style={{ fontWeight: "500" }}>10 000</b> Arama Sorgusu{" "}
                </p>
                <p style={{ fontWeight: "300" }}>
                  <b style={{ fontWeight: "500" }}>20 000</b> Coğrafi Kodlama
                  Sorgusu
                </p>
                <p style={{ fontWeight: "300" }}>
                  <b style={{ fontWeight: "500" }}>5 000</b> Güzergah İsteği{" "}
                </p>
                <br />
                <Tooltip title="Ticarileştirme süreci devam etmektedir.">
                  <Button
                    color="google"
                    /* onClick={() =>
                    this.props.history.push(
                      user.isLoggedIn ? "/kullanici" : "/atlasapidocgiris"
                    )
                  } */
                  >
                    ÜCRETSİZ BAŞLAYIN
                  </Button>
                </Tooltip>
              </CardBody>
            </Card>
            <Card
              className={classes.card}
              style={{
                width: "20rem",
                backgroundColor: "#383C45",
                color: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {/* <div
                className={classes.cardHeader}
                style={{ backgroundColor: "#383C45", color: "#FFFFFF" }}
              >
                  <i
                  className="fas fa-user-tie"
                  style={{
                    height: "50px",
                    width: "50px",
                    marginTop: "auto"
                  }}
                /> 
               <h3>Kurumsal Kullanıcı</h3>
                <p style={{ textAlign: "center", padding: "0 4px" }}>
                  Projenizin ihtiyacı doğrultusunda en uygun planı
                  oluşturmak için Harita Genel Müdürlüğü ile iletişime
                  geçebilirsiniz.
                </p>
                <Button
                  color="google"
                  onClick={() => this.props.history.push("/bize-ulasin")}
                >
                  BİZE ULAŞIN
                </Button> 
              </div> */}
              <CardBody
                className={classes.textAlign}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <i
                  className="fas fa-user-tie"
                  style={{
                    height: "50px",
                    width: "50px"
                  }}
                />
                <h3>Kurumsal Kullanıcı</h3>
                <br />
                <p style={{ textAlign: "center", padding: "0 4px" }}>
                  Projenizin ihtiyacı doğrultusunda en uygun planı oluşturmak
                  için Harita Genel Müdürlüğü ile iletişime geçebilirsiniz.
                </p>
                <br />
                <Button
                  color="google"
                  onClick={() => this.props.history.push("/bize-ulasin")}
                >
                  BİZE ULAŞIN
                </Button>
              </CardBody>
            </Card>
          </GridContainer>
        </section>
        <Footer />
      </main>
    );
  }
}

function mapStateToProps({ user }) {
  return { user };
}

export default withStyles(style)(connect(mapStateToProps)(withRouter(Pricing)));
