import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Chip from "@material-ui/core/Chip";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody.jsx";

import documentationStyle from "../assets/jss/documentationStyle.jsx";
import map from "../assets/img/map2.png";
import staticMap from "../assets/img/map-image2-cropped.png";
import geocoder from "../assets/img/geocoder2.png";
import search from "../assets/img/search2.png";
import routing from "../assets/img/routing2.png";
import wfs from "../assets/img/wfs.png";
import wms from "../assets/img/wms.png";

import cardsList from "./cards";
import "../assets/css/documentation.css";

const imgs = { map, staticMap, geocoder, search, routing, wms, wfs };

const mobileStyle = {
  marginRight: "24px",
  width: "100%",
  height: "100%"
};
class Documentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <GridContainer justify="center" alignContent="stretch">
        <GridItem xs={12} sm={12} md={12}>
          {cardsList.map((child, index) => {
            return (
              <div key={index}>
                <Card plain key={child.title}>
                  <CardBody>
                    {isWidthUp("md", this.props.width) ? (
                      index % 2 === 0 ? (
                        <GridContainer className="card-body-container">
                          <GridItem xs={12} sm={12} md={6}>
                            <img
                              src={imgs[child.img]}
                              alt="..."
                              style={
                                isWidthUp("lg", this.props.width)
                                  ? { marginLeft: "-75px" }
                                  : {}
                              }
                              width={
                                isWidthUp("lg", this.props.width)
                                  ? "600px"
                                  : "100%"
                              }
                              height={
                                isWidthUp("lg", this.props.width)
                                  ? "450px"
                                  : "100%"
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <div>
                              <h4
                                className={classes.cardTitle}
                                style={{ fontWeight: "700" }}
                              >
                                {child.title}
                              </h4>
                              <p>{child.content}</p>
                              <div>
                                {child.cat.map(category => (
                                  <Chip
                                    key={category}
                                    label={category}
                                    className={classes.chip}
                                    onClick={() =>
                                      this.props.history.push(
                                        `/${child.title
                                          .split(" ")
                                          .join("-")
                                          .toLowerCase()}`
                                      )
                                    }
                                  />
                                ))}
                              </div>
                            </div>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <GridContainer
                          alignContent="stretch"
                          className="card-body-container"
                        >
                          <GridItem xs={12} sm={12} md={6}>
                            <div>
                              <h4
                                className={classes.cardTitle}
                                style={{ fontWeight: "700" }}
                              >
                                {child.title}
                              </h4>
                              <p>{child.content}</p>
                              <div>
                                {child.cat.map(category => (
                                  <Chip
                                    key={category}
                                    label={category}
                                    className={classes.chip}
                                    onClick={() =>
                                      this.props.history.push(
                                        `/${child.title
                                          .split(" ")
                                          .join("-")
                                          .toLowerCase()}`
                                      )
                                    }
                                  />
                                ))}
                              </div>
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <img
                              src={imgs[child.img]}
                              alt="..."
                              style={
                                isWidthUp("lg", this.props.width)
                                  ? { marginLeft: "-75px" }
                                  : {}
                              }
                              width={
                                isWidthUp("lg", this.props.width)
                                  ? "600px"
                                  : "100%"
                              }
                              height={
                                isWidthUp("lg", this.props.width)
                                  ? "450px"
                                  : "100%"
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    ) : (
                      <GridContainer className="card-body-container">
                        <GridItem xs={12} sm={12} md={6}>
                          <img
                            src={imgs[child.img]}
                            alt="..."
                            style={
                              child.img === "map" || child.img === "wfs"
                                ? mobileStyle
                                : {
                                    width: "100%",
                                    height: "100%"
                                  }
                            }
                            width="500px"
                            height="300px"
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <div>
                            <h4
                              className={classes.cardTitle}
                              style={{ fontWeight: "700" }}
                            >
                              {child.title}
                            </h4>
                            <p>{child.content}</p>
                            <div>
                              {child.cat.map(category => (
                                <Chip
                                  key={category}
                                  label={category}
                                  className={classes.chip}
                                  onClick={() =>
                                    this.props.history.push(
                                      `/${child.title
                                        .split(" ")
                                        .join("-")
                                        .toLowerCase()}`
                                    )
                                  }
                                />
                              ))}
                            </div>
                          </div>
                        </GridItem>
                      </GridContainer>
                    )}
                  </CardBody>
                </Card>
              </div>
            );
          })}
        </GridItem>
      </GridContainer>
    );
  }
}

Documentation.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  width: PropTypes.string
};

export default withWidth()(withStyles(documentationStyle)(Documentation));
