import React from "react";
import Prism from "prismjs";

class RoutingOverview extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    return (
      <span>
        <h3>
          <small>Güzergah API nedir?</small>
        </h3>
        <p>
          Günümüzde rota planlama ve navigasyon, konum tabanlı uygulamaların en
          sık kullanılan özelliklerindendir. Güzergah API ile, HGM ATLAS harita
          altlıkları üzerinde kendi hesaplama kriterlerinize uyan en optimize ve
          en güncel harita verilerine dayanan rotaları oluşturabilirsiniz.
        </p>
        <h3>
          <small>Doğru:</small>
        </h3>
        <p>
          HGM en kısa yol algoritması, zengin harita öznitelik bilgilerini dönüş
          yasaklarıyla birlikte dikkate almaktadır.
        </p>
        <h3>
          <small>Alternatif Güzergah:​</small>
        </h3>
        <p>Alternatif güzergahlar da belirlenmektedir.</p>
        <br />
        <h3>
          <small>Örnek Kullanım</small>
        </h3>
        <p>
          Kullanıcı aşağıdaki adrese "GET" sorgusu atarak koordinatlar
          arasındaki en hızlı rotaya ulaşabilir.
        </p>
        <pre>
          <code className="language-">
            {
              "https://atlas.harita.gov.tr/webservis/rota/29.6276,39.9581;36.4831,38.0459.json?geometries=geojson&alternatives=true&steps=true&overview=full"
            }
          </code>
        </pre>
        {/* <p>
          Kullanıcı aşağıdaki adrese "GET" sorgusu atarak koordinatlar
          arasındaki mesafe matrisine(her bir nokta arasındaki seyahat
          sürelerine ve mesafelere) ulaşabilir.
        </p>
        <pre>
          <code className="language-">
            {
              "http://servis.pirireis.com.tr/osrm/table/v1/driving/33.388860,42.517037;33.397634,41.529407;34.428555,32.523219"
            }
          </code>
        </pre> */}
        <br />
        <br />
        <br />
        <br />
      </span>
    );
  }
}

export default RoutingOverview;
