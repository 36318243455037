import React from "react";
import "../../assets/css/layerTypeButton.css";

class LayerTypeButton extends React.Component {
  render() {
    const { onChange } = this.props;

    return (
      <div className="wrapper">
        <input className="hidden-trigger" id="toogle" type="checkbox" />
        <label className="circle" htmlFor="toogle">
          <img
            src="https://ssl.gstatic.com/bt/C3341AA7A1A076756462EE2E5CD71C11/2x/btw_ic_speeddial_white_24dp_2x.png"
            alt=""
          />
        </label>

        <div className="subs">
          <button className="sub-circle base" onClick={() => onChange("TEMEL")}>
            <input
              className="hidden-sub-trigger"
              id="sub1"
              type="radio"
              name="sub-circle"
              value="TEMEL"
              onChange={() => onChange("TEMEL")}
            />
          </button>

          <button className="sub-circle dark" onClick={() => onChange("GECE")}>
            <input
              className="hidden-sub-trigger"
              id="sub2"
              type="radio"
              name="sub-circle"
              value="GECE"
              onChange={() => onChange("GECE")}
            />
          </button>

          <button className="sub-circle topo" onClick={() => onChange("TOPO")}>
            <input
              className="hidden-sub-trigger"
              id="sub3"
              type="radio"
              name="sub-circle"
              value="TOPO"
              onChange={() => onChange("TOPO")}
            />
          </button>

          <button
            className="sub-circle siyasi"
            onClick={() => onChange("SIYASI")}
          >
            <input
              className="hidden-sub-trigger"
              id="sub4"
              type="radio"
              name="sub-circle"
              value="SIYASI"
            />
          </button>

          <button
            className="sub-circle satellite"
            onClick={() => onChange("UYDU")}
          >
            <input
              className="hidden-sub-trigger"
              id="sub5"
              type="radio"
              name="sub-circle"
              value="UYDU"
            />
          </button>
        </div>
      </div>
    );
  }
}

export default LayerTypeButton;
