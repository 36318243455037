const help = [
  {
    question:
      "HGM ATLAS üzerinde yapacağım bir arama ile hangi verilere ulaşabilirim? ",
    answer:
      "HGM ATLAS Arama API kullanarak sitenize veya uygulamanıza ekleyeceğiniz bir arama çubuğu ile kullanıcılarınız HGM ATLAS’ın içerdiği milyonlarca farklı ilgi noktasına (POI) ulaşabilir ve bu noktaları farklı amaçlar doğrultusunda kullanabilirler."
  },
  {
    question:
      "HGM ATLAS Arama API kullanarak yapılan bir arama sorgusunun yanıtını hangi formatta alabilirim? ",
    answer:
      "Kullanıcıların gerçekleştirdikleri arama sorgularına verilen yanıt standart bir GeoJSON nesnesidir. "
  },
  {
    question: "Aradığım bir yeri HGM ATLAS üzerinde bulamadım, ne yapmalıyım? ",
    answer:
      "HGM ATLAS kullanıcıları için son derece güncel harita verisi sağlasa da, bazı münferit durumlarda aramak istediğiniz yeri harita ekranı üzerinde bulamamış olabilirsiniz. Böyle durumlarda, harita ekranı üzerinde “Geri Bildirim” yaparak bu noktanın HGM ATLAS veritabanına eklenmesini sağlayabilirsiniz. "
  }
];

export default help;
