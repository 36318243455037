import React from "react";
import Prism from "prismjs";

const geocode = `{
	"lat"	: "enlem bilgisi(wgs84)"
	"lng	: "boylam bilgisi(wgs84)"
}
`;

const response = `{
  "type": "FeatureCollection",
  "features": [
      {
          "type": "Feature",
          "properties": {
              "confidence": 1,
              "name": "Erzurum Ağzer Köyü Derneği",
              "locality": "Çiçekli",
              "county": "Keçiören",
              "region": "Ankara",
              "type": "poi"
          },
          "geometry": {
              "type": "Point",
              "coordinates": [
                  32.850261,
                  39.977922
              ]
          }
      }
  ]
}`;

class Address extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    return (
      <span>
        <h3>
          <small>Coğrafi Koordinatlardan Adres Alma</small>
        </h3>
        <p>
          Verilen koordinat için adres bilgisini almak için kullanıcı aşağıdaki
          yapıda request içeriğini oluşturur.
        </p>
        <br />
        <pre>
          <code className="language-json">{geocode}</code>
        </pre>
        <p>Oluşturulan request ile aşağıdaki adrese GET sorgusu yapılır.</p>
        <pre>
          <code className="language-">
            {
              "https://atlas.harita.gov.tr/webservis/arama/reverse?lat={enlem}&lng={boylam}&apikey=[apiKey]"
            }
          </code>
        </pre>
        <p>API'dan dönen response örneği aşağıdadır.</p>
        <pre>
          <code className="language-javascript">{response}</code>
        </pre>
        <br />
        <h4>
          <small>Response özellikleri</small>
        </h4>
        <p>
          <b>type</b>, her zaman "FeatureCollection" değerini alır.
        </p>
        <p>
          <b>features</b>, yalnızca tek bir nesne olabilir, birden fazla sonuç
          dönmez. Bu nesnenin içindeki <b>type</b> özelliği her zaman{" "}
          <b>Feature</b> olur. <b>properties</b> JSON nesnesi içinde{" "}
          <b>confidence</b>, <b>name</b>, <b>locality</b>, <b>county</b>,{" "}
          <b>region</b> ve <b>type</b> özelliklerini bulundurur.
        </p>
        <br />
        <p>
          <b>confidence</b> 0 ile 1 arasında bir sayı değeridir ve aranan
          koordinatlar ile dönen adresin yakınlık oranını belirtir.{" "}
          <b>locality</b> semt, <b>county</b> ilçe ve <b>region</b> il
          bilgilerini içerir.
        </p>
        <br />
        <p>
          <b>geometry</b> GeoJSON standardına uygun geometri objesidir.{" "}
          <b>type</b> özelliği her zaman "Point" olur. <b>coordinates</b>{" "}
          özelliği lokasyonun enlem ve boylam değerlerini içerir.
        </p>
      </span>
    );
  }
}

export default Address;
