import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import L from "leaflet";
import * as atlas from "../../assets/atlasjs/atlas";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import withStyles from "@material-ui/core/styles/withStyles";
import LayerTypeButton from "./LayerTypeButton.jsx";

import parallaxWithMapStyle from "assets/jss/material-kit-react/components/parallaxWithMapStyle.js";

const LOCATIONS = [
  { lat: 37.22395, lon: 38.92091, zoom: 16, name: "Göbeklitepe" }, //Gobeklitepe
  { lat: 36.549609, lon: 29.115541, zoom: 17, name: "Ölüdeniz Tabiat Parkı" }, // Oludeniz */
  {
    lat: 40.8651,
    lon: 28.99363,
    zoom: 16,
    name: "Demokrasi ve Özgürlükler Adası"
  },
  { lat: 41.01279, lon: 28.984473, zoom: 16, name: "Topkapı Sarayı Müzesi" }, //Topkapı
  {
    lat: 40.04979,
    lon: 26.21848,
    zoom: 17,
    name: "Çanakkale Şehitler Abidesi"
  }, //Şehitler Abidesi
  { lat: 40.61956, lon: 40.29909, zoom: 13, name: "Trabzon Uzungöl" }, //Uzungöl
  { lat: 40.95309, lon: 41.10087, zoom: 15, name: "Rize Ayder Yaylası" }, // Ayder
  { lat: 37.5315, lon: 29.6563, zoom: 14, name: "Burdur Salda Gölü" }, // Salda
  {
    lat: 36.497241,
    lon: 29.13027,
    zoom: 17,
    name: "Fethiye Kelebekler Vadisi"
  }, // Kelebekler Vadisi
  { lat: 38.5321, lon: 35.4461, zoom: 15, name: "Erciyes Dağı" } // Erciyes */
];

class Parallax extends React.Component {
  constructor(props) {
    super(props);
    var windowScrollTop = window.pageYOffset / 3;
    this.state = {
      transform: "translate3d(0," + windowScrollTop + "px,0)",
      mapType: atlas.BASEMAPS.UYDU
    };
    this.resetTransform = this.resetTransform.bind(this);
  }

  componentDidMount() {
    var windowScrollTop = window.pageYOffset / 3;
    this.setState({
      transform: "translate3d(0," + windowScrollTop + "px,0)"
    });
    window.addEventListener("scroll", this.resetTransform);

    this._location = LOCATIONS[Math.floor(Math.random() * LOCATIONS.length)];

    const { lat, lon, zoom, name } = this._location;

    let client = new atlas.Client({
      apiKey: "AlkVjf0YFkkDuvu58l7Ndc1oiHk71IbF",
      mapType: atlas.MAPTYPES.LEAFLET
    });
    let layers = client.createLayer(atlas.BASEMAPS.UYDU);

    this._map = L.map("map", {
      layers: layers,
      zoomControl: false,
      scrollWheelZoom: false
    }).setView([lat, lon], zoom);

    this._map.dragging.disable();
    this._map.doubleClickZoom.disable();

    const marker = L.marker([lat, lon]).addTo(this._map);
    marker.bindPopup(`<b>${name}</b>`, { closeButton: false }).openPopup();

    this.setState({ client });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.resetTransform);
  }

  resetTransform() {
    var windowScrollTop = window.pageYOffset / 3;
    this.setState({
      transform: "translate3d(0," + windowScrollTop + "px,0)"
    });
  }

  render() {
    const {
      classes,
      filter,
      className,
      children,
      style,
      small,
      medium
    } = this.props;

    let isMobile = !isWidthUp("sm", this.props.width);

    const parallaxClasses = classNames({
      [classes.parallax]: true,
      [classes.filter]: filter,
      [classes.small]: small,
      [classes.medium]: medium,
      [classes.mobile]: isMobile,
      [className]: className !== undefined
    });
    return (
      <div
        className={parallaxClasses}
        style={{
          ...style,
          backgroundImage: "url(" + require("assets/img/3.PNG") + ")",
          ...this.state
        }}
      >
        <div
          style={{
            position: "absolute",
            top: isMobile ? "49%" : "62%",
            left: "50%",
            WebkitTransform: "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            width: "60px",
            height: "60px",
            zIndex: 4
          }}
        >
          <LayerTypeButton
            onChange={mapType => {
              if (this.state.client) {
                if (mapType === "TEMEL") {
                  this.state.client.changeLayersMapType(atlas.BASEMAPS.TEMEL);
                  this._map.setZoom(12);
                } else if (mapType === "GECE") {
                  this.state.client.changeLayersMapType(atlas.BASEMAPS.GECE);
                  this._map.setZoom(12);
                } else if (mapType === "TOPO") {
                  this.state.client.changeLayersMapType(
                    atlas.BASEMAPS.YUKSEKLIK
                  );
                  this._map.setZoom(12);
                } else if (mapType === "SIYASI") {
                  this.state.client.changeLayersMapType(atlas.BASEMAPS.SIYASI);
                  this._map.setZoom(12);
                } else if (mapType === "UYDU") {
                  this.state.client.changeLayersMapType(atlas.BASEMAPS.UYDU);
                  this._map.setZoom(this._location.zoom);
                }
              }
            }}
          />
        </div>

        <div
          id="map"
          style={{
            width: "100%",
            height: isMobile ? "40%" : "55%",
            zIndex: 2,
            marginTop: isMobile ? "19%" : "69px",
            overflow: "hidden"
          }}
        />

        {children}
      </div>
    );
  }
}

Parallax.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  filter: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.string,
  width: PropTypes.string,
  small: PropTypes.bool,
  medium: PropTypes.bool
};

export default withWidth()(withStyles(parallaxWithMapStyle)(Parallax));
