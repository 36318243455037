import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { getApiKey } from "../../actions";

import styles from "assets/jss/material-kit-react/views/componentsSections/javascriptStyles.jsx";
import { Tooltip } from "@material-ui/core";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classicModal: false,
      error: ""
    };
  }

  handleClickOpen(modal) {
    this.props.getApiKey(
      () => this.setState({ classicModal: true }),
      error => this.setState({ error })
    );
    /* var x = [];
    x[modal] = true;
    this.setState(x); */
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleCopy = () => {
    this.setState({ copied: true });
  };

  render() {
    const { classes, apiKey } = this.props;
    return (
      <div>
        <Tooltip title="Ticarileştirme süreci devam etmektedir.">
          <Button
            color="danger"
            size="lg"
            rel="noopener noreferrer"
            onClick={() => this.handleClickOpen("classicModal")}
            round
          >
            ÜCRETSİZ BAŞLAYIN
          </Button>
        </Tooltip>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.classicModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("classicModal")}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClose("classicModal")}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            <h4 className={classes.modalTitle}>API Anahtarı</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <p className={classes.key}>{apiKey}</p>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <CopyToClipboard text={apiKey} onCopy={() => this.handleCopy()}>
              <Button color="danger" simple>
                {this.state.copied ? "Kopyalandı" : "Kopyala"}
              </Button>
            </CopyToClipboard>
            <Button
              onClick={() => this.handleClose("classicModal")}
              color="danger"
              simple
            >
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps({ key }) {
  return {
    message: key.message,
    apiKey: key.apiKey
  };
}

Modal.propTypes = {
  classes: PropTypes.object.isRequired,
  getApiKey: PropTypes.func,
  apiKey: PropTypes.string
};

export default withStyles(styles)(
  connect(mapStateToProps, { getApiKey })(Modal)
);
