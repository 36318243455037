import { GET_KEY, LOGOUT } from "../actions";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_KEY:
      return { ...state, ...action.payload };
    case LOGOUT:
      return { ...state, apiKey: "" };
    default:
      return state;
  }
}
