import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import L from "leaflet";
import * as atlas from "../../assets/atlasjs/atlas";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Snackbar from "@material-ui/core/Snackbar";
import { sendMail } from "../../actions";
import Header from "../../components/Header/Header.jsx";
import StyledSnackbarContent from "../../components/Snackbar/StyledSnackbarContent";
import Footer from "../../components/Footer/Footer.jsx";
import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import landingPageStyle from "../../assets/jss/material-kit-react/mainPage.jsx";
import "../../assets/css/form.css";

const dashboardRoutes = [];

const inputStyle = {
  border: "2px solid gainsboro"
};

const mainPageStyle = {
  /* height: "79.7vh", */
  display: "flex"
};

class Contact extends React.Component {
  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
    error: {},
    notificationOpen: false,
    notificationMessage: "",
    notificationVariant: "success"
  };

  componentDidMount() {
    const lat = 39.932431,
      lon = 32.88196;

    let client = new atlas.Client({
      apiKey: "AlkVjf0YFkkDuvu58l7Ndc1oiHk71IbF",
      mapType: atlas.MAPTYPES.LEAFLET
    });

    let layers = client.createLayer(atlas.BASEMAPS.UYDU);

    this.map = L.map("map", {
      layers: layers,
      zoomControl: false
      //scrollWheelZoom: false
    }).setView([lat, lon], 12);

    /* this.map.dragging.disable();
    this.map.doubleClickZoom.disable(); */

    const marker = L.marker([lat, lon]).addTo(this.map);
    marker
      .bindPopup(`<b>HARİTA GENEL MÜDÜRLÜĞÜ</b>`, { closeButton: false })
      .openPopup();
  }

  handleChange = (name, errorMsg) => event => {
    let error = Object.assign({}, this.state.error);

    if (!event.target.value) {
      this.setState({
        error: Object.assign(error, { [name]: errorMsg })
      });
    } else {
      this.setState({
        error: Object.assign(error, { [name]: "" })
      });
    }

    this.setState({
      [name]: event.target.value
    });
  };

  handleSend = () => {
    const { name, email, subject, message } = this.state;

    if (name && email && subject && message) {
      this.props.sendMail(
        { name, email, subject, message },
        () => {
          this.setState({
            notificationOpen: true,
            notificationMessage: "Mail başarıyla gönderildi!",
            notificationVariant: "success"
          });
        },
        () => {
          this.setState({
            error: { notSend: "Mail gönderilemedi!" },
            notificationOpen: true,
            notificationMessage: "Mail gönderilemedi!",
            notificationVariant: "error"
          });
        }
      );
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ notificationOpen: false });
  };

  render() {
    const { classes, history, width, ...rest } = this.props;
    const {
      error,
      name,
      email,
      subject,
      message,
      notificationOpen,
      notificationMessage,
      notificationVariant
    } = this.state;

    return (
      <div className={classes.main}>
        <Header
          history={history}
          color="dark"
          routes={dashboardRoutes}
          brand="Ana Sayfa"
          rightLinks={<HeaderLinks />}
          fixed
          {...rest}
        />
        <div className={classNames(classes.main)}>
          <div
            className={classes.section}
            style={isWidthUp("md", width) ? mainPageStyle : {}}
          >
            <div className={classes.container}>
              <h3>Bize Ulaşın:</h3>
              <form
                className="cf"
                onSubmit={e => {
                  e.preventDefault();
                  this.handleSend();
                }}
              >
                <div className="half left cf">
                  <input
                    type="text"
                    id="input-name"
                    placeholder="İsim"
                    value={name}
                    onChange={this.handleChange("name", "İsim girin!")}
                    style={inputStyle}
                  />
                  <input
                    type="email"
                    id="input-email"
                    placeholder="E-mail"
                    value={email}
                    onChange={this.handleChange("email", "E-mail girin!")}
                    style={inputStyle}
                  />
                  <input
                    type="text"
                    id="input-subject"
                    placeholder="Konu"
                    value={subject}
                    onChange={this.handleChange("subject", "Konu girin!")}
                    style={inputStyle}
                  />
                </div>
                <div className="half right cf">
                  <textarea
                    name="message"
                    type="text"
                    id="input-message"
                    placeholder="Mesajınız"
                    value={message}
                    onChange={this.handleChange("message", "Mesajınızı girin!")}
                    style={inputStyle}
                  />
                </div>
                <input type="submit" value="Gönder" id="input-submit" />
                {error && <div>{error.name}</div>}
              </form>
              <br />
              <p>
                <b>HGM Atlas Uygulama Geliştirme Platformu</b>; PiriReis Bilişim
                Teknolojileri ve Harita Genel Müdürlüğü işbirliği ile{" "}
                <b>
                  milli menfaatlere uygun olan harita ve yazılım altyapıları{" "}
                </b>
                ile Türk mühendis ve yazılımcılar tarafından
                <b> yerli imkânlar ve kaynaklar</b> kullanılarak
                geliştirilmiştir.
              </p>
              <div
                id="map"
                style={{
                  width: "100%",
                  height: /* isMobile ? "40%" : */ "25vh",
                  zIndex: 2,
                  marginTop: /* isMobile ? "19%"  :*/ "69px",
                  overflow: "hidden"
                }}
              />
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={notificationOpen}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <StyledSnackbarContent
            onClose={this.handleClose}
            variant={notificationVariant}
            message={notificationMessage}
          />
        </Snackbar>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return { user };
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  sendMail: PropTypes.func
};

export default withWidth()(
  withStyles(landingPageStyle)(
    connect(
      mapStateToProps,
      { sendMail }
    )(withRouter(Contact))
  )
);
