import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "ol/ol.css";

const styles = {
  map: {
    width: "100%",
    height: "400px"
  }
};

class LeafletExample extends React.Component {
  render() {
    return (
      <div>
        <p>İlgili lokasyonları görmek için arama kutusuna yer adı yazın.</p>
        <iframe
          title="Leaflet-Geocoder"
          width="100%"
          height="500"
          src="//jsfiddle.net/qat0wm46/1/embedded/result,js,html,css/dark/"
          frameBorder="0"
        />
      </div>
    );
  }
}

LeafletExample.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LeafletExample);
