import React from "react";
import Prism from "prismjs";

/* const geocode = `{
	"query"	: "aranan kelime(adres, yer ismi v.b.)"
	"lat"	: "aramanın başlayacağı enlem bilgisi(wgs84)"
	"lng	: "aramanın başlayacağı boylam bilgisi(wgs84)"
}
`; */

const response = `{
  "type": "FeatureCollection",
  "features": [
      {
          "type": "Feature",
          "properties": {
              "confidence": 1,
              "name": "Erzurum",
              "locality": "Kurtuluş",
              "county": "Yakutiye",
              "region": "Erzurum",
              "type": "region"
          },
          "geometry": {
              "type": "Point",
              "coordinates": [
                  41.29002722043032,
                  39.92039145818387
              ]
          }
      },
      {
          "type": "Feature",
          "properties": {
              "confidence": 1,
              "name": "Erzurum Ağzer Köyü Derneği",
              "locality": "Çiçekli",
              "county": "Keçiören",
              "region": "Ankara",
              "type": "poi"
          },
          "geometry": {
              "type": "Point",
              "coordinates": [
                  32.850261,
                  39.977922
              ]
          }
      }
  ]
}`;

const properties = ` "properties": {
  "category": {
      "countyID": 1130,
      "county": "Altındağ",
      "regionID": 7,
      "region": "Ankara",
      "name": "Eczane",
      "key": "eczane",
      "countyCount": 190,
      "regionCount": 2005
  }
}
`;

class Coordinate extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    return (
      <span>
        <h3>
          <small>Adresten Coğrafi Koordinat Alma</small>
        </h3>
        <p>
          Verilen adresin koordinat bilgisini almak için kullanıcı aşağıdaki
          adrese GET sorgusu yapmalıdır.
        </p>
        <pre>
          <code className="language-">
            {
              "https://atlas.harita.gov.tr/webservis/arama/geocode?lat={enlem}&lng={boylam}&query={aranan kelime}&apikey=[apiKey]"
            }
          </code>
        </pre>
        <br />
        <p>API'dan dönen response örneği aşağıdadır.</p>
        <pre>
          <code className="language-javascript">{response}</code>
        </pre>
        <h4>
          <small>Response özellikleri</small>
        </h4>
        <p>
          <b>type</b>, her zaman "FeatureCollection" değerini alır.
        </p>
        <p>
          <b>features</b>, nesnesinin içinde birden fazla sonuç olablir. Bu
          nesnenin içindeki <b>type</b> özelliği her zaman <b>Feature</b> olur.{" "}
          <b>properties</b> JSON nesnesi içinde <b>confidence</b>, <b>name</b>,{" "}
          <b>locality</b>, <b>county</b>, <b>region</b> ve <b>type</b>{" "}
          özelliklerini bulundurur.
        </p>
        <br />
        <p>
          <b>confidence</b> 0 ile 1 arasında bir sayı değeridir ve aranan adres
          ile dönen koordinatların yakınlık oranını belirtir. <b>locality</b>{" "}
          semt, <b>county</b> ilçe ve <b>region</b> il bilgilerini içerir.
        </p>
        <br />
        <p>
          <b>geometry</b> GeoJSON standardına uygun geometri objesidir.{" "}
          <b>type</b> özelliği her zaman "Point" olur. <b>coordinates</b>{" "}
          özelliği lokasyonun enlem ve boylam değerlerini içerir.
        </p>
        <br />
        <p>
          Eğer <b>query</b> parametresinde aranan kelimeler kategori tipleri ile
          eşleşirse servis response'a <b>properties</b> objesi ekler.
        </p>
        <pre>
          <code className="language-javascript">{properties}</code>
        </pre>
        <p>
          <b>category</b> objesi kullanıcının aramada kullandığı kelime ile
          eşleşen kategorileri, verilen koordinatlara yakınlığına göre
          filtreleyip sonuçların sayısını (il(regioncount) ve ilçe(countyCount)
          için ayrı olarak) döndürür.
        </p>
      </span>
    );
  }
}

export default Coordinate;
