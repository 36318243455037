const documentationStyle = {
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "1000px",
    color: "#999",
    textAlign: "center !important"
  },

  title: {
    color: "#383c45",
    fontSize: "16px",
    textTransform: "uppercase",
    fontWeight: "700"
  },

  chip: {
    backgroundColor: "#141625",
    color: "white",
    marginRight: "2px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "red"
    }
  }
};

export default documentationStyle;
