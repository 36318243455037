const help = [
  {
    question:
      "HGM ATLAS haritalarını internet sitemde veya uygulamamda kullanmaya nasıl başlarım?",
    answer:
      "HGM ATLAS haritalarını Giriş Ekranı’ndan alacağınız size özel “Uygulama Geliştirme Arayüzü Anahtarı” ile ücretsiz ve son derece kolay bir şekilde oluşturup sitenize veya uygulamanıza ekleyebilirsiniz."
  },
  {
    question:
      "İnternet siteme veya uygulamama ekleyeceğim “Haritalar” neler içermektedir?",
    answer:
      "HGM ATLAS Haritalar API ile internet sitenizde veya uygulamanızda Türkiye ölçeğinde güncel ve doğruluğu son derece yüksek harita verilerini paylaşabilirsiniz. HGM ATLAS’ın sahip olduğu beş farklı harita katmanını (temel, hava fotoğrafı, siyasi, gece ve topoğrafik) web sayfanıza ya da uygulamanıza ekleyerek kullanıcılarınız için etkileşimli harita deneyimleri oluşturabilirsiniz."
  },
  {
    question:
      "Farklı HGM ATLAS harita altlık katmanlarıyla çalışabilir miyim? ",
    answer: "Evet, bu mümkündür. "
  },
  {
    question: "Hangi harita kütüphaneleriyle çalışabilirim?",
    answer:
      " HGM ATLAS Haritalar API, açık kaynak kodlu JavaScript harita kütüphaneleri olan “Leaflet” ve “OpenLayers” ile uyumludur. Bu kütüphanelerin sahip olduğu tüm yetenekleri HGM ATLAS harita görüntüleri üzerinde sergileyebilirsiniz."
  }
];

export default help;
