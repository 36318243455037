import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemText from "@material-ui/core/ListItemText";
import { leaflet } from "./exampleCodes";
import { openLayers } from "./exampleCodes";
import Prism from "prismjs";

let drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    position: "relative"
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    maxWidth: "666px"
  },
  listItemText: {
    fontSize: "0.75em"
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  }
});

class APIReference extends React.Component {
  state = {
    selectedIndex: 1,
    open: true
  };

  componentDidMount() {
    Prism.highlightAll();
  }

  handleListItemClick = (event, index) => {
    this.setState(state => ({ open: !state.open, selectedIndex: index }));
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { classes } = this.props;
    const { selectedIndex } = this.state;

    return (
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="left"
        >
          <List>
            <ListItem
              button
              key={"Client"}
              selected={true}
              onClick={event => this.handleListItemClick(event, 1)}
            >
              <ListItemText
                primary={`Client`}
                classes={{ primary: classes.listItemText }}
              />
              {this.state.open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  component="a"
                  href="#createLayer"
                  className={classes.nested}
                >
                  <ListItemText
                    primary={`#createLayer`}
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
                <ListItem
                  button
                  component="a"
                  href="#changeLayersMapType"
                  className={classes.nested}
                >
                  <ListItemText
                    primary={`#changeLayersMapType`}
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Drawer>
        <main className={classes.content}>
          {selectedIndex === 1 ? (
            <span>
              <h3>
                <small>Sınıf Özellikleri</small>
              </h3>
              <p>
                Tile Layer olusturmak için kullanılan sınıftır. Olusturulurken
                iki parametre alır; api key ve harita tipi. Harita tipi olarak
                Leaflet ve OpenLayers'ı destekler.
              </p>
              <br />
              <p>
                &quot;mapType&quot; parametresi iki değer alabilir:
                &quot;atlas.MAPTYPES.LEAFLET&quot; ve
                &quot;atlas.MAPTYPES.OPENLAYERS&quot;
              </p>
              <h4>
                <small>Örnek Kullanım</small>
              </h4>
              <h5>
                <small>Leaflet için</small>
              </h5>
              <pre>
                <code className="language-javascript">{leaflet}</code>
              </pre>
              <h5>
                <small>OpenLayers için</small>
              </h5>
              <pre>
                <code className="language-javascript">{openLayers}</code>
              </pre>
              <h3>
                <small>Methodlar</small>
              </h3>
              <h4 id="createLayer">
                <small>#createLayer(mapTheme)</small>
              </h4>
              <p>
                Verilen map temasına göre tile layer listesi olusturur. Bu
                katmam listesini, OpenLayers ve Leaflet haritalarına
                oluşturduğunuz "Map" objesinin layers özelligine geçirerek
                kullanabilirsiniz.
              </p>
              <br />
              <p>Parametre olarak alabileceği değerler şunlardır:</p>
              <ul>
                <li>atlas.BASEMAPS.TEMEL</li>
                <li>atlas.BASEMAPS.UYDU</li>
                <li>atlas.BASEMAPS.GECE</li>
                <li>atlas.BASEMAPS.SIYASI</li>
                <li>atlas.BASEMAPS.YUKSEKLIK</li>
              </ul>
              <h4 id="changeLayersMapType">
                <small>#changeLayersMapType(basemap)</small>
              </h4>
              <p>Harita temasını günceller.</p>
              <br />
              <p>Parametre olarak alabileceği değerler şunlardır:</p>
              <ul>
                <li>atlas.BASEMAPS.TEMEL</li>
                <li>atlas.BASEMAPS.UYDU</li>
                <li>atlas.BASEMAPS.GECE</li>
                <li>atlas.BASEMAPS.SIYASI</li>
                <li>atlas.BASEMAPS.YUKSEKLIK</li>
              </ul>
            </span>
          ) : (
            ""
          )}
        </main>
      </div>
    );
  }
}

APIReference.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(APIReference);
