import React from "react";
import Prism from "prismjs";

const response = `{
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "name": "Doğanşehir Eczanesi",
                "locality": "Yeni",
                "county": "Doğanşehir",
                "region": "Malatya"
            },
            "geometry": {
                "type": "Point",
                "coordinates": [
                    null,
                    null
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Dicle Eczanesi",
                "locality": "Yeni",
                "county": "Doğanşehir",
                "region": "Malatya"
            },
            "geometry": {
                "type": "Point",
                "coordinates": [
                    null,
                    null
                ]
            }
        }
    ]
  }
  `;

class Category extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    return (
      <span>
        <h3>
          <small>Kategori Arama</small>
        </h3>
        <p>
          İl veya ilçede kullanıcının belirlediği kategorideki lokasyonların ad
          ve koordinatlarını döndürür.
        </p>
        <br />
        <p>Aşağıdaki adrese "GET" sorgusu atılır.</p>
        <pre>
          <code className="language-">
            {
              "https://atlas.harita.gov.tr/webservis/category?type={type}&id={id}&category={category}&limit={limit}&offset={offset}"
            }
          </code>
        </pre>
        <p>
          <b>type</b> parametresi aramanın il ya da ilçede mi yapılacağını
          belirtir. 1(ilçe) veya 2(il) olabilir. <b>id</b> parametresi aramanın
          yapılacağı il veya ilçenin belirlenmesinde kullanılır. <b>category</b>{" "}
          aranacak kategori kelimesini, <b>limit</b> dönen maksimum sonuç
          sayısını, <b>offset</b> dönen sonuçlar için kayma miktarını
          belirtir(örneğin; 10 tane sonuç için offset değeri 2 verilirse,
          response objesine sonuç listesi ilk iki değer atlanarak atanır).
        </p>
        <p>API'dan dönen response örneği aşağıdadır.</p>
        <pre>
          <code className="language-javascript">{response}</code>
        </pre>
        <br />
        <p>
          Dönen request standart GeoJSON nesnesidir. <b>properties</b>'de{" "}
          <b>name</b> lokasyonun adını, <b>locality</b> semt, <b>county</b> ilçe
          ve <b>region</b> il bilgilerini tutar.
        </p>
      </span>
    );
  }
}

export default Category;
