import React from "react";
import Table from "./Table";
import Prism from "prismjs";

const response = `{
    "code": "Ok",
    "routes": [
        {
            "legs": [
                {
                    "steps": [],
                    "distance": 561214.8,
                    "duration": 28631.3,
                    "summary": "",
                    "weight": 28631.3
                },
                {
                    "steps": [],
                    "distance": 1100621.7,
                    "duration": 49649.8,
                    "summary": "",
                    "weight": 49649.8
                }
            ],
            "distance": 1661836.5,
            "duration": 78281.1,
            "weight_name": "routability",
            "weight": 78281.1
        }
    ],
    "waypoints": [
        {
            "hint": "bNBCgIHQQoDlAQAAAAAAACstAAAAAAAABp6oQwAAAABbavtFAAAAAOUBAAAAAAAAKy0AAAAAAAAcGAAAABn8AVN5TAI8ef0BLblLAhwA7wZrbARr",
            "distance": 9573.316682,
            "name": "62555. Sk.",
            "location": [
                33.298688,
                38.566227
            ]
        },
        {
            "hint": "qIFHgL-BR4AAAAAADQAAAJABAACcEAAAAAAAAE6HbkG1EN5DTdGTRQAAAAANAAAAkAEAAJwQAAAcGAAAgUUOAgVmgALC3QwCf_KIAg0ADxBrbARr",
            "distance": 62693.20067,
            "name": "",
            "location": [
                34.489729,
                41.969157
            ]
        },
        {
            "hint": "0AwTgNIME4BlAAAAAAAAABoBAAAAAAAAxX-MQgAAAADL5EJDAAAAAGUAAAAAAAAAGgEAAAAAAAAcGAAAJaD-AdknFgJLFP4B00PwAQIADwtrbARr",
            "distance": 275444.953252,
            "name": "Şehit Ulus Ülfet Cd.",
            "location": [
                33.464357,
                35.006425
            ]
        }
    ]
}`;

class Route extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    return (
      <span>
        <h3>
          <small>En Hızlı Rotayı Alma</small>
        </h3>
        <p>
          Verilen sıraya uyarak koordinatlar arasındaki en hızlı rotayı
          döndürür. Kullanıcı aşağıdaki adrese GET sorgusu atarak servisin bu
          özelliğine ulaşır.
        </p>
        <br />
        <pre>
          <code className="language-">
            {
              "https://atlas.harita.gov.tr/webservis/rota/{coordinates}?alternatives={true|false}&steps={true|false}&geometries={polyline|polyline6|geojson}&overview={full|simplified|false}&annotations={true|false}"
            }
          </code>
        </pre>
        <br />
        <p>Parametre listesi aşağıdadır:</p>
        <Table
          rows={[
            {
              param: "alternatives",
              values: "true ,  false (varsayılan)",
              desc: "Alternatif rotalar ara"
            },
            {
              param: "steps",
              values: "true ,  false (varsayılan)",
              desc: "Her bir rota bacağı için rota adımlarını döndür"
            },
            {
              param: "annotations",
              values: "true ,  false (varsayılan)",
              desc: "Ekstra metadayı(id, süre ve uzaklık) döndür"
            },
            {
              param: "geometries",
              values: "polyline (varsayılan), polyline6 ,  geojson",
              desc: "Dönen rotanın geometri formatı"
            },
            {
              param: "overview",
              values: "simplified (varsayılan),  full , false",
              desc:
                "Genel bakış geometrisini tam veya basitleştirilmiş(yakınlaştırma seviyesine göre) olarak ekler ya da eklemez(false). "
            }
          ]}
        />
        <br />
        <p>API'dan dönen response örneği aşağıdadır.</p>
        <pre>
          <code className="language-javascript">{response}</code>
        </pre>
        <br />
      </span>
    );
  }
}

export default Route;
