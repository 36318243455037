import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import AccountIcon from "@material-ui/icons/AccountCircle";
import UserInfo from "./UserInfo.jsx";
import Projects from "./Projects.jsx";

import userPageStyle from "assets/jss/material-kit-react/views/userPage.jsx";

class UserPage extends React.Component {
  state = {
    selectedTab: 0
  };

  handleClick = selectedTab => () => {
    this.setState({ selectedTab });
  };

  render() {
    const { classes, user, width, ...rest } = this.props;
    const { selectedTab } = this.state;

    return (
      <div>
        <Header
          absolute
          color="primary"
          brand="HGM Atlas Geliştirici Platformu"
          {...rest}
        />
        <div className={classes.pageHeader}>
          <div className={classes.container}>
            <GridContainer justify="center" align="center">
              <GridItem xs={12} md={3}>
                <Card style={{ marginTop: "65px", borderRadius: "5px" }}>
                  <CardBody>
                    <Avatar className={classes.avatar}>
                      <AccountIcon style={{ height: "100%", width: "100%" }} />
                    </Avatar>
                    <div className={classes.userName}>
                      {user.username || "Kullanıcı Adı"}
                    </div>
                    <div
                      className={
                        isWidthUp("md", width) ? classes.navSection : ""
                      }
                    >
                      <Button
                        className={classNames({
                          [classes.nav]: true,
                          [classes.tabSelected]: selectedTab === 0
                        })}
                        onClick={this.handleClick(0)}
                        style={{ textTransform: "capitalize" }}
                      >
                        Kullanıcı Bilgileri
                      </Button>
                      <Button
                        className={classNames({
                          [classes.nav]: true,
                          [classes.tabSelected]: selectedTab === 1
                        })}
                        onClick={this.handleClick(1)}
                        style={{ textTransform: "capitalize" }}
                      >
                        Projeler
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem
                xs={12}
                md={6}
                style={isWidthUp("md", width) ? {} : { marginBottom: "255px" }}
              >
                {selectedTab === 0 ? <UserInfo /> : <Projects />}
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont border={false} />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return { user: user.user };
}

UserPage.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object
};

export default withWidth()(
  withStyles(userPageStyle)(connect(mapStateToProps)(UserPage))
);
