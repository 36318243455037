import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import Button from "components/CustomButtons/Button.jsx";

import {
  getApiKey,
  getUserInfo,
  checkValidityOfUser,
  updateUserInfo
} from "../../actions";

const styles = theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  main: {
    margin: "0 auto",
    flexFlow: "column",
    alignItems: "center"
    /* maxWidth: "45%" */
  },
  margin: {
    margin: theme.spacing.unit
  },
  form: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-evenly"
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    //border: "1px solid #ced4da",
    fontSize: 16,
    //width: "100%",
    padding: "8px",
    margin: "0 0 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.1rem rgba(0,123,255,.25)"
    }
  },
  marginTop: {
    marginTop: "20px"
  },
  formLabel: {
    fontSize: 18
  },
  fullWidth: {
    width: "100%"
  },
  avatar: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: " 18px 20px 10px 0",
    height: "88px",
    width: "88px",
    textAlign: "center",
    alignContent: "center"
  },
  nameContainer: {
    width: "100%",
    padding: "0 4px 0 0",
    display: "flex",
    flexFlow: "column"
  },
  button: {
    flex: ".1",
    marginTop: "24px",
    marginLeft: "10px",
    height: "36px",
    width: "25%"
  },
  inputRaw: {
    color: "currentColor",
    border: 0,
    display: "block",
    minWidth: 0,
    boxSizing: "content-box",
    WebkitTapHighlightColor: "transparent"
  }
});

class UserInfo extends React.Component {
  state = {
    error: "",
    copied: false,
    showOldPassword: false,
    showNewPassword: false,
    username: "",
    name: "",
    email: "",
    oldPassword: "",
    password: ""
  };

  componentDidMount() {
    this.props.getApiKey(
      () => {
        this.setState({ error: "" });
      },
      error => this.setState({ error })
    );
    this.props.getUserInfo(
      user => {
        this.setState({
          username: user.username,
          name: user.firstname,
          email: user.email,
          oldPassword: "",
          password: "",
          error: ""
        });
      },
      error => this.setState({ error })
    );
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowPassword = isOld => () => {
    if (isOld) {
      this.setState(state => ({
        showOldPassword: !state.showOldPassword
      }));
    } else {
      this.setState(state => ({
        showNewPassword: !state.showNewPassword
      }));
    }
  };

  handleSave = () => {
    const { username, oldPassword, password, name, email } = this.state;
    this.props.checkValidityOfUser(
      username,
      oldPassword,
      () => {
        this.props.updateUserInfo(
          {
            username,
            name,
            password,
            email
          },
          () => {
            this.setState({ error: "" });
          }
        );
      },
      () => {
        this.setState({
          error:
            "Kullanıcı Adı ve/veya Şifre hatalı (eski şifrenizi girdiğinizden emin olun)!"
        });
      }
    );
  };

  render() {
    const { classes, apiKey, updateSuccess } = this.props;
    const { username, name, email, password, error } = this.state;

    return (
      <main className={classes.main}>
        <h4> Kullanıcı Bilgileri </h4>
        <FormControl className={classes.form}>
          {/* <Avatar className={classes.avatar}>U</Avatar> */}
          <div className={classes.nameContainer}>
            <input
              id="username"
              value={username}
              onChange={this.handleChange("username")}
              className={classNames({
                [classes.inputRaw]: true,
                [classes.marginTop]: true,
                [classes.input]: true,
                [classes.root]: true
              })}
            />
            <input
              id="name"
              value={name}
              onChange={this.handleChange("name")}
              className={classNames({
                [classes.inputRaw]: true,
                [classes.input]: true,
                [classes.root]: true
              })}
            />
          </div>
        </FormControl>
        <FormControl className={classes.form}>
          <InputLabel shrink htmlFor="api-key" className={classes.formLabel}>
            API Anahtarı
          </InputLabel>
          <InputBase
            id="api-key"
            placeholder="API Anahtarı"
            value={apiKey}
            readOnly
            classes={{
              root: classes.root,
              input: classes.input
            }}
            className={classNames({
              [classes.fullWidth]: true,
              [classes.margin]: false
            })}
          />
          <CopyToClipboard text={apiKey}>
            <Button color="danger" fullWidth className={classes.button}>
              {this.state.copied ? "Kopyalandı" : "Kopyala"}
            </Button>
          </CopyToClipboard>
        </FormControl>
        <FormControl className={classes.form}>
          <InputLabel shrink htmlFor="email" className={classes.formLabel}>
            Email
          </InputLabel>
          <InputBase
            id="email"
            value={email}
            onChange={this.handleChange("email")}
            classes={{
              root: classes.root,
              input: classes.input
            }}
            className={classNames({
              [classes.fullWidth]: true,
              [classes.margin]: false
            })}
          />
        </FormControl>
        <FormControl className={classes.form}>
          <InputLabel
            shrink
            htmlFor="oldpassword"
            className={classes.formLabel}
          >
            Eski Şifre
          </InputLabel>
          <InputBase
            id="oldpassword"
            classes={{
              root: classes.root,
              input: classes.input
            }}
            className={classNames({
              [classes.fullWidth]: true,
              [classes.margin]: false
            })}
            type={this.state.showOldPassword ? "text" : "password"}
            placeholder="Eski şifrenizi girin"
            onChange={this.handleChange("oldPassword")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword(true)}
                  style={{ padding: 0, marginBottom: "8px" }}
                >
                  {this.state.showOldPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl className={classes.form}>
          <InputLabel shrink htmlFor="password" className={classes.formLabel}>
            Yeni Şifre
          </InputLabel>
          <InputBase
            id="password"
            classes={{
              root: classes.root,
              input: classes.input
            }}
            className={classNames({
              [classes.fullWidth]: true,
              [classes.margin]: false
            })}
            type={this.state.showNewPassword ? "text" : "password"}
            value={password}
            onChange={this.handleChange("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword(false)}
                  style={{ padding: 0, marginBottom: "8px" }}
                >
                  {this.state.showNewPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <div>
          <Button color="info" fullWidth onClick={this.handleSave}>
            Kaydet
          </Button>
          {error ? (
            <div>{error}</div>
          ) : (
            updateSuccess && <div>{"Bilgileriniz güncellendi"}</div>
          )}
        </div>
      </main>
    );
  }
}

function mapStateToProps({ key, user }) {
  return {
    apiKey: key.apiKey,
    user: user.user,
    updateSuccess: user.updateSuccess
  };
}

UserInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  getApiKey: PropTypes.func,
  getUserInfo: PropTypes.func,
  checkValidityOfUser: PropTypes.func,
  updateUserInfo: PropTypes.func,
  apiKey: PropTypes.string,
  user: PropTypes.object,
  updateSuccess: PropTypes.bool
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { getApiKey, getUserInfo, checkValidityOfUser, updateUserInfo }
  )(UserInfo)
);
