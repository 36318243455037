/*eslint-disable*/
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { logout } from "../../actions";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";
import { Tooltip } from "@material-ui/core";

function HeaderLinks({ ...props }) {
  const { classes, user, logout } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="SERVİSLER"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          dropdownList={[
            <Link to="/hari̇talar" className={classes.dropdownLink}>
              Haritalar
            </Link>,
            /* <Link to="/hari̇ta-görüntüsü" className={classes.dropdownLink}>
              Harita Görüntüsü
            </Link>, */
            <Link to="/coğrafi̇-kodlama" className={classes.dropdownLink}>
              Coğrafi Kodlama
            </Link>,
            <Link to="/arama" className={classes.dropdownLink}>
              Arama
            </Link>,
            <Link to="/güzergah" className={classes.dropdownLink}>
              Güzergah
            </Link>
          ]}
        />
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Button
          to="/"
          color="transparent"
          className={classes.navLink}
          component={Link}
        >
          BLOG
        </Button>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Button
          to="/ucret"
          color="transparent"
          className={classes.navLink}
          component={Link}
        >
          ÜCRETLENDİRME
        </Button>
      </ListItem>
      {!user.isLoggedIn ? (
        <ListItem className={classes.listItem}>
          <Tooltip title="Ticarileştirme süreci devam etmektedir.">
            <Button
            //to="/atlasapidocgiris"
            color="twitter"
            className={classes.navLink}
            //component={Link}
            style={{ color: "#fffff !important" }}
          >
            GİRİŞ
          </Button>
          </Tooltip>
        </ListItem>
      ) : (
        <>
          <ListItem className={classes.listItem}>
            <Button
              to="/kullanici"
              color="transparent"
              className={classes.navLink}
              component={Link}
            >
              HESABIM
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              to="/atlasapidocgiris"
              color="twitter"
              className={classes.navLink}
              component={Link}
              onClick={() => {
                logout();
              }}
            >
              Çıkış
            </Button>
          </ListItem>
        </>
      )}
    </List>
  );
}

function mapStateToProps({ user }) {
  return { user };
}

export default withStyles(headerLinksStyle)(
  connect(
    mapStateToProps,
    { logout }
  )(HeaderLinks)
);
