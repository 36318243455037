import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Coordinate from "./Coordinate";
import Address from "./Address";
import Prism from "prismjs";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    position: "relative"
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    maxWidth: "666px"
  },
  listItemText: {
    fontSize: "0.75em"
  }
});

class APIReference extends React.Component {
  state = {
    selectedIndex: 1
  };

  componentDidMount() {
    Prism.highlightAll();
  }

  handleListItemClick = (event, index) => {
    this.setState({ selectedIndex: index });
  };

  render() {
    const { classes } = this.props;
    const { selectedIndex } = this.state;
    let selectedContent = "";

    if (selectedIndex === 1) {
      selectedContent = <Coordinate />;
    } else if (selectedIndex === 2) {
      selectedContent = <Address />;
    }

    return (
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="left"
        >
          <List>
            {[
              "Adresten Coğrafi Koordinat Alma",
              "Coğrafi Koordinatlardan Adres Alma"
            ].map((text, index) => (
              <ListItem
                button
                key={text}
                selected={selectedIndex === index + 1}
                onClick={event => this.handleListItemClick(event, index + 1)}
              >
                <ListItemText
                  primary={`${text}`}
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <main className={classes.content}>{selectedContent}</main>
      </div>
    );
  }
}

APIReference.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(APIReference);
