import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import AddIcon from "@material-ui/icons/Add";
import { getApiKey, addProject } from "../../actions";

import styles from "assets/jss/material-kit-react/views/componentsSections/javascriptStyles.jsx";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class NewProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classicModal: false,
      error: "",
      project: "",
      domain: ""
    };
  }

  handleClickOpen = () => () => this.setState({ classicModal: true });

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleCopy = () => {
    this.setState({ copied: true });
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  addProject = () => {
    const { project, domain } = this.state;

    if (project) {
      this.props.addProject(project, domain);
    } else {
      //Error
    }
  };

  render() {
    const { classes } = this.props;
    const { project, domain } = this.state;

    return (
      <div>
        <Button
          className={classes.button}
          aria-label="Proje Ekle"
          onClick={this.handleClickOpen("classicModal")}
          color="google"
          style={{
            padding: "8px"
          }}
        >
          <AddIcon /> Proje oluştur
        </Button>
        <Dialog
          classes={{
            paper: classes.modal
          }}
          open={this.state.classicModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("classicModal")}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClose("classicModal")}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            <h4 className={classes.modalTitle}>Proje Tanımla</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}
          >
            <FormControl className={classes.form}>
              <InputLabel
                shrink
                htmlFor="project-modal"
                className={classes.formLabel}
              >
                Projenize bir isim verin:
              </InputLabel>
              <InputBase
                id="project-modal"
                value={project}
                onChange={this.handleChange("project")}
                classes={{
                  root: classes.root,
                  input: classes.input
                }}
                className={classNames({
                  [classes.fullWidth]: true,
                  [classes.margin]: false
                })}
              />
            </FormControl>
            <FormControl className={classes.form} style={{ marginTop: "12px" }}>
              <InputLabel
                shrink
                htmlFor="domain-modal"
                className={classes.formLabel}
              >
                URL:
              </InputLabel>
              <InputBase
                id="domain-modal"
                value={domain}
                onChange={this.handleChange("domain")}
                classes={{
                  root: classes.root,
                  input: classes.input
                }}
                className={classNames({
                  [classes.fullWidth]: true,
                  [classes.margin]: false
                })}
              />
            </FormControl>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              color="danger"
              simple
              onClick={() => {
                this.addProject();
                this.handleClose("classicModal");
              }}
            >
              Ekle
            </Button>
            <Button
              onClick={() => this.handleClose("classicModal")}
              color="danger"
              simple
            >
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps({ key }) {
  return {
    message: key.message,
    apiKey: key.apiKey
  };
}

NewProjects.propTypes = {
  classes: PropTypes.object.isRequired,
  getApiKey: PropTypes.func,
  apiKey: PropTypes.string
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { getApiKey, addProject }
  )(NewProjects)
);
