import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

const styles = {
  question: {
    fontWeight: "bold"
  }
};

function Help(props) {
  const { faq, classes } = props;

  return (
    <List>
      {faq.map((element, index) => (
        <div key={index}>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="subheading" className={classes.question}>
                  {element.question}
                </Typography>
              }
            />
          </ListItem>
          <ListItem key={index}>
            <ListItemText primary={`${element.answer}`} />
          </ListItem>
        </div>
      ))}
    </List>
  );
}

Help.propTypes = {
  classes: PropTypes.object.isRequired,
  faq: PropTypes.array
};

export default withStyles(styles)(Help);
