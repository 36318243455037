import React from "react";
import Prism from "prismjs";

class SearchOverview extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    return (
      <span>
        <h3>
          <small>Arama API nedir?</small>
        </h3>
        <p>
          ATLAS Arama API, kullanıcıların kategoriler ile arama yapmasını
          sağlayan bir REST servisidir. Arama işlemini gerçekleştirdiğiniz "il"
          ve "ilçe" içerisindeki sorgu kategoriniz ile eşleşen lokasyonların
          listesini yanıt olarak verir.{" "}
        </p>
        <p>
          Projenize ekleyeceğiniz arama özelliği ile kullanıcılarınız, HGM ATLAS
          veritabanında bulunan milyonlarca ilgi noktaları (POI) içerisinden
          aradıkları yere ulaşabilecekler.
        </p>
        <h3>
          <small>Doğru ve Güncel:</small>
        </h3>
        <p>Arama için yüksek doğrulukta eşleştirme algoritmasına sahiptir.</p>
        <h3>
          <small>Yüksek Hacim:</small>
        </h3>
        <p>
          Yaklaşık 3 milyon veri içerisinden eşleştirme gerçekleştirmektedir.
        </p>
        <br />
        <h3>
          <small>Örnek Kullanım</small>
        </h3>
        <p>
          Kullanıcı aşağıdaki adrese "GET" sorgusu atarak Malatya(id parametresi
          45) ilindeki, eczane kategorisindeki lokasyonlara ulaşabilir.
        </p>
        <pre>
          <code className="language-">
            {
              "https://atlas.harita.gov.tr/webservis/category?type=2&id=45&category=eczane&limit=10&offset=0"
            }
          </code>
        </pre>
        <br />
        <p>
          Kullanıcı aşağıdaki adrese "GET" sorgusu atarak Doğanşehir(id
          parametresi 1286) ilçesindeki, eczane kategorisindeki lokasyonlara
          ulaşabilir.
        </p>
        <pre>
          <code className="language-">
            {
              "https://atlas.harita.gov.tr/webservis/category?type=1&id=1286&category=eczane&limit=10&offset=0"
            }
          </code>
        </pre>
        <br />
        <br />
        <br />
        <br />
      </span>
    );
  }
}

export default SearchOverview;
