import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Laptop from "@material-ui/icons/Laptop";
import Security from "@material-ui/icons/Security";
import Map from "@material-ui/icons/Map";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import pillsStyle from "../../assets/jss/material-kit-react/views/componentsSections/pillsStyle";
import APIReferece from "./APIReference";
import InteractiveMapOverview from "./InteractiveMapOverview";
import InteractiveMapExamples from "./InteractiveMapExamples";
import TabView from "../../components/Tab/TabView.jsx";
import Help from "../../components/Help/Help.jsx";
import helpList from "./help";

class InteractiveMap extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div /*className={classes.section}*/>
        <div className={classes.container}>
          <div id="navigation-pills">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <TabView
                  tabs={[
                    {
                      tabButton: "Genel Bakış",
                      tabIcon: Laptop,
                      tabContent: <InteractiveMapOverview />
                    },
                    {
                      tabButton: "API Referansı",
                      tabIcon: Security,
                      tabContent: <APIReferece />
                    },
                    {
                      tabButton: "Örnekler",
                      tabIcon: Map,
                      tabContent: <InteractiveMapExamples />
                    },
                    {
                      tabButton: "Yardım",
                      tabIcon: Laptop,
                      tabContent: <Help faq={helpList} />
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

InteractiveMap.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pillsStyle)(InteractiveMap);
