const cardsList = [
  {
    title: "HARİTALAR",
    content: `Haritalar JavaScript API, harita hizmeti sunan uygulamalarınıza veya web sayfalarınıza 
    HGM Atlas haritalarının işlevselliğini ve görselliğini ekler. HGM Atlas haritaları, 
    mevcutlar arasında en güncel ve en doğru olanlarıdır.`,
    cat: ["Detay"],
    img: "staticMap"
  },
  /* {
    title: "HARİTA GÖRÜNTÜSÜ",
    content: `Harita Görüntüsü REST API, web ve mobil cihazlarda görüntülenebilen “JPG” veya “PNG” uzantılı bağımsız resimler verir. Sonuç ekranı etkileşim veya kontroller olmadan bütünleşik bir harita görüntüsüdür. 
      Merkez koordinatlar, yakınlaştırma seviyesi ve farklı dosya uzantısı olmak üzere parametreler sağlayarak Harita Görüntüsü API'sinden statik bir harita isteyebilirsiniz. Harita görüntüsü ekranı ayrıca çizgiler, işaretçiler veya çokgenler gibi kaplamalar içerebilir.`,
    cat: ["Detay"],
    img: "staticMap"
  }, */
  {
    title: "COĞRAFİ KODLAMA",
    content: `Adresler coğrafi koordinatlara çevrilebilir veya coğrafi koordinatlardan konuma ait kullanılabilir açıklayıcı metinsel bilgiler elde edilebilir.`,
    cat: ["Detay"],
    img: "geocoder"
  },
  {
    title: "ARAMA",
    content: `Önemli noktalar aranabilir, bulunabilir ve tam adları ile görüntülenebilir. HGM Atlas Arama API'si, doğrudan son kullanıcılarla etkileşime geçmek için mükemmel bir sorgu arayüzü sunar.`,
    cat: ["Detay"],
    img: "search"
  },
  {
    title: "GÜZERGAH",
    content: `Kullanıcıların iki ya da daha fazla lokasyon arasında yol tarifi, seyahat süreleri ve mesafeleri almasına olanak sağlar.`,
    cat: ["Detay"],
    img: "routing"
  }
  /* {
    title: "WEB HARİTA SERVİSİ",
    content: `Web Harita Servis Arabirimi Standardı (WMS), bir veya daha fazla coğrafi veritabanında kayıtlı harita görüntülerini istemek için kullanılan bir HTTP arayüzüdür. İsteğe verilen yanıt, JPG, PNG veya TIF gibi raster formatları ile aktarılan coğrafi kayıtlı raster harita görüntüsüdür. 
    HGM ATLAS WMS, uygulamanız veya web sayfanız için tam olarak istediğiniz haritayı istediğiniz boyutta tek bir görüntüde istemenizi sağlar. Tek ihtiyacınız olan web sayfanıza veya uygulamanıza ekleyeceğiniz bir coğrafi sınırlama kutusu. `,
    cat: ["Detay"],
    img: "wms"
  },
  {
    title: "VEKTÖR HARİTA SERVİSİ",
    content: `Vektör Harita Servisi (WFS), coğrafi nesneler (noktalar, çizgiler ve çokgenler) hakkındaki bilgileri yönetme hizmetini tanımlar. Bu işlemler Web ortamında mekansal veriler üzerinde işlemlerin (sorgulama, oluşturma, güncelleme veya silme) yapılmasına izin verir. 
    HGM ATLAS'ın WFS hizmeti, Açık Mekansal Konsorsiyumun (OGC) WFS spesifikasyonu ile uyumludur. Bu, HGM ATLAS’ın harita kontrolleri ve bileşenleri dahil olmak üzere çok çeşitli araç ve sistemlerle birlikte çalışabildiği anlamına gelir. `,
    cat: ["Detay"],
    img: "wfs"
  } */
];

export default cardsList;
