import React from "react";

class InteractiveMapOverview extends React.Component {
  render() {
    return (
      <span>
        <h3>
          <small>Haritalar API nedir?</small>
        </h3>
        <p>
          ATLAS Haritalar API, kullanıcıların web uygulamalarında kullandıkları
          OpenLayers ve Leaflet haritalarına eklemek için
          <i>Tile Layer</i> oluşturmalarına olanak sağlayan bir Javascript
          kütüphanesidir. HTML5 standartını destekleyen güncel tarayıcılar için
          yazılmıştır.
        </p>
        <p>
          HGM ATLAS’ın sahip olduğu beş farklı harita katmanını (temel, hava
          fotoğrafı, siyasi, gece ve topoğrafik) web sayfanıza ya da
          uygulamanıza ekleyerek kullanıcılarınız için etkileşimli harita
          deneyimleri oluşturabilirsiniz. HGM ATLAS, Türkiye ölçeğinde doğruluğu
          yüksek ve kaliteli harita ekranı sağlayarak, kullanıcıların en doğru
          ve güncel haritalara ulaşmasını sağlar.
        </p>
        <h3>
          <small>Doğru ve Güncel:</small>
        </h3>
        <p>
          HGM Harita verileri farklı kaynaklardan derlenmiş, doğru ve günceldir.
        </p>
        <h3>
          <small>Yerli ve Millî:</small>
        </h3>
        <p>
          Haritalar yerli ve millî olup, tamamen millî menfaatlere uygundur.
        </p>
        <br />
        <h3>
          <small>Örnek Kullanım</small>
        </h3>
        <p>
         HGM Atlas JavaScript dosyasını HTML kodunuza dahil edin:
        </p>
        <pre>
          <code className="language-">
            {`<script src="https://api.harita.gov.tr/atlas-js/v1.0.0/atlas.js"></script>`}
          </code>
        </pre>
      </span>
    );
  }
}

export default InteractiveMapOverview;
