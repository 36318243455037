import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { login, sendPassword } from "../../actions";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      error: {
        username: "",
        password: ""
      }
    };
  }

  handleChange = (name, errorMsg) => event => {
    let error = Object.assign({}, this.state.error);

    if (!event.target.value) {
      this.setState({
        error: Object.assign(error, { [name]: errorMsg })
      });
    } else {
      this.setState({
        error: Object.assign(error, { [name]: "", notFound: "" })
      });
    }

    this.setState({
      [name]: event.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    let error = { username: "", password: "", notFound: "" };

    if (!this.state.username) {
      this.setState(() => {
        return {
          error: Object.assign(error, { username: "Kullanıcı adı giriniz" })
        };
      });
    }

    if (!this.state.password) {
      this.setState(() => {
        return {
          error: Object.assign(error, { password: "Şifre giriniz" })
        };
      });
    }

    if (this.state.username && this.state.password) {
      this.setState({ error });

      this.props.login(
        {
          username: this.state.username,
          password: this.state.password
        },
        () => {
          this.props.history.push("/");
        },
        () => {
          this.setState({ error: { notFound: "Kullanıcı bulunamadı!" } });
        }
      );
    }
  };

  render() {
    const { classes } = this.props;
    const { username, password, error } = this.state;

    return (
      <form className={classes.form}>
        <CardBody>
          <CustomInput
            labelText="Kullanıcı Adı..."
            id="usernameLogin"
            onChange={this.handleChange("username", "Kullanıcı Adı girin!")}
            value={username}
            errorMessage={error["username"]}
            error={error["username"] !== ""}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "text"
            }}
          />
          <CustomInput
            labelText="Şifre"
            id="password"
            onChange={this.handleChange("password", "Şifre girin!")}
            value={password}
            errorMessage={error["password"]}
            error={error["password"] !== ""}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "password"
            }}
          />
          <div className={classes.register}>
            <Link to="/kayit-ol">Kayıt Ol</Link>
            <div
              onClick={() => {
                this.props.history.push("/sifre");
              }}
              style={{
                fontSize: ".78rem",
                textDecoration: "underline",
                cursor: "pointer"
              }}
            >
              Şifremi unuttum
            </div>
          </div>
        </CardBody>
        <CardFooter
          className={classes.cardFooter}
          style={
            error.notFound !== ""
              ? { justifyContent: "space-around !important" }
              : {}
          }
        >
          <Button color="google" onClick={this.handleSubmit}>
            Başlayın
          </Button>
          <FormControl
            error={error.notFound !== undefined && error.notFound !== ""}
          >
            {error.notFound !== "" ? (
              <div className={classes.marginLeft}>{error.notFound}</div>
            ) : (
              ""
            )}
          </FormControl>
        </CardFooter>
      </form>
    );
  }
}

export default withStyles(loginPageStyle)(
  withRouter(
    connect(
      null,
      { login, sendPassword }
    )(Login)
  )
);
