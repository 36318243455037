import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";

import navPillsStyle from "assets/jss/material-kit-react/components/navPillsStyle.jsx";

import "./TabView.css";

class CustomizedTabs extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes, tabs, direction, isTabColored } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <Tabs
          value={value}
          onChange={this.handleChange}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          {tabs.map((prop, key) => {
            return (
              <Tab
                key={key}
                disableRipple
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected
                }}
                style={
                  isTabColored
                    ? {
                        backgroundColor: "#141625",
                        color: "#fff",
                        minWidth: "50%"
                      }
                    : {}
                }
                label={prop.tabButton}
              />
            );
          })}
        </Tabs>
        <div className={classes.contentWrapper}>
          <SwipeableViews
            axis={direction === "rtl" ? "x-reverse" : "x"}
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
          >
            {tabs.map((prop, key) => {
              return (
                <div className={classes.tabContent} key={key}>
                  {prop.tabContent}
                </div>
              );
            })}
          </SwipeableViews>
        </div>
      </div>
    );
  }
}

CustomizedTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.string,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node
    })
  ).isRequired,
  isTabColored: PropTypes.bool,
  direction: PropTypes.string
};

CustomizedTabs.defaultTypes = {
  isTabColored: false
};

export default withStyles(navPillsStyle)(CustomizedTabs);
