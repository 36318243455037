import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "./components/Header/Header.jsx";
import Footer from "./components/Footer/Footer.jsx";
import GridContainer from "./components/Grid/GridContainer.jsx";
import GridItem from "./components/Grid/GridItem.jsx";
import HeaderLinks from "./components/Header/HeaderLinks.jsx";
import Parallax from "./components/ParallaxWithMap/Parallax.jsx";
import Modal from "./components/Modal/Modal.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Documentation from "./views/Documentation";

import landingPageStyle from "./assets/jss/material-kit-react/mainPage.jsx";
import "./assets/css/prism.css";
import { Tooltip } from "@material-ui/core";

const dashboardRoutes = [];

class MainPage extends React.Component {
  render() {
    const { classes, history, user, ...rest } = this.props;

    return (
      <div className={classes.main}>
        <Header
          history={history}
          color="white"
          routes={dashboardRoutes}
          brand="Ana Sayfa"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "dark"
          }}
          {...rest}
        />
        <Parallax medium filter image={require("assets/img/giris-sayfasi.png")}>
          <div
            className={classes.container}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px"
            }}
          >
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <h1 className={classes.title}>
                  HGM ATLAS Geliştirici Platformu
                </h1>
                <h4
                  className={classes.subtitle}
                  style={{ textAlign: "center" }}
                >
                  ATLAS Uygulama Geliştirme Platformu ile kendi haritalarınızı
                  kolay ve ücretsiz şekilde oluşturun!
                </h4>
                <br />
                {!user.isLoggedIn ? (
                  <Tooltip title="Ticarileştirme süreci devam etmektedir.">
                    <Button
                      color="danger"
                      size="lg"
                      //onClick={() => this.props.history.push("/atlasapidocgiris")}
                      rel="noopener noreferrer"
                      round
                    >
                      ÜCRETSİZ BAŞLAYIN
                    </Button>
                  </Tooltip>
                ) : (
                  <Modal />
                )}
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main)}>
          <div className={classes.section}>
            <div className={classes.container}>
              <Documentation history={history} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return { user };
}

MainPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object
};

export default withStyles(landingPageStyle)(
  connect(mapStateToProps, {})(withRouter(MainPage))
);
