import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Map from "@material-ui/icons/Map";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import pillsStyle from "../../assets/jss/material-kit-react/views/componentsSections/pillsStyle";
import APIReferece from "./APIReference";
import Overview from "./GeocoderOverview";
import Examples from "./GeocoderExamples";
import TabView from "../../components/Tab/TabView.jsx";
import Help from "../../components/Help/Help.jsx";
import helpList from "./help";
class Geocoder extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div id="navigation-pills">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <TabView
                tabs={[
                  {
                    tabButton: "Genel Bakış",
                    tabContent: <Overview />
                  },
                  {
                    tabButton: "API Referansı",
                    tabContent: (
                      <span>
                        <APIReferece />
                      </span>
                    )
                  },
                  {
                    tabButton: "Örnekler",
                    tabIcon: Map,
                    tabContent: (
                      <span>
                        <Examples />
                      </span>
                    )
                  },
                  {
                    tabButton: "Yardım",
                    tabIcon: Map,
                    tabContent: <Help faq={helpList} />
                  }
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
        {/*This is hack but do not delete it for now!!! */}
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

Geocoder.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pillsStyle)(Geocoder);
