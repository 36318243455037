import React from "react";
import Prism from "prismjs";

class GeocoderOverview extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    return (
      <span>
        <h3>
          <small>Coğrafi Kodlama REST API nedir?</small>
        </h3>
        <p>
          İleri coğrafi kodlama, metni coğrafi koordinatlara dönüştürür.
          Örneğin, “İstanbul Havalimanı” sorgusu “41.259871, 28.742858”
          koordinatlarına dönüştürülerek kullanıcı ekranına yansıtılır.
        </p>
        <p>
          Ters coğrafi kodlama ise coğrafi koordinatların bir metin tanımına
          dönüştürülmesidir. Bir ters coğrafi kodlama örneği olarak “41.259871,
          28.742858” koordinat sorgusu “İstanbul Havalimanı” olarak yanıtlanır.
          Bir sorgu yaptığınızda, sorgunuzla ilgili en doğru sonuçları içeren
          "JSON" formatlı bir yanıt alırsınız.
        </p>
        <h3>
          <small>Doğru ve Güncel:</small>
        </h3>
        <p>
          Koordinat ve adres için yüksek doğrulukta eşleştirme algoritmasına
          sahiptir.
        </p>
        <h3>
          <small>Yüksek Hacim:</small>
        </h3>
        <p>
          Yaklaşık 3 milyon veri içerisinden eşleştirme gerçekleştirmektedir.
        </p>
        <br />
        <h3>
          <small>Örnek Kullanım</small>
        </h3>
        <p>
          Kullanıcı aşağıdaki adrese "GET" sorgusu atarak adresten koordinata
          ulaşabilir.
        </p>
        <pre>
          <code className="language-">
            {
              "https://atlas.harita.gov.tr/webservis/arama/geocode?lat=38&lng=38&query=kuruyemis&apikey=[apiKey]"
            }
          </code>
        </pre>
        <br />
        <p>
          Kullanıcı aşağıdaki adrese "GET" sorgusu atarak koordinattan adrese
          ulaşabilir.
        </p>
        <pre>
          <code className="language-">
            {"https://atlas.harita.gov.tr/webservis/arama/reverse?lat=38&lng=38&apikey=[apiKey]"}
          </code>
        </pre>
      </span>
    );
  }
}

export default GeocoderOverview;
