import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "@material-ui/core/Snackbar";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
//import LoginHeaderLinks from "components/Header/LoginHeaderLinks.jsx";
//import TabView from "../../components/Tab/TabView.jsx";
import Login from "./Login";
import Register from "./Register";
import Password from "./Password";
import StyledSnackbarContent from "../../components/Snackbar/StyledSnackbarContent";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";
import NewPassword from "./NewPassword";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      notificationOpen: false,
      notificationMessage: ""
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 200 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      200
    );
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ notificationOpen: false });
  };

  render() {
    const { classes, children, ...rest } = this.props;
    const { notificationOpen, notificationMessage } = this.state;

    const onLoginPage = this.props.location.pathname === "/atlasapidocgiris";
    const margin =
      onLoginPage ||
      this.props.location.pathname === "/sifre" ||
      this.props.location.pathname.startsWith("/yenisifre");

    return (
      <div>
        <Header
          absolute
          color="transparentWhite"
          brand="HGM Atlas Geliştirici Platformu"
          {...rest}
        />
        <div className={classes.pageHeader}>
          <div className={classes.container}>
            <GridContainer justify="center" align="center">
              <GridItem xs={12} sm={12} md={6}>
                <Card
                  className={classes[this.state.cardAnimaton]}
                  style={margin ? { marginTop: "30%" } : {}}
                >
                  {onLoginPage ? (
                    <Login />
                  ) : this.props.location.pathname.startsWith("/yenisifre") ? (
                    <NewPassword
                      openNotification={() => {
                        this.setState({
                          notificationMessage: "Şifreniz güncellendi!",
                          notificationOpen: true
                        });
                      }}
                    />
                  ) : this.props.location.pathname.startsWith("/sifre") ? (
                    <Password
                      openNotification={() => {
                        this.setState({
                          notificationMessage:
                            "Şifrenizi güncellemek için epostanıza mail attık!",
                          notificationOpen: true
                        });
                      }}
                    />
                  ) : (
                    <Register />
                  )}
                  {children}
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={notificationOpen}
            autoHideDuration={3000}
            onClose={this.handleClose}
          >
            <StyledSnackbarContent
              onClose={this.handleClose}
              variant="success"
              message={notificationMessage}
            />
          </Snackbar>
          <Footer whiteFont border={false} />
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node
};

export default withStyles(loginPageStyle)(withRouter(LoginPage));
