import { container } from "assets/jss/material-kit-react.jsx";

const signupPageStyle = {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "15vh",
    paddingBottom: "210px"
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      height: "200px"
      /* position: "relative",
      bottom: "0",
      width: "100%" */
    }
  },
  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center"
  },
  inputIconsColor: {
    color: "#495057"
  },
  avatar: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "148px",
    width: "148px",
    textAlign: "center",
    alignContent: "center"
  },
  userName: {
    marginTop: "10px"
  },
  navSection: {
    marginTop: "40%"
  },
  nav: {
    fontWeight: "600",
    fontSize: "large",
    margin: "12px 0",
    padding: "5px",
    cursor: "pointer",
    borderRadius: "0"
  },
  tabSelected: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderBottom: "1px solid black",
    transition: "0.2s background-color 0.1s"
  }
};

export default signupPageStyle;
